import Icon from '@ant-design/icons';

export const BoxOutlined = props => <Icon component={BoxSvg} {...props} />;

const BoxSvg = () => (
	<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 100 100">
        <path d="M90.23,48.63l-8.82-8.86c2.93-2.92,5.85-5.84,8.77-8.77c0.27-0.28,0.5-0.59,0.96-1.21
        c0.19-0.25,0.29-0.55,0.29-0.85l-0.07-1.1c-0.35-1.06-1.14-1.61-1.7-1.93L63.01,10.53l-0.94-0.47c-0.19-0.1-0.9-0.15-1.11-0.15
        c-0.26,0-0.87,0.29-1.08,0.43c-0.31,0.19-0.66,0.4-0.98,0.73c-2.94,2.91-8.88,8.87-8.88,8.87c-2.93-2.93-5.85-5.86-8.79-8.78
        c-0.34-0.33-0.7-0.56-1.34-0.99c-0.24-0.17-1.01-0.26-1.29-0.26c-0.2,0-0.63,0.15-0.81,0.23c-0.21,0.1-0.41,0.2-0.61,0.31
        L10.2,26.02c-0.92,0.53-1.49,1.31-1.61,2.21c-0.12,0.9,0.23,1.81,1,2.57l8.99,9.01l-9.02,9.02c-0.74,0.74-1.08,1.64-0.97,2.52
        c0.12,0.89,0.68,1.67,1.59,2.19l9.59,5.54l-0.02,12.07c-0.01,1.29,0.56,2.28,1.7,2.93c8.97,5.17,17.92,10.35,26.88,15.53
        c0.55,0.32,1.11,0.48,1.67,0.48c0.56,0,1.12-0.16,1.68-0.48L78.6,74.05c1.1-0.64,1.65-1.6,1.65-2.87l-0.02-12.1l9.25-5.33
        c0.45-0.26,1.38-0.8,1.95-2.43v-0.65c0-0.28-0.33-0.91-0.49-1.13C90.72,49.22,90.5,48.9,90.23,48.63z M61.2,69.41
        c0.57,0,1.13-0.16,1.67-0.47c3.29-1.93,6.6-3.84,9.91-5.74l1.75-1l0.01,7.44l-21.67,12.5V62.33c0,0,4.08,4.08,6.04,6.07
        C59.56,69.06,60.35,69.41,61.2,69.41z M54.67,23.19l7.03-7.03L83.91,29l-7.03,7.03L54.67,23.19z M76.93,43.14l7.02,7.04L61.68,63.05
        l-7.02-7.03L76.93,43.14z M23.11,36.03l-7.01-7.03l22.2-12.83l7.03,7.03L23.11,36.03z M41.02,68.45l6.12-6.11v19.81l-21.7-12.54
        l0.01-7.44l11.74,6.78c0.59,0.34,1.14,0.51,1.68,0.51C39.63,69.46,40.34,69.13,41.02,68.45z M45.34,56.03l-7.02,7.02L16.09,50.21
        l7.03-7.03L45.34,56.03z M50.01,27.05l21.7,12.55l-21.7,12.55L28.31,39.6L50.01,27.05z"/>
    </svg>
);