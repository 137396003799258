import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { BoxOutlined } from '../../assets/icons/custom-antd/BoxOutlined';
import { ShipOutlined } from '../../assets/icons/custom-antd/ShipOutlined';
import { DoubleLeftOutlined } from '../../assets/icons/custom-antd/DoubleLeftOutlined';
import { DoubleRightOutlined } from '../../assets/icons/custom-antd/DoubleRightOutlined';
import { CrateOutlined } from '../../assets/icons/custom-antd/CrateOutlined';
import { setDefault } from '../../functions/setDefault';

import './sidebar.styles.css';
import { SalesOutlined } from '../../assets/icons/custom-antd/SalesOutlined';

const { Sider } = Layout;
const TabController = [
    { key: '1', url: '/inventory' },
    { key: '2', url: '/create-crate' },
    { key: '3', url: '/shipment' },
    { key: '4', url: '/create-crate/sales' },
];

class SideBar extends React.Component {
    state = {
        collapsed: setDefault(this.props.location.collapsed, false),
    };

    getSelectedKey = () => {
        const { pathname } = this.props.location;
        const key = TabController.find((tab) => tab.url === pathname).key;
        return [key];
    };

    renderTrigger = () => {
        const { collapsed } = this.state;
        return collapsed ? (
            <DoubleRightOutlined style={{ fontSize: '28px' }} />
        ) : (
            <DoubleLeftOutlined />
        );
    };

    render() {
        const { collapsed } = this.state;
        const selectedKey = this.getSelectedKey();
        return (
            <Sider
                id="sidebar"
                className="sidebar-body"
                width={200}
                collapsible
                collapsedWidth={90}
                collapsed={collapsed}
                onCollapse={this.handleCollapse}
                trigger={this.renderTrigger()}
            >
                <Menu
                    theme="dark"
                    selectedKeys={selectedKey}
                    mode="inline"
                    onClick={this.handleChangeMenu}
                >
                    <Menu.Item
                        key="2"
                        icon={
                            <CrateOutlined
                                className="sidebar-icon"
                                style={{ fontSize: '30px', left: '27px', top: '18px' }}
                            />
                        }
                    >
                        Production
                    </Menu.Item>
                    <Menu.Item
                        key="4"
                        icon={
                            <SalesOutlined
                                className="sidebar-icon"
                                style={{ fontSize: '30px', left: '27px', top: '18px' }}
                            />
                        }
                    >
                        Sales
                    </Menu.Item>
                    <Menu.Item
                        key="1"
                        icon={
                            <BoxOutlined
                                className="sidebar-icon"
                                style={{ fontSize: '35px', left: '23px', top: '18px' }}
                            />
                        }
                    >
                        Inventory
                    </Menu.Item>
                    <Menu.Item
                        key="3"
                        icon={
                            <ShipOutlined
                                className="sidebar-icon"
                                style={{ fontSize: '36px', left: '19px', top: '17px' }}
                            />
                        }
                    >
                        Shipment
                    </Menu.Item>
                </Menu>
            </Sider>
        );
    }

    handleCollapse = (collapsed) => {
        this.setState({ collapsed });
    };

    handleChangeMenu = ({ key }) => {
        const { collapsed } = this.state;
        const { history } = this.props;
        const url = TabController.find((tab) => tab.key === key).url;
        return history.push({ pathname: url, collapsed: collapsed });
    };
}

export default withRouter(SideBar);
