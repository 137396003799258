import { Layout } from "antd";

import Header from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";
import SideBar from "../../components/sidebar/sidebar.component";
import Dashboard from "./inventory.component";
import 'antd/dist/antd.min.css';
const { Content } = Layout;

const DashboardPage = () => (
  <Layout style={{ height: "100vh" }}>
    <Header />
    <Layout style={{ flex: "1" }}>
      <SideBar />
      <Layout className="site-layout" style={{ height: "100%" }}>
        <Content>
          <div
            className="site-layout-background"
            style={{
              height: "100%",
              overflow: "auto"
            }}
          >
            <Dashboard />
          </div>
        </Content>
      </Layout>
    </Layout>
    <FooterComponent />
  </Layout>
);

export default DashboardPage;
