import  { useEffect } from "react";

import { useState } from "react";
import { Dialog } from 'primereact/dialog';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { SalesShipmentLinesService } from "./shipment.services";
const ShipmentLines = (props) => {
  const [tableData, setTableData] = useState([]);
  // const [lineDialogVisible, setLineDialogVisible] = useState(false)
  const [tableLoading, setTableLoading] = useState(true);
  const [filters, setFilters] = useState(null);


  useEffect(() => {
    loadData();
    initFilters();
  }, [props.visible])


const initFilters = () => {
  setFilters({
      'No': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Job_No': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Quantity': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Quantity_Invoiced': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Unit_of_Measure': { value: null, matchMode: FilterMatchMode.CONTAINS },
      'Description': { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
}

const loadData = () => {
  if (props.selectedRow) {
    SalesShipmentLinesService(props.selectedRow).then((result) => {
      setTableData(result);
      setTableLoading(false);
    });
  }
}

  const columns = [
    { field: "No", header: "No" },
    { field: "Job_No", header: "Job No" },
    { field: "Quantity", header: "Quantity" },
    { field: "Quantity_Invoiced", header: "Quantity Invoiced" },
    { field: "Unit_of_Measure", header: "Unit of Measure" },
    { field: "Description", header: "Description" },
  ];

  const headingStyle = {
    color: "gray",
    fontWeight: "500",
  };

  const renderHeader = () => {
    return (
      <div className="d-flex justify-content-between align-items-center">
        <span>
        <h2 style={headingStyle}>Shipment Lines For Shipment#: {props.selectedRow}</h2>
        </span>
      </div>
    );
  };

  const renderColumns = columns.map((col, i) => {
    if(filters){
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.header}
        sortable
        {...(Object.keys(filters).some((filterKey) => filterKey === col.field) && { filter: true })}
      />
    );
    }
  });

  return (
      <Dialog style={{ height: "100%" }} header={renderHeader} visible={props.visible} onHide={props.onHide}>
        <DataTable
          size="large"
          value={tableData}
          removableSort
          dataKey="id"
          responsiveLayout="scroll"
          paginator
          rows={10}
          rowHover
          filters={filters}
          loading={tableLoading}
          sortField="Shipment_Date"
          sortOrder={-1}
          emptyMessage={"No Item found."}
          filterDisplay="row"
          scrollHeight="flex"
          scrollable
        >
          {renderColumns}
        </DataTable>
      </Dialog>
  );
};

export default ShipmentLines;
