// RETURN an array of objects including only unique value of the parameter
export function getUnique(array, param) {
    return [...new Map(array.map((item) => [item[param], item])).values()];
}

// Returns an array of unique values of the parameter
export function isolateUnique(array, param) {
    return [...new Set(array.map((item) => item[param]))];
}

// Returns an array of non-unique values of the parameter
export function isolateNonUnique(array, param) {
    const arrayParams = array.map((item) => item[param]);
    return [...new Set(arrayParams.filter((item, index, self) => self.indexOf(item) !== index))];
}

export function notEmptyArray(array) {
    if (array !== undefined && array !== null && array !== '' && Array.isArray(array)) {
        return array.length !== 0;
    }
    return false;
}

export function notEmptyObject(object) {
    if (isObject(object)) {
        return Object.keys(object).length !== 0;
    }
    return false;
}

export function notEmptyProperty(object, property) {
    if (!object) {
        return false;
    }

    return (
        object.hasOwnProperty(property) &&
        object[property] !== undefined &&
        object[property] !== null
    );
}

export function isObject(object) {
    return typeof object === 'object' && !Array.isArray(object) && object !== null && object !== '';
}

export function toArray(object) {
    if (notEmptyArray(object)) {
        return object;
    }
    return notEmptyObject(object) ? [object] : [];
}
