import React from 'react';
import { Progress } from 'antd';
import './quantity-progress.scss';

class QuantityProgress extends React.Component {
    // If Integer display as integer, if decimal, display one decimal place
    renderPercentage = (percentage) => {
        return Number.isInteger(percentage) ? percentage : Math.round(percentage);
    };

    renderQuantity = (quantity) => {
        return quantity !== 0 ? `(${quantity})` : null;
    };

    render() {
        const { data, type } = this.props;

        const totalQuantity = data.quantity_total;
        const quantityFinished = data.quantity_finished;
        const quantityShipped = data.crates.reduce((qty1, qty2) => qty1 + qty2, 0);

        const percentShipped = (quantityShipped / totalQuantity) * 100;
        const percentFinished = computePercentFinished(
            quantityFinished,
            totalQuantity,
            percentShipped
        );

        // console.log("================================");
        // console.log(data);
        // console.log("Total: ", totalQuantity);
        // console.log("Crates:", data.crates);
        // console.log('Quantity Shipped:', quantityShipped);
        return (
            <div className="quantity-progress">
                <div className="progress-text">
                    {type === 'SALES' ? (
                        <div className="finished">
                            <b>Remaining</b>:<span>{data.quantity_remaining}</span>
                        </div>
                    ) : (
                        <div className="finished">
                            <b>Finished</b>:<span>{this.renderPercentage(percentFinished)}%</span>
                            <span>{this.renderQuantity(quantityFinished)}</span>
                        </div>
                    )}
                    <div className="shipped">
                        <b>In Crate</b>:<span>{this.renderPercentage(percentShipped)}%</span>
                        <span>{this.renderQuantity(quantityShipped)}</span>
                    </div>
                </div>
                <Progress
                    percent={percentFinished}
                    success={{ percent: percentShipped }}
                    showInfo={false}
                />
            </div>
        );
    }
}

// Sometimes a crate has been shipped without it being finished first
function computePercentFinished(quantityFinished, totalQuantity, percentShipped) {
    const percentFinished = (quantityFinished / totalQuantity) * 100;

    // return percentFinished;

    // Products must be finished before it is shipped
    return percentFinished;
    //return percentShipped > percentFinished ? percentShipped : percentFinished;
}

export default QuantityProgress;
