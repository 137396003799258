import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import './edit-inventory-dialog.styles.scss';
import InputNumPad from '../../custom-inputs/input-numpad/input-numpad.component';

const required = [{ required: true, message: 'This field is required!' }];

function EditInventoryDialog({ visible, data, onClose, onComplete }) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (form) {
            form.setFieldsValue(data);
        }
    }, [data, form]);

    const onFinish = (formData) => {
        // --- Update quantity for items array (crate item)
        if (formData.items) {
            let totalQuantity = 0;
            for (let i = 0; i < formData.items.length; i++) {
                data.items[i].quantity = Number(formData.items[i].quantity);
                totalQuantity += Number(formData.items[i].quantity);
            }
            formData.total_quantity = totalQuantity;
        }
        delete formData.items;

        onComplete({ ...data, ...formData });
    };

    const footer = (
        <>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
                Save
            </Button>
        </>
    );

    return (
        <Modal
            title="Edit Inventory"
            className="edit-inventory-component"
            visible={visible}
            onCancel={onClose}
            footer={footer}
            getContainer={false}
        >
            <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item label="Storage Location" name="location">
                    <Input size="large" />
                </Form.Item>
                {/* <Form.Item label="Quantity (pc)" name="total_quantity" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item> */}
                <div style={{ backgroundColor: '#fbfbfb', padding: '18px', borderRadius: '1rem' }}>
                    <h5>Quantity</h5>
                    <div style={{ paddingLeft: '1rem' }}>
                        {data?.items.map((item, index) => {
                            const orderNo = data.items[index].order_no;
                            const partNo = data.items[index].part_no;
                            return (
                                <Form.Item
                                    label={`Crate Item ${index + 1} (Order ${orderNo}, ${partNo})`}
                                    name={['items', index, 'quantity']}
                                    rules={required}
                                >
                                    <InputNumPad size="large" />
                                </Form.Item>
                            );
                        })}
                    </div>
                </div>

                <Form.Item label="Gross Weight (lb)" name="total_gross_weight" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default EditInventoryDialog;
