import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';
// import { isolateNonUnique, isolateUnique } from '../../functions/manageArrays';

const url = CONST_API_URL;

export async function getManufacturingOrderService(division) {
    return axiosInstance
        .get(`${url}/nav/getCombinedData`, { params: { division: division } })
        .then((result) => {
            return result.data;
        })
        .catch((error) => console.log(`getManufacturingOrderService Client - ${error}`));
}

export async function getSalesOrderService(division) {
    return axiosInstance
        .get(`${url}/nav/getCombinedData/sales`, { params: { division: division } })
        .then((result) => {
            return result.data;
        })
        .catch((error) => console.log(`getSalesOrderService Client - ${error}`));
}
