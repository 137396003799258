export function notDoubleDecimal(numbersEntered, input) {
    return !(hasDecimal(numbersEntered) && input === ".");
}

export function notExceededMax(numbersEntered, max) {
    const decimals = countDecimals(numbersEntered);
    return (decimals < max);
}

export function hasDecimal(number) {
    return (!number) ? false : number.toString().includes(".");
}

function countDecimals(number) {
    if (!hasDecimal(number))
        return 0;
    return number.split(".")[1].length;
}
