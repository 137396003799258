import { withRouter } from "react-router-dom";
import { Layout } from "antd";
import Header from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";
import Login from "./login.component";
import 'antd/dist/antd.min.css';
import "./login.styles.css";

const { Content } = Layout;

const LoginPage = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Header />
      <Layout style={{ flex: "1" }}>
        <Layout className="site-layout" style={{ height: "100%" }}>
          <Content style={{ height: "100%", overflow: "auto" }}>
            <Login />
          </Content>
        </Layout>
      </Layout>
      <FooterComponent />
    </Layout>
  );
};

export default withRouter(LoginPage);
