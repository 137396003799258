import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Form, Space, Button, Checkbox, Select, Input } from 'antd';
import { Button as PrimeButton } from 'primereact/button';

import InputWrapper from '../../../../../components/custom-inputs/input-wrapper/input-wrapper.component';
import PalletSizeInput from '../../../../../components/custom-inputs/pallet-input/pallet-input.component';
import WrappedInput from '../../../../../components/custom-inputs/wrapped-elements/wrapped-input.component';
import WrappedInputNumber from '../../../../../components/custom-inputs/wrapped-elements/wrapped-inputNumber.component';

import { BOX_SIZE_CONFIG, PALLET_SIZE_CONFIG } from './create-crate-input.config';
import { insertCrateService } from './create-crate-input.services';
import { setDefault } from '../../../../../functions/setDefault';
import './create-crate-input.styles.css';
import WrappedSelect from '../../../../../components/custom-inputs/wrapped-elements/wrapped-select.component';
import axiosInstance from '../../../../../general/axiosInstance';
import _ from 'lodash';
import { CONST_API_URL } from '../../../../../appconfig';

const required = [{ required: true, message: 'Required!' }];
const defaultValues = {
    crate_id: undefined,
    shrink_wrap: false,
    lid: false,
    total_cont_weight: 0,
};

const DIVISON_OPTIONS_MAP = new Map([
    ['HAVEN', { documentNoOption: false }],
    ['MCKENZIE', { documentNoOption: true }],
]);

const CreateCrate = (props) => {
    const [form] = Form.useForm();
    const [documentNoList, setDocumentNoList] = useState([]);
    const [docNoLoading, setDocNoLoading] = useState(false);

    useEffect(() => {
        form.resetFields();
        props.cancelFinish();

        return () => {};
    }, [props.cancelForm]);

    useEffect(() => {
        const fetchDocumentNo = async (data, division) => {
            try {
                setDocNoLoading(true);
                const uniqueItemNoList = [];
                data?.map((obj) => ({ part_no: obj.part_no, location: division })).forEach(
                    (obj) => {
                        if (!uniqueItemNoList.some((el) => _.isEqual(el, obj))) {
                            uniqueItemNoList.push(obj);
                        }
                    }
                );

                const res = await axiosInstance.post(`${CONST_API_URL}/nav/documentno`, {
                    data: uniqueItemNoList,
                });
                setDocumentNoList(res.data);
            } catch (error) {
                console.log('ERROR - fetchDocumentNo', error);
            } finally {
                setDocNoLoading(false);
            }
        };

        if (DIVISON_OPTIONS_MAP.get(props.division)?.documentNoOption) {
            fetchDocumentNo(props?.data, props.division);
        }

        return () => {};
    }, [props?.data, props.division]);

    const calculateTotalQuantity = (products) => {
        return products.reduce((total, item) => {
            const quantity = setDefault(item.quantity, 0);
            return parseInt(total) + parseInt(quantity);
        }, 0);
    };

    const setInitialValues = () => {
        const { data } = props;
        if (data.hasOwnProperty('total_quantity')) {
            return data;
        }
        const newData = data.map((item) => {
            item.quantity = item.quantity_available;
            return item;
        });
        const totalQuantity = calculateTotalQuantity(newData);
        return { ...defaultValues, total_quantity: totalQuantity, products: [...newData] };
    };

    const initialValues = setInitialValues();
    const mode = props.mode || 'PRODUCTION';

    const handleQuantityChange = () => {
        const fields = form.getFieldsValue();
        const totalQuantity = calculateTotalQuantity(fields.products);
        form.setFieldsValue({ total_quantity: totalQuantity });
    };

    const handleFinish = (data) => {
        const { user, division } = props;

        insertCrateService(data, user, division).then((crateId) => {
            props.onFinish({ ...data, crate_id: crateId });
        });
    };

    const renderDocNoOptions = (index) => {
        if (documentNoList.length < 1 || docNoLoading) {
            return null;
        }
        const product = form.getFieldsValue()?.products[index];
        const { part_no } = product;
        const location = props.division;

        return documentNoList
            .find((obj) => obj.part_no === part_no && obj.location === location)
            ?.data.map((el) => (
                <Select.Option value={el.Document_No} key={el['@odata.etag']}>
                    {`${el.Document_No} (QTY: ${el.Total_Available_Quantity})`}
                </Select.Option>
            ));
    };

    return (
        <div id="crate-component">
            <h2>Create Crate</h2>
            <Form
                form={form}
                initialValues={initialValues}
                onFinish={handleFinish}
                name="create-crates"
            >
                <Form.Item name="crateSourceType" initialValue={mode} hidden />
                <Form.List name="products">
                    {(products, { add, remove }) => (
                        <div>
                            {products.map(
                                (
                                    { key: productKey, name: productName, ...restOrder },
                                    productIdx
                                ) => (
                                    <div
                                        key={productKey}
                                        className={`crate-item item-${productKey} full-width ${
                                            DIVISON_OPTIONS_MAP.get(props.division)
                                                ?.documentNoOption
                                                ? 'crate-item-grid6'
                                                : ''
                                        }`}
                                    >
                                        {form.getFieldsValue()?.products &&
                                        form.getFieldsValue()?.products[productIdx]?.removable !==
                                            true ? (
                                            <>
                                                {mode === 'PRODUCTION' ? (
                                                    <Form.Item
                                                        {...restOrder}
                                                        name={[productName, 'mo']}
                                                    >
                                                        <WrappedInput
                                                            title="Production No."
                                                            placeholder="Production No."
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                        {...restOrder}
                                                        name={[productName, 'so']}
                                                    >
                                                        <WrappedInput
                                                            title="Sales Order No."
                                                            placeholder="Sales Order No."
                                                            disabled
                                                        />
                                                    </Form.Item>
                                                )}
                                                <Form.Item
                                                    {...restOrder}
                                                    name={[productName, 'line_no']}
                                                    rules={required}
                                                >
                                                    <WrappedInput
                                                        title="Line No."
                                                        placeholder="Customer"
                                                        disabled
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restOrder}
                                                    name={[productName, 'customer_code']}
                                                    rules={required}
                                                >
                                                    <WrappedInput
                                                        title="Company"
                                                        placeholder="Customer"
                                                        disabled
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    {...restOrder}
                                                    name={[productName, 'part_no']}
                                                >
                                                    <WrappedInput
                                                        title="Part No."
                                                        placeholder="Part No."
                                                        disabled
                                                    />
                                                </Form.Item>
                                                {DIVISON_OPTIONS_MAP.get(props.division)
                                                    ?.documentNoOption && (
                                                    <div>
                                                        <Form.Item
                                                            {...restOrder}
                                                            name={[productName, 'document_no']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Please select Document No',
                                                                },
                                                            ]}
                                                        >
                                                            <WrappedSelect
                                                                title="Document No."
                                                                placeholder="Document No."
                                                                options={renderDocNoOptions(
                                                                    productIdx
                                                                )}
                                                                loading={docNoLoading}
                                                            />
                                                        </Form.Item>
                                                        <div className="crateitemline-control__container">
                                                            <PrimeButton
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    const product = {
                                                                        ...form.getFieldsValue()
                                                                            .products[productIdx],
                                                                    };
                                                                    const targetProduct = {
                                                                        ...product,
                                                                        removable: true,
                                                                        document_no: undefined,
                                                                        quantity: 0,
                                                                    };
                                                                    add(
                                                                        targetProduct,
                                                                        productIdx + 1
                                                                    );
                                                                }}
                                                                onTouchEnd={(e) => {
                                                                    e.preventDefault();
                                                                    const product = {
                                                                        ...form.getFieldsValue()
                                                                            .products[productIdx],
                                                                    };
                                                                    const targetProduct = {
                                                                        ...product,
                                                                        removable: true,
                                                                        document_no: undefined,
                                                                        quantity: 0,
                                                                    };
                                                                    add(
                                                                        targetProduct,
                                                                        productIdx + 1
                                                                    );
                                                                }}
                                                                icon="pi pi-plus-circle"
                                                                label="Add"
                                                                className="p-button-sm p-button-outlined crateitemline-control__button"
                                                                type="button"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <Form.Item
                                                    {...restOrder}
                                                    name={[productName, 'quantity']}
                                                    rules={[
                                                        { required },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (value <= 0) {
                                                                    return Promise.reject(
                                                                        'Quantity should be greater than 0!'
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const product =
                                                                    getFieldValue().products[
                                                                        productIdx
                                                                    ];
                                                                const documentNo =
                                                                    product?.document_no;
                                                                const partNo = product?.part_no;
                                                                const documentNoQty = documentNoList
                                                                    .find(
                                                                        (obj) =>
                                                                            obj.part_no === partNo
                                                                    )
                                                                    ?.data?.find(
                                                                        (obj) =>
                                                                            obj.Document_No ===
                                                                            documentNo
                                                                    )?.Total_Available_Quantity;
                                                                if (value > documentNoQty) {
                                                                    return Promise.reject(
                                                                        'Quantity should be less than Document No quantity'
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <WrappedInputNumber
                                                        title="Quantity"
                                                        placeholder="Quantity_Crated"
                                                        onChange={handleQuantityChange}
                                                    />
                                                </Form.Item>
                                            </>
                                        ) : (
                                            <>
                                                <Form.Item />
                                                <Form.Item />
                                                <Form.Item />
                                                <Form.Item />
                                                {DIVISON_OPTIONS_MAP.get(props.division)
                                                    ?.documentNoOption && (
                                                    <div>
                                                        <Form.Item
                                                            {...restOrder}
                                                            name={[productName, 'document_no']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Please select Document No',
                                                                },
                                                            ]}
                                                        >
                                                            <WrappedSelect
                                                                title="Document No."
                                                                placeholder="Document No."
                                                                options={renderDocNoOptions(
                                                                    productIdx
                                                                )}
                                                                loading={docNoLoading}
                                                            />
                                                        </Form.Item>
                                                        <div className="crateitemline-control__container">
                                                            <PrimeButton
                                                                onClick={(e) => {
                                                                    e.preventDefault();

                                                                    const product = {
                                                                        ...form.getFieldsValue()
                                                                            .products[productIdx],
                                                                    };
                                                                    const targetProduct = {
                                                                        ...product,
                                                                        removable: true,
                                                                        document_no: undefined,
                                                                        quantity: 0,
                                                                    };
                                                                    add(
                                                                        targetProduct,
                                                                        productIdx + 1
                                                                    );
                                                                }}
                                                                onTouchEnd={(e) => {
                                                                    e.preventDefault();
                                                                    const product = {
                                                                        ...form.getFieldsValue()
                                                                            .products[productIdx],
                                                                    };
                                                                    const targetProduct = {
                                                                        ...product,
                                                                        removable: true,
                                                                        document_no: undefined,
                                                                        quantity: 0,
                                                                    };
                                                                    add(
                                                                        targetProduct,
                                                                        productIdx + 1
                                                                    );
                                                                }}
                                                                icon="pi pi-plus-circle"
                                                                label="Add"
                                                                className="p-button-sm p-button-outlined crateitemline-control__button"
                                                                type="button"
                                                            />
                                                            <PrimeButton
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    console.log('REMOVE', {
                                                                        productName,
                                                                    });
                                                                    remove(productName);
                                                                }}
                                                                onTouchEnd={(e) => {
                                                                    e.preventDefault();
                                                                    remove(productName);
                                                                }}
                                                                icon="pi pi-minus-circle"
                                                                label="Remove"
                                                                className="p-button-danger p-button-sm p-button-outlined ms-1 crateitemline-control__button"
                                                                type="button"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                <Form.Item
                                                    {...restOrder}
                                                    name={[productName, 'quantity']}
                                                    rules={[
                                                        { required },
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                if (value <= 0) {
                                                                    return Promise.reject(
                                                                        'Quantity should be greater than 0!'
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                        ({ getFieldValue }) => ({
                                                            validator(_, value) {
                                                                const product =
                                                                    getFieldValue().products[
                                                                        productIdx
                                                                    ];
                                                                const documentNo =
                                                                    product?.document_no;
                                                                const partNo = product?.part_no;
                                                                const documentNoQty = documentNoList
                                                                    .find(
                                                                        (obj) =>
                                                                            obj.part_no === partNo
                                                                    )
                                                                    ?.data?.find(
                                                                        (obj) =>
                                                                            obj.Document_No ===
                                                                            documentNo
                                                                    )?.Total_Available_Quantity;
                                                                if (value > documentNoQty) {
                                                                    return Promise.reject(
                                                                        'Quantity should be less than Document No quantity'
                                                                    );
                                                                }
                                                                return Promise.resolve();
                                                            },
                                                        }),
                                                    ]}
                                                >
                                                    <WrappedInputNumber
                                                        title="Quantity"
                                                        placeholder="Quantity_Crated"
                                                        onChange={handleQuantityChange}
                                                    />
                                                </Form.Item>
                                            </>
                                        )}
                                    </div>
                                )
                            )}
                        </div>
                    )}
                </Form.List>
                <div className="item-total">
                    <Form.Item key="total-quantity" name="total_quantity">
                        <WrappedInput
                            title="Total Quantity"
                            placeholder="Total Quantity"
                            disabled
                        />
                    </Form.Item>
                </div>
                <hr key="divider-1" style={{ color: 'gray' }} />
                <Space key="crate-details" size={24} className="full-width">
                    <Form.Item key="gross_weight" name="total_gross_weight" rules={required}>
                        <WrappedInputNumber title="Gross Weight" placeholder="Gross Weight" />
                    </Form.Item>
                    <Form.Item key="storage_location" name="location">
                        <WrappedInput title="Storage Location" placeholder="Storage Location" />
                    </Form.Item>
                </Space>
                <Space key="container-details" size={24} className="full-width">
                    <InputWrapper title="Container Details">
                        <Space size={50}>
                            <Form.Item key="box_type" name="box_type">
                                <PalletSizeInput content={BOX_SIZE_CONFIG} />
                            </Form.Item>
                            <Form.Item key="pallet_type" name="pallet_type">
                                <PalletSizeInput content={PALLET_SIZE_CONFIG} />
                            </Form.Item>
                            <Form.Item key="shrink_wrap" name="shrink_wrap" valuePropName="checked">
                                <Checkbox className="bulky-checkbox checkbox-title">
                                    Shrink Wrap
                                </Checkbox>
                            </Form.Item>
                            <Form.Item key="lid" name="lid" valuePropName="checked">
                                <Checkbox className="bulky-checkbox checkbox-title">LID</Checkbox>
                            </Form.Item>
                        </Space>
                    </InputWrapper>
                </Space>
                <Form.Item key="cont_weight" name="total_cont_weight" hidden>
                    <WrappedInputNumber />
                </Form.Item>
                <Form.Item key="crate_id" name="crate_id" hidden>
                    <WrappedInputNumber />
                </Form.Item>
                <hr key="divider-2" />
                <Form.Item>
                    <Space style={{ float: 'right', marginTop: '15px' }}>
                        <Button className="primary-btn" onClick={() => form.submit()}>
                            Next
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default connect(mapStateToProps)(withRouter(CreateCrate));
