import Icon from '@ant-design/icons';

export const CrateOutlined = props => <Icon component={CrateSvg} {...props} />;

const CrateSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0, 0, 400, 400">
        <path id="path0" d="M4.482 5.420 C 1.177 9.402,0.204 60.462,0.958 190.460 L 2.000 370.000 100.000 370.000 C 195.333 370.000,198.000 369.782,198.000 
        362.000 C 198.000 354.385,194.966 353.947,135.000 352.898 L 72.000 351.797 72.000 338.015 L 72.000 324.232 125.000 323.116 L 178.000 322.000 178.000 
        312.000 C 178.000 302.609,176.472 301.878,152.925 300.000 L 127.849 298.000 213.714 212.245 L 299.579 126.490 300.790 152.245 C 301.940 176.728,302.494 
        178.000,312.000 178.000 L 322.000 178.000 323.086 101.000 L 324.173 24.000 338.008 24.000 L 351.844 24.000 352.922 111.000 C 353.963 194.974,354.278 
        198.000,362.000 198.000 C 369.782 198.000,370.000 195.333,370.000 100.000 L 370.000 2.000 189.523 0.960 C 40.324 0.100,8.256 0.872,4.482 5.420 M52.000 
        188.000 L 52.000 352.000 38.000 352.000 L 24.000 352.000 24.000 188.000 L 24.000 24.000 38.000 24.000 L 52.000 24.000 52.000 188.000 M300.000 38.000 
        L 300.000 52.000 186.000 52.000 L 72.000 52.000 72.000 38.000 L 72.000 24.000 186.000 24.000 L 300.000 24.000 300.000 38.000 M144.000 125.083 L 144.000 
        178.167 108.000 214.000 L 72.000 249.833 72.000 160.917 L 72.000 72.000 108.000 72.000 L 144.000 72.000 144.000 125.083 M248.000 73.075 C 248.000 
        73.666,230.000 92.083,208.000 114.000 L 168.000 153.850 168.000 112.925 L 168.000 72.000 208.000 72.000 C 230.000 72.000,248.000 72.484,248.000 73.075
         M300.000 80.972 C 300.000 93.730,93.782 300.000,81.028 300.000 C 57.768 300.000,70.945 283.111,176.972 177.028 C 283.033 70.910,300.000 57.663,300.000
          80.972 M258.560 194.528 C 192.772 214.734,166.782 306.122,211.463 360.134 C 252.044 409.190,338.032 410.231,374.009 362.102 C 439.681 274.248,362.719
           162.537,258.560 194.528 M350.000 255.006 C 350.000 257.393,333.073 277.645,312.385 300.010 L 274.770 340.675 254.509 320.414 C 238.561 304.465,235.287
            299.113,239.130 295.270 C 242.973 291.427,247.300 293.599,259.458 305.471 L 274.905 320.554 296.982 297.277 C 309.124 284.475,323.994 268.075,330.026
             260.832 C 340.430 248.341,350.000 245.550,350.000 255.006"/>
    </svg>
);