import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';
import _ from 'lodash';
import { Row, Dropdown, Menu } from 'antd';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { FilterMatchMode, FilterOperator, FilterService } from 'primereact/api';

import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import ShipIcon from '../../assets/icons/Ship.svg';
import ShipCrateDialog from '../../components/dialog/shipcratedialog.component';
import {
    getCratesAndCrateItems,
    getDailyCreatedAndShippedTotalWeight,
    updateCrate,
    deleteCrate,
    updateCrateAndCrateItems,
} from './inventory.services';
import './inventory.styles.css';
import QRCodeDialog from '../../components/dialog/qrcodedialog.component';
import ReportDialog from '../../components/dialog/reportdialog.component copy';
import BOLTableDialog from '../../components/dialog/bolTabledialog.compnent';
import PackingListTable from '../../components/dialog/packinglistreporttabledialog.compnent';
import EditInventoryDialog from '../../components/dialog/edit-inventory/edit-inventory-dialog.component';
import DeleteCrateDialog from '../../components/dialog/delete-crate/delete-crate-dialog.component';
import { dateIsAfter } from '../../general/componentSetup';
const columns = [
    { field: 'id', header: 'Crate ID' },
    { field: 'customer_code', header: 'Customer' },
    { field: 'date_for_search', header: 'Date' },
    { field: 'part_no', header: 'Part #' },
    { field: 'mo_no', header: 'MO#' },
    { field: 'customer_po_no', header: 'Customer PO#' },
    { field: 'location', header: 'Location' },
    { field: 'total_quantity', header: 'Total Quantity' },
    { field: 'total_gross_weight', header: 'Gross Wt.' },
];

// --- filters setup ---
FilterService.register('customDateIsAfter', dateIsAfter);

const dateFilterMatchModeOptions = [
    { label: 'Date is', value: FilterMatchMode.DATE_IS },
    { label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: 'customDateIsAfter' },
];

const initialFilters = {
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    id: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    customer_code: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    part_no: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    mo_no: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    customer_po_no: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    location: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
    },
    date_for_search: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
    },
    total_quantity: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    total_gross_weight: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
};
// --- filters setup done ---

const Dashboard = (props) => {
    const [dataset, setDataset] = useState(null);
    const [tableData, setTableData] = useState(null);
    const [rowData, setRowData] = useState(null);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [showFooterFlag, setShowFooterFlag] = useState(false);
    const [totalWeight, setTotalWeight] = useState(0);
    const [dailyShippedWeight, setDailyShippedWeight] = useState(0);
    const [shipDialogVisible, setShipDialogVisible] = useState(false);
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [QRCodeDialogVisible, setQRCodeDialogVisible] = useState(false);
    const [printableDocVisible, setPrintableDocVisible] = useState(false);
    const [bolTableVisible, setBolTableVisible] = useState(false);
    const [packingListTableVisible, setPackingListTableVisible] = useState(false);
    const [filters, setFilters] = useState(null);
    const toast = useRef(null);
    console.log('inventory', props.division);

    useEffect(() => {
        setTableLoading(true);
        fetchTableData();
        initFilters();
    }, [props.division]);

    const initFilters = () => {
        setFilters(initialFilters);
        setGlobalFilterValue('');
        setShowFooterFlag(false);
    };

    async function fetchTableData() {
        const structuredCrates = await getCratesAndCrateItems(props.division);
        if (structuredCrates) {
            setDataset(structuredCrates);
            console.log('Crate format', structuredCrates);

            structuredCrates.forEach((crate) => {
                crate.created_date = moment
                    .utc(crate.created_datetime)
                    .local()
                    .format('MM/DD/YYYY');
                crate.date_for_search = new Date(crate.created_datetime);
                crate.due_date = crate.items[0]?.due_date
                    ? moment.utc(crate.items[0]?.due_date).local().format('MM/DD/YYYY')
                    : '';
                crate.order_no = crate.items[0]?.order_no;
                crate.part_no = crate.items[0]?.part_no;
                crate.mo_no = crate.items
                    .map((u) => u.order_no)
                    .filter(Boolean)
                    .join(', ');
                crate.customer_po_no = crate.items
                    .map((u) => u.external_document_no)
                    .filter(Boolean)
                    .join(', ');
            });

            setTableData(structuredCrates);
        }
        setTableLoading(false);
    }

    useEffect(() => {
        if (tableLoading) {
            setTableLoading(false);
        }
    }, [tableData, props.division]);

    const onGlobalFilterChange = (e) => {
        const { value } = e.target;
        const _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleOnFilterClear = (field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: initialFilters[field],
        }));
    };

    const handleOnFilterMatchModeChange = ({ field, matchMode }) => {
        setFilters((prev) => ({
            ...prev,
            [field]: { ...prev[field], matchMode },
        }));
    };

    const onClickHandler = (e) => {
        setShipDialogVisible(true);
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const onClickBOLHandler = (e) => {
        setBolTableVisible(true);
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const onClickPackingListHandler = (e) => {
        setPackingListTableVisible(true);
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const reportMenu = (
        <Menu>
            <Menu.Item key="0">
                <a onClick={onClickBOLHandler}>Bill of Lading</a>
            </Menu.Item>
            <Menu.Item key="1">
                <a onClick={onClickPackingListHandler}>Packing List Report</a>
            </Menu.Item>
            <Menu.Item key="2">
                <a onClick={() => setPrintableDocVisible(true)}>Finished Goods In Warehouse</a>
            </Menu.Item>
        </Menu>
    );

    const formatDate = (value) =>
        value.toLocaleDateString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        });

    const dateFilterTemplate = (options) => (
        <Calendar
            value={options.value}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
            mask="99/99/9999"
        />
    );

    const dateBodyTemplate = (rowData) => {
        return formatDate(rowData.date_for_search);
    };

    const onRowEditComplete = async (e) => {
        // console.log("onRowEditComplete:", e);
        setTableLoading(true);

        const prevData = e.data;
        const { newData } = e;
        if (!_.isEqual(prevData, newData)) {
            const result = await updateCrate(newData);
            if (result) {
                const _tableData = [...tableData];
                const index = tableData.findIndex((obj) => obj.id === prevData.id);
                _tableData[index] = newData;
                // console.log("TEST-tabledata", index, _tableData);
                setTableData(_tableData);
            } else {
                console.log('ERROR - onRowEditComplete', result);
                toast.current.show({
                    severity: 'error',
                    summary: 'Request failed',
                    detail: 'Unexpected error occurred',
                    life: 3000,
                });
            }
        }
        setTableLoading(false);
    };

    const textEditor = (options) => (
        <InputText
            type="text"
            value={options.value}
            onChange={(e) => options.editorCallback(e.target.value)}
            style={{ width: '100%' }}
        />
    );

    const refreshTable = (e) => {
        setTableLoading(true);
        fetchTableData();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const numberEditor = (options) => (
        <InputNumber
            value={options.value}
            onChange={(e) => {
                options.editorCallback(e.value);
            }}
            style={{ width: '100%' }}
        />
    );

    const clearFilter = (e) => {
        initFilters();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const onFilterHandler = async (e) => {
        setFilters(e.filters);
        // console.log('onFilterHandler', e.filters);

        // --- get total gross weight of crates which are created and already shipped for the selected date ---
        if (e.filters.date_for_search.constraints[0].value !== null) {
            let updateShippedWeightFg = true;

            // --- check if other filters are on ---
            for (const key in e.filters) {
                const filter = e.filters[key];
                if (
                    (key === 'global' && filter.value !== null) ||
                    (key !== 'global' &&
                        key !== 'date_for_search' &&
                        filter.constraints[0].value !== null)
                ) {
                    updateShippedWeightFg = false;
                    break;
                }
            }

            if (updateShippedWeightFg) {
                // --- get data and update state ---
                const { constraints } = e.filters.date_for_search;
                const formattedDates = constraints
                    .filter(
                        (constraint) =>
                            constraint.matchMode === 'dateIs' && constraint.value !== null
                    )
                    .map((constraint) => moment.utc(constraint.value).format('YYYY-MM-DD'));
                if (!_.isEmpty(formattedDates)) {
                    //console.log('formattedDates', formattedDates);
                    const resData = await getDailyCreatedAndShippedTotalWeight(formattedDates);
                    setDailyShippedWeight(resData?.totalshippedweight ?? 0);
                } else {
                    setDailyShippedWeight(0);
                }
            } else {
                setDailyShippedWeight(0);
            }
        }
    };

    const renderHeader = () => {
        const headingStyle = {
            color: 'gray',
            fontWeight: '500',
            margin: '0',
            marginRight: '0.4em',
        };

        return (
            <div className="d-flex justify-content-between align-items-center">
                <Row className="align-items-center">
                    <h2 style={headingStyle}>Warehouse Goods</h2>
                    {/* <Button
            icon="pi pi-print"
            className="p-button-rounded p-button-danger p-button-outlined"
            onClick={() => setPrintableDocVisible(true)}
            style={{ borderRadius: '10%' }}
          /> */}
                    <Button
                        icon="pi pi-refresh"
                        className="p-button-rounded p-button-danger p-button-outlined"
                        onClick={refreshTable}
                        onTouchEnd={refreshTable}
                        style={{ borderRadius: '10%', marginLeft: '10px' }}
                    />
                    {showFooterFlag ? (
                        <Button
                            icon="pi pi-filter-slash"
                            className="p-button-rounded p-button-danger p-button-outlined"
                            onClick={clearFilter}
                            onTouchEnd={clearFilter}
                            style={{ borderRadius: '10%', marginLeft: '10px' }}
                        />
                    ) : (
                        ''
                    )}
                </Row>
                <span className="p-input-icon-left ms-auto">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Global Search"
                        style={{ marginRight: '1em' }}
                        className="p-inputtext"
                    />
                </span>
                <Button className="p-button-lg ship-button" onClick={onClickHandler}>
                    <Row>
                        <img src={ShipIcon} alt="ship icon" height="30px" className="me-2" />
                        <span>Ship</span>
                    </Row>
                </Button>
                {/* <Button
          style={{ marginLeft: '5px' }}
          type="button"
          icon="pi pi-list"
          label="BOL"
          className="p-button-lg secondary-btn lading-button"
          onClick={onClickBOLHandler}
        /> */}
                <Dropdown overlay={reportMenu} placement="bottomLeft" trigger={['click', 'hover']}>
                    <Button
                        style={{ marginLeft: '5px' }}
                        type="button"
                        icon="pi pi-list"
                        className="p-button-lg secondary-btn lading-button"
                        label="Reports"
                    />
                </Dropdown>
            </div>
        );
    };

    const renderColumns = columns
        // .filter((column) => column.field !== "customer_code")
        .map((col, i) => {
            if (filters) {
                return (
                    <Column
                        key={col.field}
                        field={col.field}
                        header={col.header}
                        sortable
                        // onFilterApplyClick={(e) => handleFilterApplyClick(e)}
                        onFilterClear={() => handleOnFilterClear(col.field)}
                        {...(Object.keys(filters).some((filterKey) => filterKey === col.field) && {
                            filter: true,
                        })}
                        {...(col.field === 'location' && {
                            editor: (options) => textEditor(options),
                        })}
                        {...((col.field === 'total_quantity' ||
                            col.field === 'total_gross_weight' ||
                            col.field === 'total_contained_weight') && {
                            dataType: 'numeric',
                            editor: (options) => numberEditor(options),
                        })}
                        {...(col.field === 'date_for_search' && {
                            filter: true,
                            filterElement: dateFilterTemplate,
                            body: dateBodyTemplate,
                            dataType: 'date',
                            filterMatchModeOptions: dateFilterMatchModeOptions,
                        })}
                        {...(col.field === 'id' && { dataType: 'numeric' })}
                        style={{ minWidth: '50px' }}
                    />
                );
            }
        });

    const renderfooter = () => {
        let message = '';

        if (dailyShippedWeight) {
            message += message = `The sum of weight is ${
                totalWeight + dailyShippedWeight ?? 0
            } (inventory: ${totalWeight}, shipped: ${dailyShippedWeight})`;
        } else {
            message = `The sum of weight is ${totalWeight ? totalWeight : 0}.`;
        }

        if (showFooterFlag) {
            return message;
        }
        return '';
    };

    const handleEdit = (rowData) => {
        setRowData(rowData);
        setEditDialogVisible(true);
    };
    const handleDelete = (rowData) => {
        setRowData(rowData);
        setDeleteDialogVisible(true);
    };

    const tableEditTemplate = (rowData) => (
        <>
            <Button
                onClick={() => handleEdit(rowData)}
                icon="pi pi-pencil"
                className="p-button-squared p-button-outlined"
            />
            <Button
                style={{ marginLeft: '10px' }}
                onClick={() => handleDelete(rowData)}
                icon="pi pi-trash"
                className="p-button-squared p-button-danger p-button-outlined"
            />
            <Button
                style={{ marginLeft: '10px' }}
                icon="pi pi-qrcode"
                className="p-button-squared p-button-danger p-button-outlined"
                onClick={() => {
                    setRowData(rowData);
                    setQRCodeDialogVisible(true);
                }}
            />
        </>
    );

    const handleEditComplete = async (newData) => {
        setEditDialogVisible(false);
        setTableLoading(true);

        const prevData = rowData;
        // return;
        if (!_.isEqual(prevData, newData)) {
            const result = await updateCrateAndCrateItems(newData);
            if (result) {
                fetchTableData();
                // console.log(result);
                // let _tableData = [...tableData];
                // const index = tableData.findIndex((obj) => obj.id === prevData.id);
                // _tableData[index] = newData;
                // setTableData(_tableData);
            } else {
                console.log('ERROR - onRowEditComplete', result);
                toast.current.show({
                    severity: 'error',
                    summary: 'Request failed',
                    detail: 'Unexpected error occurred',
                    life: 3000,
                });
            }
        }
        setTableLoading(false);
    };

    const handleDeleteComplete = async (newData) => {
        setDeleteDialogVisible(false);
        setTableLoading(true);

        const prevData = tableData;
        if (!_.isEqual(prevData, newData)) {
            const result = await deleteCrate(newData);
            if (result) {
                fetchTableData();
                // console.log(result);
                // let _tableData = [...tableData];
                // const index = tableData.findIndex((obj) => obj.id === prevData.id);
                // _tableData[index] = newData;
                // setTableData(_tableData);
            } else {
                console.log('ERROR - onRowEditComplete', result);
                toast.current.show({
                    severity: 'error',
                    summary: 'Request failed',
                    detail: 'Unexpected error occurred',
                    life: 3000,
                });
            }
        }
        setTableLoading(false);
    };

    const handleShipDialogVisibleAndRefreshTable = (bool) => {
        setShipDialogVisible(bool);
        refreshTable();
    };

    const calculateTotalFilteredWeight = (data) => {
        setShowFooterFlag(true);
        let tempData = data;
        let weightSum = tempData.reduce(function (a, b) {
            return a + b['total_gross_weight'];
        }, 0);
        setTotalWeight(weightSum);
        setDataset(data);
        if (data === tableData) {
            setShowFooterFlag(false);
        }
    };

    return (
        <>
            <Toast ref={toast} />
            <ShipCrateDialog
                visible={shipDialogVisible}
                setVisible={(bool) => handleShipDialogVisibleAndRefreshTable(bool)}
                division={props.division}
            />
            <QRCodeDialog
                visible={QRCodeDialogVisible}
                data={rowData}
                setVisible={(bool) => setQRCodeDialogVisible(bool)}
                division={props.division}
            />
            <EditInventoryDialog
                visible={editDialogVisible}
                onClose={() => setEditDialogVisible(false)}
                onComplete={handleEditComplete}
                data={rowData}
            />
            <DeleteCrateDialog
                visible={deleteDialogVisible}
                onClose={() => setDeleteDialogVisible(false)}
                onComplete={handleDeleteComplete}
                data={rowData}
            />
            <ReportDialog
                visible={printableDocVisible}
                dataset={dataset}
                setVisible={(bool) => setPrintableDocVisible(bool)}
                division={props.division}
            />
            <BOLTableDialog
                visible={bolTableVisible}
                setVisible={(bool) => setBolTableVisible(bool)}
                division={props.division}
            />
            <PackingListTable
                visible={packingListTableVisible}
                setVisible={(bool) => setPackingListTableVisible(bool)}
                division={props.division}
            />
            <div className="inventory-component" style={{ height: '100%', width: '100%' }}>
                <DataTable
                    id="finishedgoodstable"
                    value={tableData}
                    header={renderHeader}
                    footer={renderfooter}
                    dataKey="id"
                    onValueChange={(filteredData) => calculateTotalFilteredWeight(filteredData)}
                    filterDisplay="menu"
                    sortMode="single"
                    sortOrder={1}
                    scrollable // if scrollable = true, use flex-basis for column width
                    scrollHeight="flex"
                    // sortField="customer_code"
                    // rowGroupMode="subheader"
                    // groupRowsBy="customer_code"
                    // rowGroupHeaderTemplate={rowGroupHeaderTemplate}
                    // rowGroupFooterTemplate={rowGroupFooterTemplate}
                    editMode="row"
                    onRowEditComplete={onRowEditComplete}
                    responsiveLayout="scroll"
                    rowHover
                    filters={filters}
                    loading={tableLoading}
                    emptyMessage="No records found."
                    onRowClick={({ data }) => setRowData(data)}
                    onFilter={onFilterHandler}
                >
                    {renderColumns}
                    {/* <Column rowEditor bodyStyle={{ textAlign: "center" }} style={{ flex: "0 0 6rem" }} /> */}
                    <Column body={tableEditTemplate} />
                    {/* <Column
                        style={{ flex: '0 0 4rem' }}
                        bodyStyle={{ textAlign: 'center' }}
                        body={QRButtonTemplate}
                    /> */}
                </DataTable>
            </div>
        </>
    );
};
const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(Dashboard));
