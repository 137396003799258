import React from 'react'
import './progressDiagram.styles.css'

class ProgressDiagramFlex extends React.Component {
    state = {}
    render() {
        // console.log(this.props);
        return (
            <div className="progressDiagramTotal">
                <div className="progressDiagramFlexNumber">
                    {this.props.number}
                </div>

                <div className="progressDiagramFlexBox">
                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 87.71 100" className={"BoxLabel"}>
                        <g>
                            <path className="cls-1" d="M94,29.72v.89Q94,53.29,94,76a1.16,1.16,0,0,1-.68,1.17Q73.72,88.43,54.11,99.78l-.74.41c0-.31,0-.55,0-.79q0-22.77,0-45.56a1,1,0,0,1,.6-1L93.34,30.07C93.52,30,93.72,29.87,94,29.72Z" transform="translate(-6.32 -0.19)" />
                            <path className="cls-1" d="M28.82,54.17V43c.26.12.44.18.61.28l16.72,9.63a.94.94,0,0,1,.6.92q0,22.82,0,45.65c0,.1,0,.2,0,.41-.23-.11-.43-.18-.61-.28Q26.48,88.28,6.86,77a.89.89,0,0,1-.54-.87q0-22.81,0-45.65c0-.12,0-.24,0-.44l.62.3q7.32,4.22,14.64,8.43a1.06,1.06,0,0,1,.61,1.06c0,3.44,0,6.89,0,10.33a1,1,0,0,0,.63,1c1.78.88,3.54,1.81,5.3,2.71C28.33,54,28.54,54,28.82,54.17Z" transform="translate(-6.32 -0.19)" />
                            <path className="cls-1" d="M91.78,24.24,84.24,28.6Q67.46,38.31,50.69,48a1.17,1.17,0,0,1-1.35,0c-5.86-3.41-11.74-6.79-17.65-10.19.17-.11.38-.27.61-.4q20.13-11.64,40.27-23.3a1.34,1.34,0,0,1,1.55,0c5.64,3.25,11.31,6.47,17,9.69C91.28,23.93,91.47,24.05,91.78,24.24Z" transform="translate(-6.32 -0.19)" />
                            <path className="cls-1" d="M66.07,9.43c-.71.42-1.35.8-2,1.17L24.92,33.23c-.33.19-.59.35-1,.1q-7.65-4.45-15.32-8.88a3.3,3.3,0,0,1-.31-.22c.27-.16.48-.31.71-.44L49.38.44c.34-.19.61-.39,1.08-.12,5.1,3,10.21,5.94,15.32,8.9C65.84,9.25,65.9,9.31,66.07,9.43Z" transform="translate(-6.32 -0.19)" />
                        </g>
                    </svg>
                </div>
            </div>
        );
    }
}

export default ProgressDiagramFlex;