import React from "react";
import { Checkbox, Space } from "antd";

import './pallet-input.styles.css';

/* @ REQUIRED PROPS
    1) content - an array of objects specifying the contents of the pallet-size input. 
    Should be defined in this format: { type, key, labels }

        a) type     = name of the Pallet Group, e.g. Box Size, Pallet Size, etc.
        b) key      = serves as the react component key values
        c) sizes    = specifies the different sizes 

    2) returnSizeOnly - boolean that toggles the return value of the checkbox.
        - TRUE  = "{key}-{size}"
        - FALSE = "{size}"
*/
class PalletSizeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedValue: undefined
        }
    }

    renderCheckbox = (parentKey, labels) => {
        const { returnSizeOnly } = this.props;
        return labels.map(label => {
            const fullKey = `${parentKey}-${label.toLowerCase()}`;
            const key = (returnSizeOnly) ? `${label.toLowerCase()}` : fullKey;
            return (
                <Checkbox className="bulky-checkbox" 
                    key={fullKey} 
                    checked={this.setChecked(key)} 
                    onChange={() => this.handleChange(key)}>
                    { label }
                </Checkbox>
            )
        })
    }

    renderContent = (contents) => {
        return contents.map(content => (
            <Space key={`${content.key}-pallet-input`}>
                <label key={`${content.key}-title`} className={ this.setActiveClass(content.key) }>{ content.type }</label>
                { this.renderCheckbox(content.key, content.sizes) }
            </Space>
        ));
    }

    render() {
        const { content } = this.props;
        return (
            <div id="pallet-size-input" className="pallet-size-input-container">
                { this.renderContent(content) }
            </div>
        );
    }

    setActiveClass = (key) => {
        const classList = ['type-label'];
        const { checkedValue } = this.state;
        const type = (checkedValue) ? checkedValue.split('-')[0] : null;
        if (key === type) classList.push('current-type');
        return classList.join(' ');
    }

    setChecked = (size) => {
        return this.state.checkedValue === size;
    }

    handleChange = (size) => {
        const { checkedValue } = this.state;
        size = (size === checkedValue) ? undefined : size;
        
        this.setState({ checkedValue: size },
            this.props.onChange?.(size)
        );
    }
}

PalletSizeInput.defaultProps = {
    content : [{ type: 'Pallet Size', key: 'pallet', sizes: ['SM', 'LG', 'XL'] }],
    returnSizeOnly: true
}

export default PalletSizeInput