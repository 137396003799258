import axiosInstance from '../../../general/axiosInstance';
import { CONST_API_URL } from '../../../appconfig';

const url = CONST_API_URL;

export function insertCarrierTo(data) {
    return axiosInstance.post(`${url}/report/insertCarrierTo`, { data }).catch((error) => {
        return null;
    });
}

export function updateCarrierTo(data) {
    return axiosInstance.put(`${url}/report/updateCarrierTo`, { data }).catch((error) => {
        return null;
    });
}
