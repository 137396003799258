import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function GetBOLReportService(division) {
    return axiosInstance.post(`${url}/report/billoflading/getbolreport`,{division:division}).then(({ data: result }) =>
        [...(result || [])].map((d) => {
            d.created_datetime = new Date(d.created_datetime);
            return d;
        })
    );
}

export function GetBOLReportLineService(report_no) {
    return axiosInstance
        .get(`${url}/report/billoflading/getbolreportLine`, { params: { report_no } })
        .then(({ data: result }) => result);
}
