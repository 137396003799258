import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export async function getCratesAndCrateItems(division) {
    try {
        // console.log(`${url}/item/,division:${division}`);
        const result = await axiosInstance.get(`${url}/item`, { params: { division } });

        if (result.data) {
            console.log('result.data', result.data);
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCrates() {
    try {
        const result = await axiosInstance.get(`${url}/item/crate`);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCrateItems() {
    try {
        const result = await axiosInstance.get(`${url}/item/crateitem`);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getCrateItemsWithCrateHeader() {
    try {
        const result = await axiosInstance.get(`${url}/item/crateitem/table`);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSalesOrders(so_no) {
    try {
        const result = await axiosInstance.get(`${url}/nav/getSalesOrders`, { params: { so_no } });

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function scanSalesOrderArchive(so_no) {
    try {
        const result = await axiosInstance.get(`${url}/nav/salesorder/archive`, {
            params: { so_no },
        });

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getSalesOrderLine(so_no) {
    try {
        const result = await axiosInstance.get(`${url}/nav/getSalesOrderLine`, {
            params: { so_no },
        });

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getDropdown(name = undefined) {
    try {
        const result = await axiosInstance.get(`${url}/dropdown`, { params: { name } });

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getNextBOLReportNo() {
    try {
        const result = await axiosInstance.get(`${url}/report/billoflading/bolnextreportno`);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function updateCrate(data) {
    try {
        const result = await axiosInstance.put(`${url}/item/crate`, data);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function updateCrateAndCrateItems(data) {
    try {
        const result = await axiosInstance.put(`${url}/item/crateandcrateitems`, data);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function deleteCrate(data) {
    console.log('data', data);
    try {
        const result = await axiosInstance.delete(`${url}/item/crate`, { data });

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function insertBOLReportData(data) {
    try {
        const result = await axiosInstance.post(`${url}/report/billoflading`, data);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function getDailyCreatedAndShippedTotalWeight(data) {
    try {
        let dateParam = 'date=' + data.join('&date=');
        const result = await axiosInstance.get(`${url}/item/shippedtotalweight?` + dateParam);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function findsalesOrder(order_no) {
    return await axiosInstance
        .post(`${url}/nav/findsalesOrder`, { order_no: order_no })
        .catch((error) => {
            console.log(`findsalesOrder Client: ${error}`);
            return error.response.data;
        });
}

export async function findsalesOrderLine(order_no) {
    return await axiosInstance
        .post(`${url}/nav/findsalesOrderLine`, { order_no: order_no })
        .catch((error) => {
            console.log(`findsalesOrderLine Client: ${error}`);
            return error.response.data;
        });
}
