import { useEffect, useRef } from 'react';

function BarcodeScanner(props) {
  const keyStack = useRef('');

  useEffect(() => {
    const eventListener = (event) => {
      const { key } = event;
      if (key === 'Enter') {
        props.onScan(keyStack.current);
        keyStack.current = '';
      } else if (key === 'Shift') {
      } else {
        keyStack.current += key;
      }
    };
    window.addEventListener('keydown', eventListener);
    return () => {
      window.removeEventListener('keydown', eventListener);
    };
  }, []);

  return <></>;
}

export default BarcodeScanner;
