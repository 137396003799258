import React, { useEffect, useRef } from 'react';
import { Modal, Form, Input, Button, Descriptions } from 'antd';

const required = [{ required: true, message: 'This field is required!' }];

function DeleteCrateDialog({ visible, data, onClose, onComplete }) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (form) {
            form.setFieldsValue(data);
        }
    }, [data, form]);

    const onFinish = () => {
        console.log('clicked');
        onComplete(data);
    };

    const footer = (
        <>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" danger onClick={() => onFinish()}>
                Delete
            </Button>
        </>
    );

    return (
        <Modal
            title="Delete Crate"
            className="delete-crate-component"
            visible={visible}
            onCancel={onClose}
            footer={footer}
            getContainer={false}
        >
            {/* <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item label="Storage Location" name="location" rules={required}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item label="Quantity (pc)" name="total_quantity" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item>
                <Form.Item label="Gross Weight (lb)" name="total_gross_weight" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item>
            </Form> */}
            <Descriptions title="Crate Info">
                <Descriptions.Item label="Crate ID">{data?.id}</Descriptions.Item>
                <Descriptions.Item label="Customer">{data?.customer_code}</Descriptions.Item>
                <Descriptions.Item label="Date">{data?.created_date}</Descriptions.Item>
                <Descriptions.Item label="Part #">{data?.part_no}</Descriptions.Item>
                <Descriptions.Item label="MO #">{data?.order_no}</Descriptions.Item>
                <Descriptions.Item label="Customer PO#"> {data?.customer_po_no}</Descriptions.Item>
                <Descriptions.Item label="Location">{data?.location}</Descriptions.Item>
                <Descriptions.Item label="Quantity">{data?.total_quantity}</Descriptions.Item>
                <Descriptions.Item label="Gross Wt.">{data?.total_gross_weight}</Descriptions.Item>
            </Descriptions>
        </Modal>
    );
}

export default DeleteCrateDialog;
