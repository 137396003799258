import React from 'react';
import { Modal, Button, Descriptions } from 'antd';

function RequestConfirmDialog({ visible, data, onCancel, onOk, okLoading }) {
    const footer = (
        <>
            <Button onClick={onCancel} danger>
                Cancel
            </Button>
            <Button
                loading={okLoading}
                type="primary"
                onClick={() => {
                    onOk();
                }}
            >
                Confirm
            </Button>
        </>
    );
    const confirmRequiredItems = data?.confirmRequiredItems;
    return (
        <Modal
            title="Request confirm"
            className="request-confirm-dialog"
            visible={visible}
            onCancel={onCancel}
            footer={footer}
            getContainer={false}
            width="fit-content"
        >
            {confirmRequiredItems?.map((item, index) => {
                const { data, soLine, reason, requestCode } = item;
                return (
                    <div key={`${requestCode}-[${index}]`}>
                        <Descriptions title={`Request ${index + 1}`} bordered>
                            <Descriptions.Item label="Request detail" span={3}>
                                {reason}
                            </Descriptions.Item>
                            <Descriptions.Item label="Crate ID">{data.crate_id}</Descriptions.Item>
                            <Descriptions.Item label="Part #" span={2}>
                                {data.part_no}
                            </Descriptions.Item>
                            <Descriptions.Item label="MO #">{data.order_no}</Descriptions.Item>
                            <Descriptions.Item label="SO #">{soLine.Document_No}</Descriptions.Item>
                            <Descriptions.Item label="SO Line #">
                                {soLine.Line_No}
                            </Descriptions.Item>
                            <Descriptions.Item label="SO Line Quantity">
                                {soLine.Quantity}
                            </Descriptions.Item>
                            <Descriptions.Item label="Shipped Quantity">
                                {soLine.Quantity_Shipped}
                            </Descriptions.Item>
                            <Descriptions.Item label="Requested new quantity to ship">
                                {data.quantity}
                            </Descriptions.Item>
                        </Descriptions>
                    </div>
                );
            })}
        </Modal>
    );
}

export default RequestConfirmDialog;
