import { useEffect, useState, useRef } from 'react';
import { Col, Row, Spin } from 'antd';
import HavenReportLogo from '../../assets/icons/HSP_Icon.svg';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { connect } from 'react-redux';
import {
    getDropdown,
    getSalesOrders,
    getSalesOrderLine,
    findsalesOrder,
    findsalesOrderLine,
} from '../../pages/inventory/inventory.services';
import {
    getNextPackingListReportNo,
    insertPackingListReportData,
} from './packinglist.report.service';
import './reports.styles.css';
import AddAddressDialog from '../dialog/edit-address/add-address-dialog.component';
import EditAddressDialog from '../dialog/edit-address/edit-address-dialog.component';
import { InputNumber } from 'primereact/inputnumber';

const NO_MO_JOB_NUM = 'N/A';

const dataHeaderInitialState = {
    reportNo: [],
    generatedDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
    shipToAddress: [],
    billToAddress: [],
    shipmentNo: [],
    customerCode: [],
    shipmentDate: [],
    phone: [],
    shipVia: [],
    poNumber: [],
    orderNumber: [],
    orderDate: [],
    terms: [],
};

const PackingList = (props) => {
    const { dataset, currentStep, setCurrentStep, user, type, setLineTableVisivle, onFinishPrint } =
        props;
    const [customizedDataSet, setCustomizedDataSet] = useState({});
    const [finalDataSet, setFinalDataSet] = useState({});
    const [selectedBillTo, setSelectedBillTo] = useState(null);
    const [selectedShipTo, setSelectedShipTo] = useState(null);
    const [parsedBillToAddress, setParsedBillToAddress] = useState(null);
    const [parsedShipToAddress, setParsedShipToAddress] = useState(null);
    const [editShipToAddrVisible, setEditShipToAddrVisible] = useState(false);
    const [editBillToAddrVisible, setEditBillToAddrVisible] = useState(false);
    const [addAddressVisible, setAddAddressVisible] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);
    const [orderNoArray, setOrderNoArray] = useState([]);
    const [dataHeader, setDataHeader] = useState(dataHeaderInitialState);
    const [dataHeaderLoading, setDataHeaderLoading] = useState(false);
    const [salesOrdersLoading, setSalesOrdersLoading] = useState(false);

    const printRef = useRef(null);
    const handlePrintPackingList = useReactToPrint({
        content: () => printRef.current,
    });

    // --- Populate data from SO Line

    const populateSOLineData = async (SONo) => {
        const SOLineData = await getSalesOrderLine(SONo);

        let tempSOLineData = SOLineData;
        tempSOLineData.sort((a, b) => a.Line_No - b.Line_No);
        let tempData = { ...customizedDataSet };
        console.log('customizedDataSet', customizedDataSet);
        console.log('beforetempData', tempData, tempData['CO00018734']);
        let objectMap = Object.create(null);
        let objectArray = tempData[SONo];

        console.log({ SOLineData, tempData, SONo, objectArray });

        // To combine crate item quantity for same MO or same SO + SO Line
        objectArray.forEach((row) => {
            let order_no = row.order_no;

            if (order_no && objectMap[order_no]) {
                // Same MO found
                objectMap[order_no] = {
                    ...objectMap[order_no],
                    quantity: objectMap[order_no].quantity + row.quantity,
                };
            } else if (order_no && !objectMap[order_no]) {
                // Same MO not found
                objectMap[order_no] = { ...row };
            } else if (objectMap[NO_MO_JOB_NUM]) {
                // SO Shipping, "N/A" field found
                const sameSOLineIdx = objectMap[NO_MO_JOB_NUM].findIndex(
                    (obj) =>
                        obj.sales_order_no === row.sales_order_no &&
                        obj.sales_order_line === row.sales_order_line
                );
                if (sameSOLineIdx < 0) {
                    // Same SO + SOLine not Found
                    objectMap[NO_MO_JOB_NUM] = [...objectMap[NO_MO_JOB_NUM], row]; //
                } else {
                    // Same SO + SOLine Found
                    objectMap[NO_MO_JOB_NUM][sameSOLineIdx] = {
                        ...objectMap[NO_MO_JOB_NUM][sameSOLineIdx],
                        quantity: objectMap[NO_MO_JOB_NUM][sameSOLineIdx].quantity + row.quantity,
                    };
                }
            } else {
                // SO Shipping - "N/A" field not found
                objectMap[NO_MO_JOB_NUM] = [row];
            }
        });

        console.log({ objectMap });
        let qtySumList = [];
        for (const key of Object.keys(objectMap)) {
            if (key === NO_MO_JOB_NUM) {
                qtySumList = [...qtySumList, ...objectMap[key]];
            } else {
                qtySumList.push(objectMap[key]);
            }
        }
        tempData[SONo] = qtySumList;

        console.log('tempData', tempData);
        for (let i = 0; i < tempData[SONo].length; i++) {
            let currentLine = tempData[SONo][i];
            console.log('currentLine', currentLine);

            const tempSO =
                currentLine.sales_order_no ?? (await findsalesOrder(currentLine.order_no))?.data;
            let lineNo =
                currentLine.sales_order_line ??
                (await findsalesOrderLine(currentLine.order_no))?.data;
            let targetSOLineData = tempSOLineData.find(
                (soLine) => parseInt(soLine.Line_No) === parseInt(lineNo)
            );

            if (!targetSOLineData) {
                // --- Current Line No in SQL cannot be found in NAV, so retrieve Line No again ---
                lineNo =
                    currentLine.sales_order_line ??
                    (await findsalesOrderLine(currentLine.order_no))?.data; //
                targetSOLineData = tempSOLineData.find(
                    (soLine) => parseInt(soLine.Line_No) === parseInt(lineNo)
                );
            }

            console.log({ tempSOLineData, tempSO, lineNo });

            // eslint-disable-next-line react-hooks/exhaustive-deps, eqeqeq
            if (tempSOLineData[0] && tempSOLineData[0].Document_No == tempSO) {
                const targetSOLineData = tempSOLineData.find((soLine) => soLine.Line_No === lineNo);
                console.log('TEST123', targetSOLineData);
                let tempObject = {
                    open: targetSOLineData ? targetSOLineData.Quantity : 0,
                    shipped: currentLine.quantity,
                    backOrder: targetSOLineData
                        ? targetSOLineData.Quantity - targetSOLineData.Quantity_Shipped > 0
                            ? targetSOLineData.Quantity - targetSOLineData.Quantity_Shipped
                            : 0
                        : 0,
                    fixedBackOrder: targetSOLineData
                        ? targetSOLineData.Quantity - targetSOLineData.Quantity_Shipped > 0
                            ? targetSOLineData.Quantity - targetSOLineData.Quantity_Shipped
                            : 0
                        : 0,
                    unit: targetSOLineData ? targetSOLineData.Unit_of_Measure : '',
                };
                console.log('TEST456', tempObject);
                tempData[SONo][i] = {
                    ...tempData[SONo][i],
                    ...tempObject,
                };
                console.log('TEST789', tempData[SONo][i]);
            }
        }
        return tempData[SONo];
    };

    const onFinishEditAddress = async (value) => {
        if (editShipToAddrVisible) {
            setEditShipToAddrVisible(false);
        }
        if (editBillToAddrVisible) {
            setEditBillToAddrVisible(false);
        }
        fetchDropdown('bol_customer_addr');
        // setSelectedFreightBillTo(null);
    };

    const onFinishAddAddress = async (value) => {
        setAddAddressVisible(false);
        fetchDropdown('bol_customer_addr');
    };

    const phaseAddress = (data) => {
        if (data && data.includes('{')) {
            let tempData = JSON.parse(data);
            if (tempData instanceof Object) {
                return `${tempData?.Name}${tempData?.Address1 && '\n' + tempData.Address1}${
                    tempData?.Address2 && '\n' + tempData.Address2
                }${tempData?.Address3 && '\n' + tempData.Address3}`;
            }
        }
        return data;
    };

    // --- Fetch functions ---
    async function fetchSalesOrder(so_no, No, i) {
        const salesOrderData = await getSalesOrders(so_no);
        if (salesOrderData && salesOrderData[0]) {
            const obj = salesOrderData[0];
            console.log('obj');
            console.log(obj);
            const tempShipToAddress =
                obj.Ship_to_Name +
                `\r\n` +
                obj.Ship_to_Address +
                (obj.Ship_to_Address_2 ? `\r\n${obj.Ship_to_Address_2}` : '') +
                `\r\n${obj.Ship_to_City}${obj.Ship_to_County ? `, ${obj.Ship_to_County}` : ''}. ${
                    obj.Ship_to_Post_Code
                }`;
            const tempBillToAddress =
                obj.Bill_to_Name +
                `\r\n` +
                obj.Bill_to_Address +
                (obj.Bill_to_Address_2 ? `\r\n${obj.Bill_to_Address_2}` : '') +
                `\r\n${obj.Bill_to_City}${obj.Bill_to_County ? `, ${obj.Bill_to_County}` : ''}. ${
                    obj.Bill_to_Post_Code
                }`;

            setDataHeader(
                (prevState) => {
                    return {
                        ...prevState,
                        reportNo: [
                            ...prevState.reportNo,
                            { orderNo: obj.No, reportNo: No + i + 1 },
                        ],
                        shipToAddress: [
                            ...prevState.shipToAddress,
                            { orderNo: obj.No, shipToAddress: tempShipToAddress },
                        ],
                        billToAddress: [
                            ...prevState.billToAddress,
                            { orderNo: obj.No, billToAddress: tempBillToAddress },
                        ],
                        shipmentDate: [
                            ...prevState.shipmentDate,
                            {
                                orderNo: obj.No,
                                shipmentDate: moment(new Date()).format('YYYY-MM-DD'),
                            },
                        ],
                        orderNumber: [
                            ...prevState.orderNumber,
                            { orderNo: obj.No, orderNumber: obj.No },
                        ],
                        orderDate: [
                            ...prevState.orderDate,
                            { orderNo: obj.No, orderDate: obj.Order_Date },
                        ],
                        customerCode: [
                            ...prevState.customerCode,
                            { orderNo: obj.No, customerCode: obj.Sell_to_Customer_No },
                        ],
                        phone: [
                            ...prevState.phone,
                            { orderNo: obj.No, phone: obj.Sell_to_Phone_No },
                        ],
                        poNumber: [
                            ...prevState.poNumber,
                            { orderNo: obj.No, poNumber: obj.External_Document_No },
                        ],
                        terms: [
                            ...prevState.terms,
                            { orderNo: obj.No, terms: obj.Payment_Terms_Code },
                        ],
                    };
                },
                () => console.log(dataHeader)
            );

            const result = await populateSOLineData(so_no); //
            console.log('SOLine Result', result);
            return result;
        }
    }

    async function fetchDropdown(name) {
        const dropdownData = await getDropdown(name);
        if (name === 'bol_carrier') {
            //setCarrierOptions(dropdownData);
        } else if (name === 'bol_customer_addr') {
            setAddressOptions(dropdownData);

            if (parsedBillToAddress) {
                const updatedObj = dropdownData.find(
                    (data) => data.label === parsedBillToAddress.Name
                );
                if (updatedObj) {
                    setSelectedBillTo(updatedObj.value);
                    setParsedBillToAddress(JSON.parse(updatedObj.value));
                }
            }
            if (parsedShipToAddress) {
                const updatedObj = dropdownData.find(
                    (data) => data.label === parsedShipToAddress.Name
                );
                if (updatedObj) {
                    setSelectedShipTo(updatedObj.value);
                    setParsedShipToAddress(JSON.parse(updatedObj.value));
                }
            }
        }
    }

    const handleBack = () => {
        if (type === 'add') {
            setCurrentStep(currentStep - 1);
        } else {
            setLineTableVisivle(false);
        }
    };

    const filterDateSetBySO = async () => {
        let tempObject = {};
        let currentMO = '';
        for (let i = 0; i < dataset.length; i++) {
            for (let j = 0; j < dataset[i].items.length; j++) {
                currentMO = dataset[i].items[j];
                let orderNo;
                if (currentMO.order_no === null) {
                    orderNo = currentMO.sales_order_no;
                } else {
                    orderNo = (await findsalesOrder(currentMO.order_no))?.data;
                }

                if (tempObject.hasOwnProperty(orderNo)) {
                    tempObject[orderNo].push(currentMO);
                } else {
                    tempObject[orderNo] = [currentMO];
                }
            }
        }

        let clone = { ...tempObject };
        setCustomizedDataSet(clone);
    };

    const handleCanceledUnitChange = (line, orderNo, index, value) => {
        const newList = { ...finalDataSet };
        const targetObj = newList[orderNo][index];
        //--- update canceled unit quantity value ---
        targetObj.canceled_unit_qty = value;
        if (line.fixedBackOrder > 0) {
            //--- calculate and update back order value
            const newBackOrd = line.fixedBackOrder - value;
            targetObj.backOrder = newBackOrd > 0 ? newBackOrd : 0;
        }
        setFinalDataSet(newList, () => {
            console.log('FinalDataSet', finalDataSet);
        });
    };

    useEffect(() => {
        fetchDropdown('bol_customer_addr');
        setDataHeader(dataHeaderInitialState);
        setDataHeaderLoading(true);
        if (type === 'add') {
            fetchDataAdd();
        } else {
            fetchDataEdit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset]);

    const fetchDataAdd = () => {
        filterDateSetBySO();
    };

    const fetchDataEdit = () => {
        let tempDataSet = dataset[0];
        setOrderNoArray([tempDataSet.header.order_number]);
        for (let i = 0; i < tempDataSet?.line.length; i++) {
            console.log(tempDataSet.line[i]);
            let tempObject = {
                part_no: tempDataSet.line[i].item,
                unit: tempDataSet.line[i].canceled_unit,
                fixedBackOrder:
                    tempDataSet.line[i].open -
                        tempDataSet.line[i].shipped -
                        tempDataSet.line[i].canceled_unit_qty <
                    0
                        ? 0
                        : tempDataSet.line[i].open -
                          tempDataSet.line[i].shipped -
                          tempDataSet.line[i].canceled_unit_qty,
                backOrder:
                    tempDataSet.line[i].open -
                        tempDataSet.line[i].shipped -
                        tempDataSet.line[i].canceled_unit_qty <
                    0
                        ? 0
                        : tempDataSet.line[i].open -
                          tempDataSet.line[i].shipped -
                          tempDataSet.line[i].canceled_unit_qty,
                order_no: tempDataSet.line[i].job_number,
                isErrorOnPostingToNav: tempDataSet.line[i].is_error_on_posting_to_nav,
            };
            tempDataSet.line[i] = { ...tempDataSet.line[i], ...tempObject };
        }
        setDataHeader((prevState) => {
            return {
                ...prevState,
                reportNo: [
                    ...prevState.reportNo,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        reportNo: tempDataSet.header?.report_no,
                    },
                ],
                shipToAddress: [
                    ...prevState.shipToAddress,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        shipToAddress: tempDataSet.header?.ship_to_addr,
                    },
                ],
                billToAddress: [
                    ...prevState.billToAddress,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        billToAddress: tempDataSet.header?.bill_to_addr,
                    },
                ],
                shipmentDate: [
                    ...prevState.shipmentDate,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        shipmentDate: moment(tempDataSet.header?.shipment_date).format(
                            'YYYY-MM-DD'
                        ),
                    },
                ],
                orderNumber: [
                    ...prevState.orderNumber,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        orderNumber: tempDataSet.header?.order_number,
                    },
                ],
                orderDate: [
                    ...prevState.orderDate,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        orderDate: moment(tempDataSet.header?.order_date).format('YYYY-MM-DD'),
                    },
                ],
                customerCode: [
                    ...prevState.customerCode,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        customerCode: tempDataSet.header?.customer_code,
                    },
                ],
                phone: [
                    ...prevState.phone,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        phone: tempDataSet.header?.phone,
                    },
                ],
                poNumber: [
                    ...prevState.poNumber,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        poNumber: tempDataSet.header?.po_number,
                    },
                ],
                terms: [
                    ...prevState.terms,
                    {
                        orderNo: tempDataSet.header?.order_number,
                        terms: tempDataSet.header?.terms,
                    },
                ],
            };
        });
        setFinalDataSet({
            [tempDataSet.header.order_number]: tempDataSet.line,
        });
    };

    useEffect(() => {
        async function fetchSalesOrders() {
            try {
                setSalesOrdersLoading(true);
                // await delay(10000); //--don't know why we need this
                const currentMaxReportNo = (await getNextPackingListReportNo()).ident_current;
                let tempObject = {};

                console.log({ customizedDataSet });
                const values = await Promise.all(
                    Object.keys(customizedDataSet).map((key, index) =>
                        fetchSalesOrder(key, currentMaxReportNo, index)
                    )
                );

                console.log('494', { values });
                for (const row of values) {
                    let salesorderNo = '';
                    if (row[0].sales_order_no !== null) {
                        console.log('NOT NULL');
                        salesorderNo = row[0].sales_order_no;
                    } else {
                        salesorderNo = (await findsalesOrder(row[0].order_no)).data;
                        console.log(salesorderNo);
                    }
                    console.log(salesorderNo);
                    tempObject[salesorderNo] = [...row];
                }
                console.log('327', tempObject);
                let result;
                if (values.length === 0) {
                    result = [];
                } else {
                    result = tempObject;
                }

                if (result.length !== 0) {
                    setFinalDataSet(result);
                    setOrderNoArray(Object.keys(result));
                }
            } catch (error) {
                console.log('ERROR - fetchSalesOrders', error);
            } finally {
                setSalesOrdersLoading(false);
            }
        }

        fetchSalesOrders();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customizedDataSet]);

    useEffect(() => {
        if (dataHeaderLoading) {
            setDataHeaderLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataHeader]);

    async function handlePrint() {
        await postReportData();
        handlePrintPackingList();
        if (type === 'add') {
            onFinishPrint();
        } else {
            setLineTableVisivle(false);
        }
    }

    async function postReportData() {
        let postResult = '';
        console.log('postReportData');
        console.log(finalDataSet);
        for (let key in finalDataSet) {
            postResult = await insertPackingListReportData({
                header: {
                    report_no: dataHeader.reportNo.find((item) => item.orderNo === key).reportNo,
                    bill_to_addr: parsedBillToAddress
                        ? JSON.stringify(parsedBillToAddress)
                        : dataHeader.billToAddress.find((item) => item.orderNo === key)
                              ?.billToAddress,
                    ship_to_addr: parsedShipToAddress
                        ? JSON.stringify(parsedShipToAddress)
                        : dataHeader.shipToAddress.find((item) => item.orderNo === key)
                              ?.shipToAddress,
                    shipment_no:
                        dataHeader.shipmentNo.find((item) => item.orderNo === key)?.shipmentNo ||
                        null,
                    customer_code:
                        dataHeader.customerCode.find((item) => item.orderNo === key)
                            ?.customerCode || null,
                    shipment_date:
                        dataHeader.shipmentDate.find((item) => item.orderNo === key)
                            ?.shipmentDate || null,
                    phone: dataHeader.phone.find((item) => item.orderNo === key)?.phone || null,
                    ship_via:
                        dataHeader.shipVia.find((item) => item.orderNo === key)?.shipVia || null,
                    po_number:
                        dataHeader.poNumber.find((item) => item.orderNo === key)?.poNumber || null,
                    order_number:
                        dataHeader.orderNumber.find((item) => item.orderNo === key)?.orderNumber ||
                        null,
                    order_date:
                        dataHeader.orderDate.find((item) => item.orderNo === key)?.orderDate ||
                        null,
                    terms: dataHeader.terms.find((item) => item.orderNo === key)?.terms || null,
                    created_by: user.fullName,
                    division: props.division,
                },
                lines: finalDataSet[key].map((data, index) => {
                    return {
                        report_no: dataHeader.reportNo.find((item) => item.orderNo === key)
                            .reportNo,
                        item: data.part_no,
                        open: data.open,
                        shipped: data.shipped,
                        canceled_unit: data.unit,
                        canceled_unit_qty: data.canceled_unit_qty || 0,
                        description: data.description,
                        job_number: data.order_no,
                        report_line_id: data.report_line_id,
                        is_error_on_posting_to_nav: data.isErrorOnPostingToNav,
                    };
                }),
            });
        }
        return postResult;
    }

    return (
        <>
            <EditAddressDialog
                header="Edit an Address"
                visible={editShipToAddrVisible || editBillToAddrVisible}
                onHide={() => {
                    setEditShipToAddrVisible(false);
                    setEditBillToAddrVisible(false);
                }}
                onFinish={onFinishEditAddress}
                values={editShipToAddrVisible ? parsedShipToAddress : parsedBillToAddress}
            />
            <AddAddressDialog
                header="Add an Address"
                visible={addAddressVisible}
                onHide={() => setAddAddressVisible(false)}
                onFinish={onFinishAddAddress}
            />

            <div className="printme" ref={printRef}>
                <style type="text/css" media="print">
                    {`
      .printme { border: none; }
      .pagebreak {
        page-break-before: auto;
      }
      .pagebreakafter{
        page-break-after: always
      }
    `}
                </style>
                <Spin
                    tip="Updating Business Central... (This may take 10-30 seconds)"
                    spinning={dataHeaderLoading || salesOrdersLoading}
                >
                    {/* HEADER */}
                    {/* {console.log(orderNoArray)}
          {console.log(finalDataSet)}
          {console.log(dataHeader)} */}
                    {/* {console.log(finalDataSet)} */}
                    {orderNoArray?.map((orderNo, index) => {
                        return (
                            <div className="packinglist-wrapper" key={`packinglist-page-${index}`}>
                                <Row style={{ marginBottom: '5rem' }}>
                                    <Col span={4} style={{ textAlign: 'end' }}>
                                        <img
                                            src={HavenReportLogo}
                                            alt="Haven Logo"
                                            style={{ width: '80%' }}
                                        />
                                    </Col>
                                    <Col span={20}>
                                        <h1 style={{ textAlign: 'center' }}>
                                            <b>Haven Steel Products Inc</b>
                                        </h1>
                                    </Col>
                                </Row>
                                {/* PACKING LIST */}
                                <Row>
                                    <Col span={24}>
                                        <h3 className="packing-list-title">Packing List</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={2}>
                                        <p style={{ fontWeight: 'bold' }}>
                                            Bill
                                            <br />
                                            To:
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Dropdown
                                            filter
                                            filterBy="label"
                                            options={addressOptions}
                                            value={selectedBillTo}
                                            style={{ minWidth: '180px' }}
                                            onChange={(e) => {
                                                setSelectedBillTo(e.value);
                                                setParsedBillToAddress(JSON.parse(e.value));
                                            }}
                                            className="print-display-none highlight-input"
                                        />
                                        <Button
                                            onClick={() => setAddAddressVisible(true)}
                                            style={{ marginLeft: '5px' }}
                                            className="print-display-none"
                                        >
                                            Add
                                        </Button>
                                        {selectedBillTo && (
                                            <>
                                                <Button
                                                    onClick={() => setEditBillToAddrVisible(true)}
                                                    style={{ marginLeft: '5px' }}
                                                    className="print-display-none"
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => setSelectedBillTo(null)}
                                                    style={{ marginLeft: '5px' }}
                                                    className="print-display-none p-button-danger"
                                                >
                                                    Reset
                                                </Button>
                                            </>
                                        )}
                                        <p style={{ whiteSpace: 'pre-line' }}>
                                            {selectedBillTo ? (
                                                <>
                                                    {parsedBillToAddress?.Name}
                                                    {parsedBillToAddress?.Address1 &&
                                                        '\n' + parsedBillToAddress.Address1}
                                                    {parsedBillToAddress?.Address2 &&
                                                        '\n' + parsedBillToAddress.Address2}
                                                    {parsedBillToAddress?.Address3 &&
                                                        '\n' + parsedBillToAddress.Address3}
                                                    {parsedBillToAddress?.Address4 &&
                                                        '\n' + parsedBillToAddress.Address4}
                                                </>
                                            ) : dataHeader.billToAddress.find(
                                                  (item) => item.orderNo === orderNo
                                              ) ? (
                                                phaseAddress(
                                                    dataHeader.billToAddress.find(
                                                        (item) => item.orderNo === orderNo
                                                    ).billToAddress
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                    </Col>
                                    <Col span={4}></Col>
                                    <Col span={2}>
                                        <p style={{ fontWeight: 'bold' }}>
                                            Ship
                                            <br />
                                            To:
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <Row>
                                            <Dropdown
                                                filter
                                                filterBy="label"
                                                options={addressOptions}
                                                value={selectedShipTo}
                                                style={{ minWidth: '180px' }}
                                                onChange={(e) => {
                                                    setSelectedShipTo(e.value);
                                                    setParsedShipToAddress(JSON.parse(e.value));
                                                }}
                                                className="print-display-none highlight-input"
                                            />
                                            <Button
                                                onClick={() => setAddAddressVisible(true)}
                                                style={{ marginLeft: '5px' }}
                                                className="print-display-none"
                                            >
                                                Add
                                            </Button>
                                            {selectedShipTo && (
                                                <>
                                                    <Button
                                                        onClick={() =>
                                                            setEditShipToAddrVisible(true)
                                                        }
                                                        style={{ marginLeft: '5px' }}
                                                        className="print-display-none"
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => setSelectedShipTo(null)}
                                                        style={{ marginLeft: '5px' }}
                                                        className="print-display-none p-button-danger"
                                                    >
                                                        Reset
                                                    </Button>
                                                </>
                                            )}
                                        </Row>
                                        <p style={{ whiteSpace: 'pre-line' }}>
                                            {selectedShipTo ? (
                                                <>
                                                    {parsedShipToAddress?.Name}
                                                    {parsedShipToAddress?.Address1 &&
                                                        '\n' + parsedShipToAddress.Address1}
                                                    {parsedShipToAddress?.Address2 &&
                                                        '\n' + parsedShipToAddress.Address2}
                                                    {parsedShipToAddress?.Address3 &&
                                                        '\n' + parsedShipToAddress.Address3}
                                                    {parsedShipToAddress?.Address4 &&
                                                        '\n' + parsedShipToAddress.Address4}
                                                </>
                                            ) : dataHeader.shipToAddress.find(
                                                  (item) => item.orderNo === orderNo
                                              ) ? (
                                                phaseAddress(
                                                    dataHeader.shipToAddress.find(
                                                        (item) => item.orderNo === orderNo
                                                    ).shipToAddress
                                                )
                                            ) : (
                                                ''
                                            )}
                                        </p>
                                    </Col>
                                </Row>

                                {/* Packing List Info*/}
                                <div className="packing-list-info">
                                    <Row style={{ marginBottom: '16px' }}>
                                        <Col span={24}>
                                            <Row>
                                                <Col span={12}></Col>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Customer Code:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.customerCode.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.customerCode.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).customerCode
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Report No:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.reportNo.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.reportNo.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).reportNo
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Shipment Date:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.shipmentDate.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.shipmentDate.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).shipmentDate
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Phone:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.phone.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.phone.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).phone
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Ship Via:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.shipVia.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.shipVia.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).shipVia
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            PO Number:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.poNumber.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.poNumber.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).poNumber
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Order Number:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.orderNumber.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.orderNumber.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).orderNumber
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Order Date:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.orderDate.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.orderDate.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).orderDate
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                                <Col span={12}>
                                                    <Row>
                                                        {' '}
                                                        <Col
                                                            style={{ fontWeight: 'bold' }}
                                                            span={12}
                                                        >
                                                            Terms:{' '}
                                                        </Col>
                                                        <p>
                                                            {dataHeader.terms.find(
                                                                (item) => item.orderNo === orderNo
                                                            )
                                                                ? dataHeader.terms.find(
                                                                      (item) =>
                                                                          item.orderNo === orderNo
                                                                  ).terms
                                                                : ''}
                                                        </p>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </div>

                                {/*Quantity Table*/}

                                <div className="packing-list-table-container">
                                    <Row>
                                        <table className="packing-list-table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th
                                                        className="table-quantity-header"
                                                        colSpan="3"
                                                    >
                                                        Quantity
                                                    </th>
                                                </tr>
                                                <tr>
                                                    <th className="table-header">Item</th>
                                                    <th className="table-header">Open</th>
                                                    <th className="table-header">Shipped</th>
                                                    <th className="table-header">Back Ord</th>
                                                    <th className="table-header">Canceled Unit</th>
                                                    <th className="table-header">Description</th>
                                                    <th className="table-header">Job Number</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {finalDataSet[orderNo]
                                                    ? finalDataSet[orderNo].map((line, idx) => {
                                                          console.log('Line', line);
                                                          return (
                                                              <tr
                                                                  key={`crateitemid-${line.crate_item_id}`}
                                                              >
                                                                  <td className="table-data-cell">
                                                                      {line.part_no}
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      {line.open}
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      {line.shipped}
                                                                      {line.isErrorOnPostingToNav ===
                                                                          true && '*'}
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      {line.backOrder}
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      <Row>
                                                                          <InputNumber
                                                                              inputId="minmax"
                                                                              className="print-display-none highlight-input"
                                                                              value={
                                                                                  line.canceled_unit_qty
                                                                              }
                                                                              onValueChange={(e) =>
                                                                                  handleCanceledUnitChange(
                                                                                      line,
                                                                                      orderNo,
                                                                                      idx,
                                                                                      e.value
                                                                                  )
                                                                              }
                                                                              mode="decimal"
                                                                              min={0}
                                                                              // max={100}
                                                                              showButtons
                                                                              autocomplete="off"
                                                                          />
                                                                          <span className="print-display-only">
                                                                              {
                                                                                  line.canceled_unit_qty
                                                                              }
                                                                          </span>
                                                                          <span
                                                                              style={{
                                                                                  marginLeft:
                                                                                      '0.2rem',
                                                                              }}
                                                                          >
                                                                              {line.unit || ''}
                                                                          </span>
                                                                      </Row>
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      {line.description}
                                                                  </td>
                                                                  <td className="table-data-cell">
                                                                      {line.order_no}
                                                                  </td>
                                                              </tr>
                                                          );
                                                      })
                                                    : ''}
                                            </tbody>
                                        </table>
                                    </Row>
                                </div>

                                <div className="pagebreak" />
                                <div className="break-inside-avoid" style={{ marginTop: 'auto' }}>
                                    <Row>
                                        <Col span={12}>
                                            <Row>
                                                _____________________________________________________________
                                            </Row>
                                            <Row>Packing Clerk's Signature</Row>
                                            <Row>Haven Steel Products Inc</Row>
                                        </Col>
                                    </Row>
                                    <div className="packing-list-generated">
                                        <Row>Report Generated: {dataHeader.generatedDate}</Row>
                                    </div>
                                </div>
                                <div className="pagebreakafter" />
                            </div>
                        );
                    })}
                </Spin>
            </div>
            <Row className="hidden-print" style={{ justifyContent: 'end', margin: '0.4rem 0' }}>
                <div style={{ marginLeft: 'auto' }}>
                    {type !== 'add' ? (
                        <Button
                            className="p-button-lg p-button-outlined"
                            label="Back"
                            onClick={() => handleBack()}
                            style={{
                                borderColor: 'gray',
                                color: 'gray',
                                marginRight: '0.6rem',
                            }}
                        />
                    ) : (
                        ''
                    )}
                    {/* <Button
            className="p-button-lg p-button-outlined"
            label="Back"
            onClick={() => handleBack()}
            style={{
              borderColor: 'gray',
              color: 'gray',
              marginRight: '0.6rem'
            }}
          /> */}
                    <Button
                        className="p-button-lg"
                        label={type === 'add' ? 'Print and Finish' : 'Print and Save'}
                        style={{
                            backgroundColor: 'var(--haven-red)',
                            borderColor: 'var(--haven-red)',
                        }}
                        onClick={handlePrint}
                        disabled={dataHeaderLoading || salesOrdersLoading}
                    />
                </div>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default connect(mapStateToProps)(PackingList);
