import { FilterMatchMode, FilterOperator } from 'primereact/api';

export const TABLE_PROPERTIES = [
    { dataIndex: 'mo', name: 'Production No.', minWidth: "14rem" },
    { dataIndex: 'line_no', name: 'Line No.', minWidth: "10rem" },
    { dataIndex: 'customer_code', name: 'Customer', minWidth: "11rem" },
    { dataIndex: 'quantity_available', name: 'Qty', minWidth: "8rem" },
    { dataIndex: 'so', name: 'Sales No.', minWidth: "11rem" },
    { dataIndex: 'part_no', name: 'Part No.', minWidth: "10rem" },
];

export const TABLE_FILTERS = {
    'global': { value: null, matchMode: FilterMatchMode.CONTAINS },
    'mo': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
    'line_no': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'customer_code': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
    'quantity_available': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]},
    'so': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
    'part_no': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }] },
};