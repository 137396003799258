import React from 'react';
import moment from 'moment';
import { Button, Modal, Form, Input } from 'antd';
import { Dialog } from 'primereact/dialog';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getLabelService, printLabelService } from './print-crate-label.services';
import './print-crate-label.styles.css';
import { Dropdown } from 'primereact/dropdown';
import {
    insertFreightBillTo,
    updateFreightBillTo,
} from '../../../../../components/report/billoflading.report.service';
import { getDropdown } from '../../../../inventory/inventory.services';
class PrintCrateLabel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zpl: undefined,
            changeAdressModalVisible: false,
            addressOptions: [],
            selectedAddress: null,
            parsedSelectedAddress: null,
            addAddressVisible: false,
            editAddressVisible: false,
        };
    }

    componentDidMount() {
        const { data } = this.props;
        let tempData = {
            crate_id: { id: data.crate_id, company: 'HS' },
            company: data.products[0].customer,
            street: data.products[0].address,
            city_state_zip: data.products[0].post_code,
            dof: moment().format('MMMM_D,_YYYY'),
            part_number: data.products[0].part_no,
            total_quantity: data.total_quantity,
            gross_weight: data.total_gross_weight,
            external_document_no: data.products[0].external_document_no,
            division: this.props.division,
        };
        getLabelService(tempData).then((zpl) => {
            this.setState({ zpl });
        });
        getDropdown('bol_customer_addr').then((addressOptions) => {
            this.setState({ addressOptions });
        });
    }

    getLabelUrl = (zpl) => {
        const [DPMM, WIDTH, HEIGHT, INDEX] = ['8dpmm', 4, 6, 0];
        const convertedZPL = encodeURIComponent(zpl);
        return `https://api.labelary.com/v1/printers/${DPMM}/labels/${WIDTH}x${HEIGHT}/${INDEX}/${convertedZPL}`;
    };

    reloadLabel = () => {
        const { selectedAddress, parsedSelectedAddress } = this.state;
        const { data } = this.props;
        if (selectedAddress) {
            let tempData = {
                crate_id: { id: data.crate_id, company: 'HS' },
                company: parsedSelectedAddress.Name,
                street: parsedSelectedAddress.Address1,
                city_state_zip: parsedSelectedAddress.Address2,
                ship_to_address3: parsedSelectedAddress.Address3,
                ship_to_address4: parsedSelectedAddress.Address4,
                dof: moment().format('MMMM_D,_YYYY'),
                part_number: data.products[0].part_no,
                total_quantity: data.total_quantity,
                gross_weight: data.total_gross_weight,
                external_document_no: data.products[0].external_document_no,
                division: this.props.division,
            };
            getLabelService(tempData).then((zpl) => {
                this.setState({ zpl, changeAdressModalVisible: false });
            });
        } else {
            this.setState({ changeAdressModalVisible: false });
        }
    };

    renderLabel = () => {
        const { zpl } = this.state;
        return zpl ? (
            <>
                <Button
                    danger
                    className="change-address-button"
                    onClick={() => this.setState({ changeAdressModalVisible: true })}
                >
                    Change Address
                </Button>
                <div className="label-image">
                    <img
                        src={this.getLabelUrl(zpl)}
                        alt="Error: You should see the Shipping Label here"
                    />
                </div>
            </>
        ) : null;
    };

    onFinishEditAddress = async (value) => {
        const { parsedSelectedAddress } = this.state;
        this.setState({ editAddressVisible: false });
        await updateFreightBillTo({
            name: 'bol_customer_addr',
            label: value.Name,
            value: JSON.stringify(value),
        });
        getDropdown('bol_customer_addr').then((addressOptions) => {
            this.setState({ addressOptions });
            if (parsedSelectedAddress) {
                const updatedObj = addressOptions.find(
                    (data) => data.label === parsedSelectedAddress.Name
                );
                if (updatedObj) {
                    this.setState({
                        selectedAddress: updatedObj.value,
                        parsedSelectedAddress: JSON.parse(updatedObj.value),
                    });
                }
            }
        });
    };

    onFinishAddAddress = async (value) => {
        this.setState({ addAddressVisible: false });
        await insertFreightBillTo({
            name: 'bol_customer_addr',
            label: value.Name,
            value: value,
        });
        getDropdown('bol_customer_addr').then((addressOptions) => {
            this.setState({ addressOptions });
        });
    };

    onResetAddress = () => {
        this.setState({ selectedAddress: null });
        const { data } = this.props;
        let tempData = {
            crate_id: { id: data.crate_id, company: 'HS' },
            company: data.products[0].customer,
            street: data.products[0].address,
            city_state_zip: data.products[0].post_code,
            dof: moment().format('MMMM_D,_YYYY'),
            part_number: data.products[0].part_no,
            total_quantity: data.total_quantity,
            gross_weight: data.total_gross_weight,
            external_document_no: data.products[0].external_document_no,
            division: this.props.division,
        };
        getLabelService(tempData).then((zpl) => {
            this.setState({ zpl });
        });
    };

    renerAdressSelections = () => {
        const { addressOptions, selectedAddress, parsedSelectedAddress } = this.state;
        const { data } = this.props;
        return addressOptions.length > 0 ? (
            <div className="render-Address-Container">
                <Dropdown
                    filter
                    filterBy="label"
                    options={addressOptions}
                    value={selectedAddress}
                    style={{ minWidth: '180px' }}
                    onChange={(e) => {
                        this.setState({
                            selectedAddress: e.value,
                            parsedSelectedAddress: JSON.parse(e.value),
                        });
                    }}
                />
                <Button
                    onClick={() => this.setState({ addAddressVisible: true })}
                    style={{ marginLeft: '5px' }}
                    className="print-display-none"
                >
                    Add
                </Button>
                {selectedAddress && (
                    <>
                        <Button
                            onClick={() => this.setState({ editAddressVisible: true })}
                            style={{ marginLeft: '5px' }}
                            className="print-display-none"
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={() => this.onResetAddress()}
                            style={{ marginLeft: '5px' }}
                            className="print-display-none p-button-danger"
                        >
                            Reset
                        </Button>
                    </>
                )}
                <p style={{ whiteSpace: 'pre-line' }}>
                    {selectedAddress ? (
                        <>
                            {parsedSelectedAddress?.Name}
                            {parsedSelectedAddress?.Address1 &&
                                '\n' + parsedSelectedAddress.Address1}
                            {parsedSelectedAddress?.Address2 &&
                                '\n' + parsedSelectedAddress.Address2}
                            {parsedSelectedAddress?.Address3 &&
                                '\n' + parsedSelectedAddress.Address3}
                            {parsedSelectedAddress?.Address4 &&
                                '\n' + parsedSelectedAddress.Address4}
                        </>
                    ) : (
                        <>
                            {data.products[0].customer && '\n' + data.products[0].customer}
                            {data.products[0].address && '\n' + data.products[0].address}
                            {data.products[0].post_code && '\n' + data.products[0].post_code}
                        </>
                    )}
                </p>
            </div>
        ) : null;
    };

    render() {
        return (
            <>
                <Dialog
                    header="Edit an Address"
                    visible={this.state.editAddressVisible}
                    style={{ width: '50vw' }}
                    onHide={() => this.setState({ editAddressVisible: false })}
                >
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={(value) => this.onFinishEditAddress(value)}
                        autoComplete="off"
                        initialValues={{
                            Name: this.state.parsedSelectedAddress?.Name || '',
                            Address1: this.state.parsedSelectedAddress?.Address1 || '',
                            Address2: this.state.parsedSelectedAddress?.Address2 || '',
                            Address3: this.state.parsedSelectedAddress?.Address3 || '',
                        }}
                    >
                        <Form.Item label="Name" name="Name" disabled>
                            <Input disabled />
                        </Form.Item>

                        <Form.Item label="Address1" name="Address1">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Address2" name="Address2">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Address3" name="Address3">
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Dialog>
                <Dialog
                    header="Add an Address"
                    visible={this.state.addAddressVisible}
                    style={{ width: '50vw' }}
                    onHide={() => this.setState({ addAddressVisible: false })}
                >
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        onFinish={(value) => this.onFinishAddAddress(value)}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Name"
                            name="Name"
                            rules={[{ required: true, message: 'Please input the name!' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label="Address1" name="Address1">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Address2" name="Address2">
                            <Input />
                        </Form.Item>
                        <Form.Item label="Address3" name="Address3">
                            <Input />
                        </Form.Item>
                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Dialog>
                <Modal
                    className="change-label-address-modal"
                    visible={this.state.changeAdressModalVisible}
                    onOk={() => this.reloadLabel()}
                    width={'50%'}
                    onCancel={() => this.setState({ changeAdressModalVisible: false })}
                >
                    {this.renerAdressSelections()}
                </Modal>
                <div className="print-label-component">
                    <h2>Print QR Code</h2>
                    <div className="label-container">
                        {this.renderLabel()}
                        <div className="button-container">
                            <Button onClick={this.handleBack} type="primary" className="invisible">
                                Back
                            </Button>
                            <Button onClick={this.handlePrint} type="primary">
                                Print
                            </Button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleBack = () => {
        this.props.onBack();
    };

    handlePrint = () => {
        const { zpl } = this.state;
        printLabelService(zpl)
            .then(() => this.props.onPrint('success'))
            .catch(() => this.props.onPrint('error'));
        // this.props.onPrint('success');
    };
}
const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(PrintCrateLabel));
