import { Layout } from 'antd';
import './footer.styles.css';
import packageJson from '../../../package.json';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <div id="footer-component">
            <Footer>
                <div className="footer-grid">
                    <span className="footer-copyright-text">
                        Copyright &copy; 2022
                        <span className="footer-company"> Alpaca Systems </span>| All rights
                        reserved.
                    </span>
                    <span className="footer-version-text">V{packageJson.version}</span>
                </div>
            </Footer>
        </div>
    );
};

export default FooterComponent;
