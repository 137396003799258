import axiosInstance from '../../../../../general/axiosInstance';
import { DateTime } from 'luxon';
import { CONST_API_URL } from '../../../../../appconfig';
const url = CONST_API_URL;

export function getManufacturingOrderService() {
    return axiosInstance
        .get(`${url}/nav/getSalesOrders`)
        .then((res) => res)
        .catch((error) => console.log(`getManufacturingOrderService Client - ${error}`));
}

export function insertCrateService(data, user, division) {
    let crate = convertCrate(data, user, division);
    let crateItems;
    let crateId;

    return axiosInstance
        .post(`${url}/item/insertCrate`, crate)
        .then((res) => {
            crateId = res.data[0].id;
            crateItems = convertCrateItems(data, crateId, user, division);
            return axiosInstance.post(`${url}/item/insertCrateItem`, crateItems);
        })
        .then(() => {
            return crateId;
        })
        .catch((error) => console.log(`insertCrateService Client - ${error}`));
}

function convertCrate(data, user, division) {
    console.log(data);
    const {
        total_quantity,
        total_gross_weight,
        location,
        pallet_type,
        box_type,
        shrink_wrap,
        lid,
        crateSourceType,
    } = data;
    return {
        total_quantity,
        total_gross_weight,
        total_contained_weight: data.total_cont_weight,
        location,
        pallet_type,
        box_type,
        shrink_wrap,
        lid,
        customer_code: data.products[0].customer_code,
        created_by: user.id,
        status: 'in stock',
        division: division,
        source_type: crateSourceType,
    };
}

function convertCrateItems(data, crateId, user, division) {
    const { products } = data;
    return products.map((product) => {
        const crateItemType = checkCrateItemType(product);
        return {
            crate_id: crateId,
            description: product.description,
            order_no: product.mo,
            part_no: product.part_no,
            quantity: product.quantity,
            external_document_no: product.external_document_no,
            gross_weight: 0,
            contained_weight: 0,
            due_date: DateTime.now().toFormat('yyyy-MM-dd'),
            created_by: user.id,
            division: division,
            sales_order_no: product.so,
            sales_order_line: crateItemType === 'SALES' ? product.line_no : null,
            document_no: product.document_no,
        };
    });
}

function checkCrateItemType(item) {
    // ["MANUFACTURING", "SALES"]
    if (item.mo) {
        return 'MANUFACTURING';
    } else if (item.so && item.line_no) {
        return 'SALES';
    }
    return null;
}
