import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { getLabelService } from './qrcodedialog.services';
import { Button, Spin } from 'antd';
import { getSalesOrders, scanSalesOrderArchive } from '../../pages/inventory/inventory.services';
import moment from 'moment';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { printLabelService } from '../../pages/create-crate-table/subcomponents/create-crate-modal/print-crate-label/print-crate-label.services';
import { notEmptyObject, notEmptyProperty } from '../../functions/manageArrays';
import { getDropdown, findsalesOrder } from '../../pages/inventory/inventory.services';
import EditAddressDialog from './edit-address/edit-address-dialog.component';
import AddAddressDialog from './edit-address/add-address-dialog.component';

const errorMessages = {
    error1: 'Invalid data detected, please refresh your browser and try again.', //wrong data, supposed to not display usually
    // error2: 'Selected crate is missing MO#, please check and re-create a new crate.', //when crate doesn't have MO#
    error3: 'Failed to find the corresponding sales order number.', //when it is not able to find SO number following the rule (before dash in MO# or Source No. when Source type='Sales Header')
    error4: 'Failed to find the corresponding sales order data.', //when SO number is found but target SO does not exist in neither Sales Orders nor Archive
    error5: 'Target Sales Order is detected in Sales Order Archive, this usually means the sales order is either fully shipped or deleted.', //when SO only exists in Archive
};
const QRCodeDialog = ({ data, setVisible, visible, division }) => {
    const [ZPL, setZPL] = useState(null);
    const [changeAdressModalVisible, setChangeAdressModalVisible] = useState(false);
    const [addressOptions, setAddressOptions] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [parsedSelectedAddress, setParsedSelectedAddress] = useState(null);
    const [addAddressVisible, setAddAddressVisible] = useState(false);
    const [editAddressVisible, setEditAddressVisible] = useState(false);
    const [tempcompany, setCompany] = useState(null);
    const [tempstreet, setStreet] = useState(null);
    const [tempzip, setZip] = useState(null);
    const [tempExternalDocumentNo, setTempExternalDocumentNo] = useState(null);
    const [labelLoading, setLabelLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);

    const resetState = () => {
        setZPL(null);
        setChangeAdressModalVisible(false);
        setAddressOptions([]);
        setSelectedAddress(null);
        setParsedSelectedAddress(null);
        setAddAddressVisible(false);
        setEditAddressVisible(false);
        setCompany(null);
        setStreet(null);
        setZip(null);
        setTempExternalDocumentNo(null);
        setLabelLoading(false);
        setErrorMsg(null);
    };

    useEffect(() => {
        const fetchData = async (data) => {
            try {
                setLabelLoading(true);
                console.log('QR Data', data);
                let foundSONo;
                if (data.order_no) {
                    const res1 = await findsalesOrder(data.order_no);
                    foundSONo = res1?.data;
                } else {
                    foundSONo = data.items?.[0]?.sales_order_no;
                }
                console.log('Found SONo', foundSONo);
                if (!foundSONo) {
                    setErrorMsg(errorMessages.error3);
                    return;
                }

                const salesOrderData = await getSalesOrders(foundSONo);
                console.log('sodata', salesOrderData);
                if (Array.isArray(salesOrderData) && salesOrderData[0]) {
                    const obj = salesOrderData[0];
                    const STREET =
                        obj.Ship_to_Address +
                        (obj.Ship_to_Address_2 ? `\r\n${obj.Ship_to_Address_2}` : '') +
                        `\r\n${obj.Ship_to_City}${
                            obj.Ship_to_County ? `, ${obj.Ship_to_County}` : ''
                        }.`;
                    const CITY_STATE_ZIP = `${obj.Ship_to_Post_Code}`;
                    const COMPANY = obj.Ship_to_Name;
                    const EXTERNAL_DOCUMENT_NO = obj.External_Document_No;
                    let tempData = {
                        crate_id: { id: data.id, company: 'HS' },
                        company: COMPANY,
                        street: STREET,
                        city_state_zip: CITY_STATE_ZIP,
                        dof: moment(data.created_date).format('MMMM_D,_YYYY'),
                        part_number: data.part_no,
                        total_quantity: data.total_quantity,
                        gross_weight: data.total_gross_weight,
                        external_document_no: EXTERNAL_DOCUMENT_NO,
                        division: division,
                    };
                    setCompany(COMPANY);
                    setTempExternalDocumentNo(EXTERNAL_DOCUMENT_NO);
                    setStreet(STREET);
                    setZip(CITY_STATE_ZIP);
                    setSelectedAddress(null);
                    setParsedSelectedAddress(null);
                    getLabelService(tempData).then((zpl) => {
                        setZPL(zpl);
                    });
                    getDropdown('bol_customer_addr').then((addressOptions) => {
                        setAddressOptions(addressOptions);
                    });
                } else {
                    // the target sales order not found, scan archive
                    const archiveData = await scanSalesOrderArchive(foundSONo);
                    if (Array.isArray(archiveData) && archiveData.length > 0) {
                        setErrorMsg(
                            errorMessages.error5 + `\r\n(Detected Sales Order No: ${foundSONo})`
                        );
                    } else {
                        setErrorMsg(
                            errorMessages.error4 + `\r\n(Detected Sales Order No: ${foundSONo})`
                        );
                    }
                }
            } finally {
                setLabelLoading(false);
            }
        };

        if (visible) {
            const isNotEmptyObject = notEmptyObject(data);

            if (isNotEmptyObject) {
                fetchData(data);
            } else if (!isNotEmptyObject) {
                setErrorMsg(errorMessages['error1']);
            }
        } else {
            resetState();
        }
    }, [data, visible]);

    // useEffect(() => {
    //     if (visible !== true) {
    //         resetState();
    //     }
    // }, [visible]);

    // const getLabelUrl = (zpl) => {
    //   const [ DPMM, WIDTH, HEIGHT, INDEX ] = ['8dpmm', 4, 6, 0];
    //   return `https://api.labelary.com/v1/printers/${DPMM}/labels/${WIDTH}x${HEIGHT}/${INDEX}/${zpl}`;
    // }

    const getLabelUrl = (zpl) => {
        const [DPMM, WIDTH, HEIGHT, INDEX] = ['8dpmm', 4, 6, 0];
        const convertedZPL = encodeURIComponent(zpl);
        return `https://api.labelary.com/v1/printers/${DPMM}/labels/${WIDTH}x${HEIGHT}/${INDEX}/${convertedZPL}`;
    };

    const reloadLabel = () => {
        if (selectedAddress) {
            let tempData = {
                crate_id: { id: data.id, company: 'HS' },
                company: parsedSelectedAddress.Name,
                street: parsedSelectedAddress.Address1,
                city_state_zip: parsedSelectedAddress.Address2,
                ship_to_address3: parsedSelectedAddress.Address3,
                ship_to_address4: parsedSelectedAddress.Address4,
                dof: moment(data.created_date).format('MMMM_D,_YYYY'),
                part_number: data.part_no,
                total_quantity: data.total_quantity,
                gross_weight: data.total_gross_weight,
                external_document_no: tempExternalDocumentNo,
                division: division,
            };
            getLabelService(tempData).then((zpl) => {
                setZPL(zpl);
            });
            setChangeAdressModalVisible(false);
        } else {
            setChangeAdressModalVisible(false);
        }
    };

    const renderLabel = () => {
        // console.log(ZPL);
        if (labelLoading) {
            return <Spin size="large" />;
        } else if (errorMsg) {
            return renderErrorMsg;
        }
        return ZPL ? (
            <>
                <Button
                    danger
                    className="change-address-button"
                    onClick={() => setChangeAdressModalVisible(true)}
                >
                    Change Address
                </Button>
                <div className="label-image">
                    <img
                        src={getLabelUrl(ZPL)}
                        alt="Error: You should see the Shipping Label here"
                    />
                </div>
            </>
        ) : null;
    };

    const onFinishEditAddress = async (value) => {
        setEditAddressVisible(false);
        // await updateFreightBillTo({
        //   name: 'bol_customer_addr',
        //   label: value.Name,
        //   value: JSON.stringify(value)
        // });
        getDropdown('bol_customer_addr').then((addressOptions) => {
            setAddressOptions(addressOptions);
            if (parsedSelectedAddress) {
                const updatedObj = addressOptions.find(
                    (data) => data.label === parsedSelectedAddress.Name
                );
                if (updatedObj) {
                    setSelectedAddress(updatedObj.value);
                    setParsedSelectedAddress(JSON.parse(updatedObj.value));
                }
            }
        });
    };

    const onFinishAddAddress = async (value) => {
        setAddAddressVisible(false);
        // await insertFreightBillTo({
        //   name: 'bol_customer_addr',
        //   label: value.Name,
        //   value: value
        // });
        getDropdown('bol_customer_addr').then((addressOptions) => {
            setAddressOptions(addressOptions);
        });
    };

    const onResetAddress = () => {
        setSelectedAddress(null);
        let tempData = {
            crate_id: { id: data.id, company: 'HS' },
            company: tempcompany,
            street: tempstreet,
            city_state_zip: tempzip,
            dof: moment(data.created_date).format('MMMM_D,_YYYY'),
            part_number: data.part_no,
            total_quantity: data.total_quantity,
            gross_weight: data.total_gross_weight,
            external_document_no: tempExternalDocumentNo,
            division: division,
        };
        getLabelService(tempData).then((zpl) => {
            setZPL(zpl);
        });
    };

    const renerAdressSelections = () => {
        return addressOptions.length > 0 ? (
            <div className="render-Address-Container">
                <Dropdown
                    filter
                    filterBy="label"
                    options={addressOptions}
                    value={selectedAddress}
                    style={{ minWidth: '180px' }}
                    onChange={(e) => {
                        setSelectedAddress(e.value);
                        setParsedSelectedAddress(JSON.parse(e.value));
                    }}
                />
                <Button
                    onClick={() => setAddAddressVisible(true)}
                    style={{ marginLeft: '5px' }}
                    className="print-display-none"
                >
                    Add
                </Button>
                {selectedAddress && (
                    <>
                        <Button
                            onClick={() => setEditAddressVisible(true)}
                            style={{ marginLeft: '5px' }}
                            className="print-display-none"
                        >
                            Edit
                        </Button>
                        <Button
                            onClick={onResetAddress}
                            style={{ marginLeft: '5px' }}
                            className="print-display-none p-button-danger"
                        >
                            Reset
                        </Button>
                    </>
                )}
                <p style={{ whiteSpace: 'pre-line' }}>
                    {selectedAddress ? (
                        <>
                            {parsedSelectedAddress?.Name}
                            {parsedSelectedAddress?.Address1 &&
                                '\n' + parsedSelectedAddress.Address1}
                            {parsedSelectedAddress?.Address2 &&
                                '\n' + parsedSelectedAddress.Address2}
                            {parsedSelectedAddress?.Address3 &&
                                '\n' + parsedSelectedAddress.Address3}
                            {parsedSelectedAddress?.Address4 &&
                                '\n' + parsedSelectedAddress.Address4}
                        </>
                    ) : (
                        <>
                            {tempcompany && '\n' + tempcompany}
                            {tempzip && '\n' + tempzip}
                            {tempzip && '\n' + tempzip}
                        </>
                    )}
                </p>
            </div>
        ) : null;
    };

    const handlePrint = () => {
        setVisible(false);
        printLabelService(ZPL).then(() => {
            console.log('Print Successful!');
        });
    };

    const footer = (
        <div>
            <Button type="danger" onClick={() => setChangeAdressModalVisible(false)}>
                Close
            </Button>
            <Button type="primary" onClick={() => reloadLabel()}>
                Save
            </Button>
        </div>
    );

    const renderErrorMsg = (
        <div className="error-message__wrapper">
            <div>
                <h3>Error occurred</h3>
                <p>Detail: {errorMsg}</p>
            </div>
        </div>
    );

    return (
        <>
            <EditAddressDialog
                header="Edit an Address"
                visible={editAddressVisible}
                onHide={() => setEditAddressVisible(false)}
                onFinish={onFinishEditAddress}
                values={parsedSelectedAddress}
            />
            <AddAddressDialog
                header="Add an Address"
                visible={addAddressVisible}
                onHide={() => setAddAddressVisible(false)}
                onFinish={onFinishAddAddress}
            />
            <Dialog
                className="change-label-address-modal"
                visible={changeAdressModalVisible}
                style={{ width: '50%' }}
                footer={footer}
                onHide={() => setChangeAdressModalVisible(false)}
            >
                {renerAdressSelections()}
            </Dialog>
            <Dialog
                className="qrcode-dialog"
                visible={visible}
                onHide={() => setVisible(false)}
                dismissableMask
                header={'QR Label'}
                draggable={false}
                style={{ height: '90vh', width: '90vw' }}
            >
                <div className="print-label-component">
                    <div className="label-container">
                        {renderLabel()}
                        <div className="button-container">
                            <Button style={{ visibility: 'hidden' }}>Back</Button>
                            <Button onClick={handlePrint} type="primary">
                                Print
                            </Button>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>
    );
};
const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(QRCodeDialog));
