import { useEffect, useState, useRef } from 'react';
import { Col, Descriptions, Divider, Row, Spin } from 'antd';
import './reports.styles.css';
import HavenReportLogo from '../../assets/icons/HSP_Icon.svg';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import moment from 'moment';
import _ from 'lodash';
import { useReactToPrint } from 'react-to-print';
import { connect } from 'react-redux';
import {
    getDropdown,
    getNextBOLReportNo,
    getSalesOrders,
    insertBOLReportData,
    findsalesOrder,
} from '../../pages/inventory/inventory.services';
import {
    checkCrateValidity,
    updateCrateStatusService,
    updateSalesOrderLineConfirmed,
    updateSalesOrderLineService,
} from './billoflading.report.service';
import AddAddressDialog from '../dialog/edit-address/add-address-dialog.component';
import EditAddressDialog from '../dialog/edit-address/edit-address-dialog.component';
import AddCarrierDialog from '../dialog/edit-carrier/add-carrier-dialog.component';
import EditCarrierDialog from '../dialog/edit-carrier/edit-carrier-dialog.component';
import ErrorInfoDialog from '../dialog/error-info/error-info-dialog.component';
import RequestConfirmDialog from '../request-confirm/request-confirm-dialog.component';
// const CONST_RECORDS_NUM = 10;

const BillOfLadingReport = (props) => {
    const { currentStep, setCurrentStep, user, type, setLineTableVisivle } = props;
    let { dataset } = props;
    const [dataHeader, setDataHeader] = useState({
        total_gross_weight: 0,
        total_num_of_pkgs: 0,
        report_no: null,
        date: moment(new Date()).format('M/D/YYYY'),
        customer_order_no: '',
        order_no: [],
        shipToAddress: '',
        shipToCustomerName: '',
        shipToCustomerCode: '',
        billToAddress: '',
    });
    const [dataLoading, setDataLoading] = useState(false);
    const [addAddressVisible, setAddAddressVisible] = useState(false);
    const [addCarrierVisible, setAddCarrierVisible] = useState(false);
    const [editCarrierVisible, setEditCarrierVisible] = useState(false);
    const [errorModalVisible, setErrorModalVisible] = useState(false);
    const [editShipToAddrVisible, setEditShipToAddrVisible] = useState(false);
    const [editBillToAddrVisible, setEditBillToAddrVisible] = useState(false);
    const [carrierOptions, setCarrierOptions] = useState([]);
    const [addressOptions, setAddressOptions] = useState([]);
    const [errorInfo, setErrorInfo] = useState([]);
    const freightChargeOptions = [
        { label: 'Collect', value: 'Collect' },
        { label: '3rd party', value: '3rd party' },
        { label: 'Pre pay and add', value: 'Pre pay and add' },
        { label: 'Other', value: 'Other' },
    ];
    const printRef = useRef(null);
    const handlePrintBOL = useReactToPrint({ content: () => printRef.current });
    // --- Form data ---
    const [selectedFreightCharge, setSelectedFreightCharge] = useState(
        freightChargeOptions[0].value
    );
    const [selectedFreightBillTo, setSelectedFreightBillTo] = useState(null);
    const [parsedBillToAddress, setParsedBillToAddress] = useState(null);
    const [selectedFreightShipTo, setSelectedFreightShipTo] = useState(null);
    const [parsedShipToAddress, setParsedShipToAddress] = useState(null);
    const [freightChargeType, setFreightChargeType] = useState(null);
    const [selectedCarrier, setSelectedCarrier] = useState(null);
    const [classNumberList, setClassNumberList] = useState([]);
    const [NMFCNumberList, setNMFCNumberList] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);
    const [postDataLoading, setPostDataLoading] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(null);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);
    const [requestConfirmLoading, setRequestConfirmLoading] = useState(false);

    // --- Fetch functions ---
    async function fetchSalesOrders(soList) {
        let isMissingSalesOrder = false;
        let repSalesOrderData = null;

        // Loop all SO numbers to check if the SO number exists
        for (let i = 0; i < soList.length; i++) {
            const so_no = soList[i];
            const salesOrderData = await getSalesOrders(so_no);
            if (_.isEmpty(salesOrderData) || !!!salesOrderData[0]) {
                setErrorMessage(
                    <h2 className="text-danger">
                        {`Failed to create BOL - Sales Order '${so_no}' is not found. Please contact your System Administrator.`}
                    </h2>
                );
                isMissingSalesOrder = true;
                break;
            } else if (repSalesOrderData === null && salesOrderData && salesOrderData[0]) {
                // Keep first sales order data to update dataHeader
                repSalesOrderData = salesOrderData;
            }
        }

        if (!isMissingSalesOrder && repSalesOrderData) {
            const obj = repSalesOrderData[0];
            const shipToAddress =
                obj.Ship_to_Name +
                `\r\n` +
                obj.Ship_to_Address +
                (obj.Ship_to_Address_2 ? `\r\n${obj.Ship_to_Address_2}` : '') +
                `\r\n${obj.Ship_to_City}${obj.Ship_to_County ? `, ${obj.Ship_to_County}` : ''}. ${
                    obj.Ship_to_Post_Code
                }`;
            const billToAddress =
                obj.Bill_to_Name +
                `\r\n` +
                obj.Bill_to_Address +
                (obj.Bill_to_Address_2 ? `\r\n${obj.Bill_to_Address_2}` : '') +
                `\r\n${obj.Bill_to_City}${obj.Bill_to_County ? `, ${obj.Bill_to_County}` : ''}. ${
                    obj.Bill_to_Post_Code
                }`;

            setDataHeader((prevState) => {
                return {
                    ...prevState,
                    shipToCustomerName: obj.Ship_to_Name,
                    shipToCustomerCode: obj.Ship_to_Code || obj.Sell_to_Customer_No,
                    shipToAddress,
                    billToAddress: _.isEqual(shipToAddress, billToAddress) ? '' : billToAddress,
                    customer_order_no: obj.External_Document_No,
                };
            });
        }
    }

    async function fetchNextReportNo() {
        const currentMaxReportNo = (await getNextBOLReportNo()).ident_current;
        setDataHeader((prevState) => {
            return {
                ...prevState,
                report_no: Number(currentMaxReportNo) + 1,
            };
        });
    }

    async function fetchDropdown(name) {
        const dropdownData = await getDropdown(name);
        if (name === 'bol_carrier') {
            setCarrierOptions(dropdownData);
        } else if (name === 'bol_customer_addr') {
            setAddressOptions(dropdownData);

            if (parsedBillToAddress) {
                const updatedObj = dropdownData.find(
                    (data) => data.label === parsedBillToAddress.Name
                );
                if (updatedObj) {
                    setSelectedFreightBillTo(updatedObj.value);
                    setParsedBillToAddress(JSON.parse(updatedObj.value));
                }
            }
            if (parsedShipToAddress) {
                const updatedObj = dropdownData.find(
                    (data) => data.label === parsedShipToAddress.Name
                );
                if (updatedObj) {
                    setSelectedFreightShipTo(updatedObj.value);
                    setParsedShipToAddress(JSON.parse(updatedObj.value));
                }
            }
        }
    }

    const handleBack = () => {
        if (type === 'add') {
            setCurrentStep(currentStep - 1);
        } else {
            setLineTableVisivle(false);
        }
    };

    const checkDataValidity = async (crateData) => {
        const res = await checkCrateValidity(crateData);
        if (res.data?.isValid === false) {
            const errorDetails = res.data.errorItems?.map((el, index) => (
                <div>
                    <Divider>Error #{index + 1}</Divider>
                    <Descriptions>
                        <Descriptions.Item label="Crate ID">{el.data.crate_id}</Descriptions.Item>
                        <Descriptions.Item label="Crate Item ID">
                            {el.data.crate_item_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Part #">{el.data.part_no}</Descriptions.Item>
                        <Descriptions.Item label="SO #">{el.data.sales_order_no}</Descriptions.Item>
                        <Descriptions.Item label="SO Line #">
                            {el.data.sales_order_line}
                        </Descriptions.Item>
                        {el.data.order_no && (
                            <Descriptions.Item label="MO #">{el.data.order_no}</Descriptions.Item>
                        )}
                    </Descriptions>
                    <Descriptions>
                        <Descriptions.Item label="Error Message">
                            <span className="text-danger">{el.reason}</span>
                        </Descriptions.Item>
                    </Descriptions>
                </div>
            ));
            const errorElement = (
                <>
                    <h2 className="text-danger">Errors:</h2>
                    {errorDetails ||
                        'Unexpected error occurred in validity checking. Please contact your System Administrator.'}
                </>
            );
            setErrorMessage(errorElement);
        }

        return res.data?.isValid || false;
    };

    const fetchDataAdd = async () => {
        if (!(await checkDataValidity(dataset))) {
            console.log('INVALID');
            return;
        }
        fetchNextReportNo();

        //--- Populate Sales order data ---
        // const firstOrderNo = dataset[0]?.items[0]?.order_no;
        // if (firstOrderNo) {
        //     const target_so_no = firstOrderNo.split('-')[0];
        //     fetchSalesOrder(target_so_no);
        // }
        //need there too - FindSO
        //const soList = dataset.map((crate) => crate.items[0]?.order_no?.split('-')[0]);

        //const soList = dataset.map(async(crate) => crate.items[0]?(crate.items[0].sales_order_no!=null?crate.items[0].sales_order_no:await findsalesOrder(crate.items[0].order_no).data):'');
        //const soList = dataset.map(async(crate) =>crate.items[0]?.sales_order_no?crate.items[0].sales_order_no!=null?crate.items[0].sales_order_no:await findsalesOrder(crate.items[0].order_no).data);
        const soList = await Promise.all(
            dataset.map(async (crate) => {
                const sono = crate.items[0]?.sales_order_no ? crate.items[0].sales_order_no : null;
                const mono = crate.items[0]?.order_no ? crate.items[0].order_no : null;
                if (sono != null) {
                    return crate.items[0].sales_order_no;
                } else {
                    if (mono != null) {
                        const list = await findsalesOrder(crate.items[0].order_no);
                        const listValue = list?.data;
                        return listValue;
                    } else {
                        return null;
                    }
                }
            })
        );

        if (soList) {
            fetchSalesOrders(soList);
        }

        //--- Calculate Total values ---
        let total_gross_weight = 0;
        let total_num_of_pkgs = 0;
        let orderList = [];
        dataset.forEach((data) => {
            total_gross_weight += data.total_gross_weight;
            total_num_of_pkgs += data.items.length;

            //--- Make a list of order numbers ---
            data.items.forEach(async (item) => {
                //new
                let so_no = '';
                if (item.sales_order_no != null) {
                    so_no = item.sales_order_no;
                } else {
                    so_no = (await findsalesOrder(item.order_no))?.data;
                }
                //const so_no = item.order_no.split('-')[0];
                if (!orderList.includes(so_no)) {
                    orderList.push(so_no);
                }
            });
        });

        //--- Fill number of items into arrays ---
        const emptyArr = new Array(dataset.length).fill(null);
        setClassNumberList(emptyArr);
        setNMFCNumberList(emptyArr);

        setDataHeader((prevState) => ({
            ...prevState,
            total_gross_weight,
            total_num_of_pkgs,
            order_no: orderList,
        }));
    };

    // //new
    // const FindSO=(item)=>{
    //     try{
    //         var mo='';
    //         mo=item.item.order_no;
    //         var SONO='';

    //         if(MOHeader.value[0].Source_Type == "Sales Header"){
    //             SONO= MOHeader.value[0].Source_No;
    //         }else{
    //             if(item.order_no.indexOf('-')>-1){
    //                 SONO=item.order_no.split('-')[0];
    //             }else{
    //                 SONO=item.order_no;
    //             }
    //         }
    //         console.log("SONO",SONO);
    //         return SONO;
    //     }catch (error) {
    //         console.error(error);
    //         return null;
    //     }
    // }

    const fetchDataEdit = () => {
        //--- Calculate Total values ---
        let total_gross_weight = 0;
        let total_num_of_pkgs = 0;

        dataset[0].line.forEach((data) => {
            total_gross_weight += data.weight;

            total_num_of_pkgs += data.num_of_packages;
        });
        setDataHeader((prevState) => {
            return {
                ...prevState,
                date: moment(dataset[0].header.created_datetime).format('M/D/YYYY'),
                total_gross_weight,
                total_num_of_pkgs,
                customer_order_no: dataset[0].header.customer_order_no,
                order_no: dataset[0].header.out_order_no.split(','),
                report_no: dataset[0].header.report_no,
                shipToCustomerName: dataset[0].header.consignee_name,
                shipToCustomerCode: dataset[0].header.consignee_code,
                shipToAddress: dataset[0].header.consignee_addr,
                billToAddress:
                    dataset[0].header.bill_to_addr === 'null'
                        ? null
                        : dataset[0].header.bill_to_addr,
            };
        });
        setSelectedFreightBillTo(
            dataset[0].header.bill_to_addr === 'null' ? null : dataset[0].header.bill_to_addr
        );
        setSelectedFreightCharge(dataset[0].header.freight_charge_type);
        setFreightChargeType(dataset[0].header.is_prepaid ? 'Prepaid' : 'Collect');
        setSelectedCarrier(dataset[0].header.carrier_driver);
        setClassNumberList(
            dataset[0].line.map((data) => {
                let array = [];
                array.push(data.class);
                return array;
            })
        );
        setNMFCNumberList(
            dataset[0].line.map((data) => {
                let array = [];
                array.push(data.nmfc);
                return array;
            })
        );
    };

    useEffect(() => {
        //------------------------------
        setErrorMessage(null);
        setDataLoading(true);
        //--- Fetch Dropdown options ---
        fetchDropdown('bol_carrier');
        fetchDropdown('bol_customer_addr');
        if (type === 'add') {
            fetchDataAdd();
        } else {
            fetchDataEdit();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset]);

    useEffect(() => {
        if (
            dataLoading &&
            dataHeader.report_no &&
            dataHeader.shipToAddress &&
            dataHeader.order_no &&
            carrierOptions.length > 0
        ) {
            setDataLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataHeader, carrierOptions]);

    function handleInputNumberChange(type, idx, val) {
        switch (type) {
            case 'class':
                const newClassData = [...classNumberList];
                newClassData[idx] = val;
                setClassNumberList(newClassData);
                break;
            case 'NMFC':
                const newNMFCData = [...NMFCNumberList];
                newNMFCData[idx] = val;
                setNMFCNumberList(newNMFCData);
                break;
            default:
                console.log('handleInputNumberChange - Uncaught type name:', type);
        }
    }

    function addErrorFlagIntoDataset(errorList) {
        const _dataset = [...dataset];
        const errorCrateItemIdList = errorList.map((obj) => obj.data?.crate_item_id);

        for (const crate of _dataset) {
            for (const crateitem of crate.items) {
                if (errorCrateItemIdList.includes(crateitem.crate_item_id)) {
                    crateitem.isErrorOnPostingToNav = true;
                }
            }
        }

        dataset = _dataset;
    }

    async function handlePrint() {
        // handlePrintBOL();
        setPostDataLoading(true);

        if (type === 'add') {
            const result = await updateSalesOrder();
            // console.log('Result - handlePrint:', result);
            const errorList = result?.data?.errorItems || [];
            const confirmRequiredList = result?.data?.confirmRequiredItems || [];

            // if (result.data === 'good') {
            //     handlePrintBOL(); //
            //     await setCurrentStep(currentStep + 1);
            //     await postReportData();
            // } else if (errorList.length > 0) {
            //     setErrorInfo(errorList);
            //     //await setCurrentStep(currentStep - 1);
            //     setErrorModalVisible(true);
            // } else if (confirmRequiredList.length > 0) {
            //     setConfirmModalData(confirmRequiredList);
            //     setConfirmModalVisible(true);
            // } else {
            //     setErrorInfo(null);
            //     setErrorModalVisible(true);
            // }
            if (confirmRequiredList.length > 0) {
                // setConfirmModalData(confirmRequiredList);
                setConfirmModalData(result.data);
                setConfirmModalVisible(true);
            } else {
                handlePrintBOL();
                await postReportData();
                if (errorList.length > 0) {
                    setErrorInfo(errorList);
                    addErrorFlagIntoDataset(errorList);
                    setErrorModalVisible(true);
                } else {
                    setCurrentStep(currentStep + 1);
                }
            }
        } else {
            handlePrintBOL();
            await postReportData();
            setLineTableVisivle(false);
        }
        setPostDataLoading(false);
    }

    async function handleRequestConfirm(data) {
        try {
            setRequestConfirmLoading(true);
            const result = await updateSalesOrderLineConfirmed(data);
            const errorList = result?.data?.errorItems || [];
            // if (result.data === 'good') {
            //     handlePrintBOL();
            //     await setCurrentStep(currentStep + 1);
            //     await postReportData();
            // }
            handlePrintBOL();
            await postReportData();
            if (Array.isArray(errorList) && errorList.length > 0) {
                setErrorInfo(errorList);
                addErrorFlagIntoDataset(errorList);
                setErrorModalVisible(true);
            } else {
                setCurrentStep(currentStep + 1);
            }
        } finally {
            setRequestConfirmLoading(false);
            setConfirmModalVisible(false);
        }
    }

    async function updateSalesOrder() {
        const _dataset = dataset.map((data) => ({ ...data, username: user.fullName }));

        console.log('dataset for update', _dataset);
        return await updateSalesOrderLineService(_dataset);
    }

    async function postReportData() {
        const postResult = await insertBOLReportData({
            header: {
                report_no: dataHeader.report_no,
                date: dataHeader.date || null,
                consignee_code: dataHeader.shipToCustomerCode || null,
                consignee_addr:
                    (selectedFreightShipTo ? selectedFreightShipTo : dataHeader.shipToAddress) ||
                    null,
                consignee_name: dataHeader.shipToCustomerName || null,
                customer_order_no: dataHeader.customer_order_no || null,
                out_order_no: dataHeader.order_no.join(', ') || null,
                bill_to_addr:
                    dataHeader.billToAddress ||
                    (parsedBillToAddress ? JSON.stringify(parsedBillToAddress) : null) ||
                    null,
                freight_charge_type: selectedFreightCharge || null,
                is_prepaid: freightChargeType?.toUpperCase() === 'PREPAID',
                is_collect: freightChargeType?.toUpperCase() === 'COLLECT',
                carrier_driver: selectedCarrier || null,
                created_by: user.fullName,
                division: props.division,
            },
            lines:
                type === 'add'
                    ? dataset.map((data, idx) => {
                          return {
                              report_no: dataHeader.report_no,
                              num_of_packages: data.items.length,
                              unit_of_measure: 'CRATE',
                              hm: null,
                              description: data.items.find((item) => !!item.description)
                                  ?.description,
                              weight: data.total_gross_weight,
                              class: classNumberList[idx] || null,
                              nmfc: NMFCNumberList[idx] || null,
                              report_line_id: data.report_line_id,
                          };
                      })
                    : dataset[0].line.map((data, idx) => {
                          return {
                              report_no: dataHeader.report_no,
                              num_of_packages: data.num_of_packages,
                              unit_of_measure: 'CRATE',
                              hm: null,
                              description: data.description,
                              weight: data.weight,
                              class: classNumberList[idx] || null,
                              nmfc: NMFCNumberList[idx] || null,
                              report_line_id: data.report_line_id,
                          };
                      }),
        });
        return postResult;
    }

    const phaseAddress = (data) => {
        if (data && data.includes('{')) {
            let tempData = JSON.parse(data);
            if (tempData instanceof Object) {
                return `${tempData?.Name}${tempData?.Address1 && '\n' + tempData.Address1}${
                    tempData?.Address2 && '\n' + tempData.Address2
                }${tempData?.Address3 && '\n' + tempData.Address3}`;
            }
        }
        return data;
    };

    const onFinishAddAddress = async (value) => {
        setAddAddressVisible(false);
        // await insertFreightBillTo({
        //   name: "bol_customer_addr",
        //   label: value.Name,
        //   value: value,
        // });

        fetchDropdown('bol_customer_addr');
    };

    const onFinishEditAddress = async (value) => {
        if (editShipToAddrVisible) {
            setEditShipToAddrVisible(false);
            fetchDropdown('bol_customer_addr').then(() => {
                setParsedShipToAddress(value);
                setSelectedFreightShipTo(JSON.stringify(value));
            });
        }
        if (editBillToAddrVisible) {
            fetchDropdown('bol_customer_addr').then(() => {
                setParsedBillToAddress(value);
                setSelectedFreightBillTo(JSON.stringify(value));
            });
            setEditBillToAddrVisible(false);
        }
        // await updateFreightBillTo({
        //   name: 'bol_customer_addr',
        //   label: value.Name,
        //   value: JSON.stringify(value)
        // });

        // setSelectedFreightBillTo(null);
        console.log(value);
    };

    const onFinishAddCarrier = async (value) => {
        setAddCarrierVisible(false);
        fetchDropdown('bol_carrier').then(setSelectedCarrier(value.Name));
    };

    const onFinishEditCarrier = async (value) => {
        if (editCarrierVisible) {
            setEditCarrierVisible(false);
        }
        fetchDropdown('bol_carrier').then(setSelectedCarrier(value.Name));
    };
    return (
        <>
            <AddAddressDialog
                header="Add an Address"
                visible={addAddressVisible}
                onHide={() => setAddAddressVisible(false)}
                onFinish={onFinishAddAddress}
            />
            <EditAddressDialog
                header="Edit an Address"
                visible={editShipToAddrVisible || editBillToAddrVisible}
                onHide={() => {
                    setEditShipToAddrVisible(false);
                    setEditBillToAddrVisible(false);
                }}
                onFinish={onFinishEditAddress}
                values={editShipToAddrVisible ? parsedShipToAddress : parsedBillToAddress}
            />

            <AddCarrierDialog
                header="Add a Carrier"
                visible={addCarrierVisible}
                onHide={() => setAddCarrierVisible(false)}
                onFinish={onFinishAddCarrier}
            ></AddCarrierDialog>
            <EditCarrierDialog
                header="Edit a Carrier"
                visible={editCarrierVisible}
                onHide={() => setEditCarrierVisible(false)}
                onFinish={onFinishEditCarrier}
                values={selectedCarrier}
            ></EditCarrierDialog>
            <ErrorInfoDialog
                visible={errorModalVisible}
                onClose={async () => {
                    setErrorModalVisible(false);
                    // await setCurrentStep(currentStep - 1);
                    setCurrentStep(currentStep + 1);
                }}
                data={errorInfo}
            />
            <RequestConfirmDialog
                visible={confirmModalVisible}
                data={confirmModalData}
                onCancel={() => {
                    setConfirmModalVisible(false);
                    setCurrentStep(currentStep - 1);
                }}
                onOk={() => {
                    handleRequestConfirm(confirmModalData);
                }}
                okLoading={requestConfirmLoading}
            />

            <div className="printme" ref={printRef}>
                <style type="text/css" media="print">
                    {`
                    .printme { border: none; }
                    .pagebreak {
                        page-break-before: auto;
                    }
                    `}
                </style>

                {errorMessage ? (
                    <div className="bol-errormessage">{errorMessage}</div>
                ) : (
                    <Spin spinning={dataLoading} tip="Loading data and creating report...">
                        <div className="bol-wrapper">
                            {/* HEADER */}
                            <div className="bol-header">
                                <Row>
                                    <Col span={21}>
                                        <h6>
                                            <b>STRAIGHT BILL OF LADING</b> - SHORT FORM - Original -
                                            Not Negotiable
                                        </h6>
                                        <p>
                                            RECEIVED, subject to the classifications and tariffs in
                                            effect on the date of issue of this Original Bill of
                                            Lading.
                                        </p>
                                        <p style={{ textAlign: 'end', paddingRight: '4em' }}>
                                            <label style={{ marginRight: '4em' }}>
                                                Carrier SCAC:
                                            </label>
                                            <label style={{ marginRight: '4em' }}>Carrier #:</label>
                                        </p>
                                    </Col>
                                    <Col span={3}>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <p>
                                                <b>BOL#: </b>
                                                {dataHeader.report_no}
                                            </p>
                                            <p>
                                                <b>Date: </b>
                                                {dataHeader.date || ''}
                                            </p>
                                            <p>
                                                <b>Page: </b>1 of 1
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                <hr />

                                <Row style={{ marginBottom: '8px' }}>
                                    <p className="p-sm">
                                        the property described below, in apparent good order, except
                                        as noted (contents and condition of contents of packages
                                        unknown), marked, consigned, and destinec as indicated
                                        below, which said carrier (the word carrier being understood
                                        throughout this contract as meaning any person or
                                        corporation in possession of the property under the
                                        contract) agrees to carry to its usual place of delivery as
                                        said destination, if on its route, otherwise to deliver to
                                        another carrier on the route to said destination. It is
                                        mutually agreed, as to each carrier of all or any of said
                                        property over all of any portion of said route to
                                        destination, and as to each party at any time interested in
                                        all or any of said property, that every service to be
                                        performed hereunder shall be subject to all the terms and
                                        conditions of the Uniform Domestic Straight Bill of Lading
                                        set forth (1) in the Uniform Freight Classification in
                                        effect on the date hereof, if this is a rail, or a
                                        rail-water shipment, or (2) in the applicable motor carrier
                                        classification or tariff if this is a motor carrier
                                        shipment. Shipper hereby certifies that he is familiar with
                                        all the terms and conditions of said bill of lading,
                                        including those on the attachment thereof, set forth in the
                                        classification or tariff which governs the transportation of
                                        this shipment, and the said terms and conditions are hereby
                                        agreed to by the shipper and accepted for himself and his
                                        assigns.
                                    </p>
                                </Row>

                                {/* SHIPPER */}
                                <Row className="border ps-1">
                                    <p>
                                        <b>SHIPPER (FROM)</b>
                                    </p>
                                </Row>
                                <Row style={{ marginBottom: '8px' }}>
                                    <Col span={10}>
                                        <p>
                                            HAVEN STEEL PRODUCTS
                                            <br />
                                            {props.division == 'HAVEN'
                                                ? '13206 S.WILLISON RD.'
                                                : '25570 Hwy 22 N'}
                                            <br />
                                            {props.division == 'HAVEN'
                                                ? 'HAVEN, KS. 67543'
                                                : 'McKenzie, TN 38201'}
                                        </p>
                                    </Col>
                                    <Col span={10}>
                                        <b>PRO #:</b>
                                    </Col>
                                    <Col span={4} style={{ textAlign: 'end' }}>
                                        <img
                                            src={HavenReportLogo}
                                            alt="Haven Logo"
                                            style={{ width: '80%' }}
                                        />
                                    </Col>
                                </Row>

                                {/* CONSIGNEE */}
                                <Row className="border ps-1">
                                    <p>
                                        <b>CONSIGNEE (SHIP TO)</b>
                                    </p>
                                </Row>
                                <div style={{ marginBottom: '8px' }}>
                                    <Row>
                                        <Dropdown
                                            filter
                                            filterBy="label"
                                            options={addressOptions}
                                            value={selectedFreightShipTo}
                                            style={{ minWidth: '180px' }}
                                            onChange={(e) => {
                                                setSelectedFreightShipTo(e.value);
                                                setParsedShipToAddress(JSON.parse(e.value));
                                            }}
                                            className="print-display-none highlight-input"
                                        />
                                        <Button
                                            onClick={() => setAddAddressVisible(true)}
                                            style={{ marginLeft: '5px' }}
                                            className="print-display-none"
                                        >
                                            Add
                                        </Button>
                                        {selectedFreightShipTo && (
                                            <>
                                                <Button
                                                    onClick={() => setEditShipToAddrVisible(true)}
                                                    style={{ marginLeft: '5px' }}
                                                    className="print-display-none"
                                                >
                                                    Edit
                                                </Button>
                                                <Button
                                                    onClick={() => setSelectedFreightShipTo(null)}
                                                    style={{ marginLeft: '5px' }}
                                                    className="print-display-none p-button-danger"
                                                >
                                                    Reset
                                                </Button>
                                            </>
                                        )}
                                    </Row>
                                    <p style={{ whiteSpace: 'pre-line' }}>
                                        {selectedFreightShipTo ? (
                                            <>
                                                {parsedShipToAddress?.Name}
                                                {parsedShipToAddress?.Address1 &&
                                                    '\n' + parsedShipToAddress.Address1}
                                                {parsedShipToAddress?.Address2 &&
                                                    '\n' + parsedShipToAddress.Address2}
                                                {parsedShipToAddress?.Address3 &&
                                                    '\n' + parsedShipToAddress.Address3}
                                                {parsedShipToAddress?.Address4 &&
                                                    '\n' + parsedShipToAddress.Address4}
                                            </>
                                        ) : (
                                            phaseAddress(dataHeader.shipToAddress)
                                        )}
                                    </p>
                                </div>
                                <hr />

                                <Row style={{ marginBottom: '1em' }}>
                                    <Col span={10}>
                                        <Row>
                                            <Col>
                                                <label>CUST. ORDER#:</label>
                                            </Col>
                                            <Col style={{ flex: '1' }}>
                                                <b>{dataHeader.customer_order_no}</b>
                                            </Col>
                                        </Row>
                                        <label>ROUTE:</label>
                                        <br />
                                        <label>Special Instructions:</label>
                                    </Col>
                                    <Col span={10}>
                                        <Row>
                                            <Col>
                                                <label>OUR ORDER#:</label>
                                            </Col>
                                            <Col style={{ flex: '1' }}>
                                                <b>{dataHeader.order_no.join(', ')}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={4}>
                                        <p>
                                            <label>DEPT:</label>
                                        </p>
                                    </Col>
                                </Row>

                                <Row className="border">
                                    <Col span={13}>
                                        <div style={{ padding: '0 0.4em' }}>
                                            <Row style={{ borderBottom: '1px solid black' }}>
                                                <p className="ps-1">
                                                    <b>
                                                        SEND FREIGHT BILL TO: (if different than
                                                        CONSIGNEE above)
                                                    </b>
                                                </p>
                                            </Row>
                                            <Dropdown
                                                filter
                                                filterBy="label"
                                                options={addressOptions}
                                                value={selectedFreightBillTo}
                                                optionLabel="label"
                                                style={{ minWidth: '180px' }}
                                                onChange={(e) => {
                                                    setSelectedFreightBillTo(e.value);
                                                    setParsedBillToAddress(JSON.parse(e.value));
                                                }}
                                                className="print-display-none highlight-input"
                                            />
                                            <Button
                                                onClick={() => setAddAddressVisible(true)}
                                                style={{ marginLeft: '5px' }}
                                                className="print-display-none"
                                            >
                                                Add
                                            </Button>
                                            {selectedFreightBillTo && (
                                                <>
                                                    <Button
                                                        onClick={() =>
                                                            setEditBillToAddrVisible(true)
                                                        }
                                                        style={{ marginLeft: '5px' }}
                                                        className="print-display-none"
                                                    >
                                                        Edit
                                                    </Button>
                                                    <Button
                                                        onClick={() => {
                                                            setSelectedFreightBillTo(null);
                                                            setParsedBillToAddress(
                                                                JSON.parse(null)
                                                            );
                                                        }}
                                                        style={{ marginLeft: '5px' }}
                                                        className="print-display-none p-button-danger"
                                                    >
                                                        Reset
                                                    </Button>
                                                </>
                                            )}
                                            <p style={{ whiteSpace: 'pre-line' }}>
                                                {parsedBillToAddress ? (
                                                    <>
                                                        {parsedBillToAddress?.Name}
                                                        {parsedBillToAddress?.Address1 &&
                                                            '\n' + parsedBillToAddress.Address1}
                                                        {parsedBillToAddress?.Address2 &&
                                                            '\n' + parsedBillToAddress.Address2}
                                                        {parsedBillToAddress?.Address3 &&
                                                            '\n' + parsedBillToAddress.Address3}
                                                        {parsedBillToAddress?.Address4 &&
                                                            '\n' + parsedBillToAddress.Address4}
                                                    </>
                                                ) : (
                                                    phaseAddress(dataHeader.billToAddress)
                                                )}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col
                                        span={6}
                                        style={{
                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                        }}
                                    >
                                        <div style={{ padding: '0 0.4em' }}>
                                            <div style={{ borderBottom: '1px solid black' }}>
                                                <label>Freight charges are:</label>
                                                <Dropdown
                                                    options={freightChargeOptions}
                                                    value={selectedFreightCharge}
                                                    style={{ minWidth: '80%' }}
                                                    placeholder="Select charge type"
                                                    onChange={(e) =>
                                                        setSelectedFreightCharge(e.value)
                                                    }
                                                    className="print-display-none highlight-input"
                                                />
                                                <h4
                                                    style={{ fontWeight: '700' }}
                                                    className="print-display-only"
                                                >
                                                    {selectedFreightCharge}
                                                </h4>
                                            </div>
                                            <div style={{ marginBottom: '2px' }}>
                                                <Row style={{ alignItems: 'center' }}>
                                                    <Col>
                                                        <h4 style={{ fontWeight: '700' }}>
                                                            C.O.D.&nbsp;
                                                        </h4>
                                                    </Col>
                                                    <Col flex={'1'}>
                                                        <div className="underline" />
                                                    </Col>
                                                    <Col>
                                                        <p>AMOUNT</p>
                                                    </Col>
                                                </Row>
                                                <Row style={{ alignItems: 'center' }}>
                                                    <Col>
                                                        <h6>C.O.D. FEE &nbsp;</h6>
                                                    </Col>
                                                    <Col flex={1} style={{ marginRight: '1em' }}>
                                                        <div className="underline" />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={12}>
                                                        <Row style={{ alignItems: 'center' }}>
                                                            <label>Prepaid</label>
                                                            <Checkbox
                                                                className="bol-checkbox highlight-input"
                                                                inputId="cb-fright_charge_type"
                                                                value="Prepaid"
                                                                onChange={(e) =>
                                                                    setFreightChargeType(
                                                                        e.checked ? e.value : null
                                                                    )
                                                                }
                                                                checked={
                                                                    freightChargeType === 'Prepaid'
                                                                }
                                                            />
                                                        </Row>
                                                    </Col>
                                                    <Col span={12}>
                                                        <Row style={{ alignItems: 'center' }}>
                                                            <label>Collect</label>
                                                            <Checkbox
                                                                className="bol-checkbox highlight-input"
                                                                inputId="cb-fright_charge_type"
                                                                value="Collect"
                                                                onChange={(e) =>
                                                                    setFreightChargeType(
                                                                        e.checked ? e.value : null
                                                                    )
                                                                }
                                                                checked={
                                                                    freightChargeType === 'Collect'
                                                                }
                                                            />
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={5}>
                                        <div
                                            className="flex-column"
                                            style={{ height: '100%', padding: '0.4em' }}
                                        >
                                            <p className="p-sm" style={{ marginBottom: '1em' }}>
                                                &nbsp;Subject to Section 7 of conditions of
                                                applicable bill of lading, if this shipment is to be
                                                delivered to the consignee without resource on the
                                                consignor, the consignor shall sign the following
                                                statement:
                                            </p>
                                            <p className="p-sm">
                                                &nbsp;The carrier shall not make delivery of this
                                                shipment without payment of freight and all other
                                                lawful charges.
                                            </p>
                                            <div className="underline mt-auto" />
                                            <p className="p-sm text-center">
                                                (Signature of Consignor)
                                            </p>
                                        </div>
                                    </Col>
                                </Row>

                                {/* Table */}
                                <Row>
                                    <table>
                                        <thead className="border">
                                            <tr>
                                                <th>NO.</th>
                                                <th></th>
                                                <th>HM</th>
                                                <th></th>
                                                <th>*WEIGHT</th>
                                                <th>CLASS</th>
                                                <th>NMFC</th>
                                            </tr>
                                            <tr>
                                                <th>PKGS</th>
                                                <th>UM</th>
                                                <th>(X)</th>
                                                <th className="text-start ps-3">
                                                    DESCRIPTION OF ARTICLES, KIND OF PACKAGE,
                                                    SPECIAL MARKS AND EXCEPTIONS
                                                </th>
                                                <th colSpan={3}>(subject to correction)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {type === 'add'
                                                ? dataset.map((data, idx) => (
                                                      <tr key={`${data.order_no}-${idx}`}>
                                                          <td>{data.items.length}</td>
                                                          <td>CRATE</td>
                                                          <td></td>
                                                          <td className="text-start ps-3">
                                                              {
                                                                  data.items.find(
                                                                      (item) => !!item.description
                                                                  )?.description
                                                              }
                                                          </td>
                                                          <td>{data.total_gross_weight}</td>
                                                          <td className="text-center">
                                                              <InputNumber
                                                                  className="bol-inputnumber print-display-none highlight-input"
                                                                  value={
                                                                      classNumberList[idx] || null
                                                                  }
                                                                  onChange={(e) =>
                                                                      handleInputNumberChange(
                                                                          'class',
                                                                          idx,
                                                                          e.value
                                                                      )
                                                                  }
                                                                  useGrouping={false}
                                                                  style={{
                                                                      height: '2em',
                                                                      width: '5em',
                                                                      marginLeft: '10px',
                                                                  }}
                                                              />
                                                              <span className="print-display-only">
                                                                  {classNumberList[idx]}
                                                              </span>
                                                          </td>
                                                          <td className="text-center">
                                                              <InputNumber
                                                                  className="bol-inputnumber print-display-none highlight-input"
                                                                  value={
                                                                      NMFCNumberList[idx] || null
                                                                  }
                                                                  onChange={(e) =>
                                                                      handleInputNumberChange(
                                                                          'NMFC',
                                                                          idx,
                                                                          e.value
                                                                      )
                                                                  }
                                                                  useGrouping={false}
                                                                  style={{
                                                                      height: '2em',
                                                                      width: '5em',
                                                                      marginLeft: '10px',
                                                                  }}
                                                              />
                                                              <span className="print-display-only">
                                                                  {NMFCNumberList[idx]}
                                                              </span>
                                                          </td>
                                                          {/* <td className="text-center">
                      <InputNumber
                        className="bol-inputnumber print-display-none"
                        value={subNumberList[idx] || null}
                        onChange={(e) => handleInputNumberChange("sub", idx, e.value)}
                        useGrouping={false}
                        style={{ height: "2em", width: "5em" }}
                      />
                      <span className="print-display-only">{subNumberList[idx]}</span>
                    </td> */}
                                                      </tr>
                                                  ))
                                                : dataset[0].line.map((data, idx) => (
                                                      <tr key={`${data.report_line_id}-${idx}`}>
                                                          <td>{data.num_of_packages}</td>
                                                          <td>CRATE</td>
                                                          <td></td>
                                                          <td className="text-start ps-3">
                                                              {data.description}
                                                          </td>
                                                          <td>{data.weight}</td>
                                                          <td className="text-center">
                                                              <InputNumber
                                                                  className="bol-inputnumber print-display-none highlight-input"
                                                                  value={
                                                                      classNumberList[idx] || null
                                                                  }
                                                                  onChange={(e) =>
                                                                      handleInputNumberChange(
                                                                          'class',
                                                                          idx,
                                                                          e.value
                                                                      )
                                                                  }
                                                                  useGrouping={false}
                                                                  style={{
                                                                      height: '2em',
                                                                      width: '5em',
                                                                  }}
                                                              />
                                                              <span className="print-display-only">
                                                                  {classNumberList[idx]}
                                                              </span>
                                                          </td>
                                                          <td className="text-center">
                                                              <InputNumber
                                                                  className="bol-inputnumber print-display-none highlight-input"
                                                                  value={
                                                                      NMFCNumberList[idx] || null
                                                                  }
                                                                  onChange={(e) =>
                                                                      handleInputNumberChange(
                                                                          'NMFC',
                                                                          idx,
                                                                          e.value
                                                                      )
                                                                  }
                                                                  useGrouping={false}
                                                                  style={{
                                                                      height: '2em',
                                                                      width: '5em',
                                                                  }}
                                                              />
                                                              <span className="print-display-only">
                                                                  {NMFCNumberList[idx]}
                                                              </span>
                                                          </td>
                                                          {/* <td className="text-center">
                      <InputNumber
                        className="bol-inputnumber print-display-none"
                        value={subNumberList[idx] || null}
                        onChange={(e) => handleInputNumberChange("sub", idx, e.value)}
                        useGrouping={false}
                        style={{ height: "2em", width: "5em" }}
                      />
                      <span className="print-display-only">{subNumberList[idx]}</span>
                    </td> */}
                                                      </tr>
                                                  ))}
                                        </tbody>
                                        <tfoot className="border">
                                            <tr>
                                                <td>{dataHeader.total_num_of_pkgs}</td>
                                                <td colSpan={3}></td>
                                                <td>{dataHeader.total_gross_weight} LBS</td>
                                                <td colSpan={2}>TOTALS</td>
                                            </tr>
                                            <tr>
                                                <td
                                                    colSpan={'100%'}
                                                    className="total text-center p-0"
                                                >
                                                    MARK 'X' IN THE HM COLUMN TO DESIGNATE HAZARDOUS
                                                    MATERIALS AS DEFINED IN NOT REGULATIONS
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </Row>

                                <div className="pagebreak" />

                                {/* Footer */}
                                <div className="break-inside-avoid">
                                    <Row>
                                        <Col span={13}>
                                            <div
                                                className="flex-column"
                                                style={{ paddingTop: '1em', paddingRight: '2em' }}
                                            >
                                                <p className="p-sm">
                                                    * If the shipment moves between two ports by a
                                                    carrier by water, the law requires that the bill
                                                    of lading shall state whether it is "carrier's
                                                    or shipper's weight."
                                                    <br />
                                                    NOTE - Where the rate is dependent on value,
                                                    shippers are required to state specifically in
                                                    writing the agreed or declared value of the
                                                    property.
                                                    <br />
                                                    The agreed or declared value of the property is
                                                    hereby stated by the shipper to be not exceeding
                                                </p>
                                                <p className="text-center">PER</p>
                                                <p className="p-sm" style={{ marginBottom: '1em' }}>
                                                    + "The fibre boxes used for this shipment
                                                    conform to the specifications set forth in the
                                                    box maker's certificate thereon, and all other
                                                    requirements of Uniform Freight
                                                    Classifications."
                                                    <br />
                                                </p>
                                                <p
                                                    style={{
                                                        fontSize: '0.8em',
                                                        marginBottom: '1em',
                                                        lineHeight: '1em',
                                                    }}
                                                >
                                                    <b>
                                                        SHIPPER'S CERTIFICATION This is to certify
                                                        that the above-named materials are properly
                                                        classified, described, packaged, marked and
                                                        labeled, and are in proper condition for
                                                        transportation according to the applicable
                                                        regulations of the Department of
                                                        Transportation.
                                                    </b>
                                                </p>
                                                <Row>
                                                    <label style={{ marginRight: '0.2em' }}>
                                                        Per
                                                    </label>
                                                    <div
                                                        className="underline"
                                                        style={{ flex: '1' }}
                                                    />
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col span={11}>
                                            <div className="flex-column" style={{ height: '100%' }}>
                                                <div className="text-center">
                                                    <label>Trailer Number:</label>
                                                    <br />
                                                    <label>Seal Number:</label>
                                                </div>
                                                <div
                                                    className="border"
                                                    style={{ height: '100%', padding: '0.4em' }}
                                                >
                                                    <label>
                                                        <b>EMERGENCY RESPONSE NUMBER:</b>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <b>CONTACT:</b>
                                                    </label>
                                                    <br />
                                                    <label>
                                                        <b>REGISTERED COMPANY:</b>
                                                    </label>
                                                    <Row>
                                                        <b>PLACARDS REQUIRED</b>
                                                        <b className="ms-auto">
                                                            SUPPLIED BY SHIPPER
                                                        </b>
                                                    </Row>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="border" style={{ padding: '0.4em' }}>
                                        <Col span={11}>
                                            <div className="flex-column">
                                                <p style={{ marginBottom: '2em' }}>
                                                    <label>SHIPPER:</label>HAVEN STEEL PRODUCTS
                                                </p>
                                                <Row style={{ marginBottom: '1em' }}>
                                                    <label>PER:</label>
                                                    <div
                                                        className="underline"
                                                        style={{ flex: '1' }}
                                                    ></div>
                                                </Row>
                                                <p style={{ lineHeight: '1.2em' }}>
                                                    Shipper Phone # / Fax # / E-mail
                                                    <br />
                                                    <span style={{ marginRight: '3em' }}>
                                                        {props.division == 'HAVEN'
                                                            ? '620-465-2573'
                                                            : '731-352-5351'}
                                                    </span>
                                                    {props.division == 'HAVEN'
                                                        ? '620-465-2323'
                                                        : '731-352-3774'}
                                                    <br />
                                                    {props.division == 'HAVEN' ? (
                                                        <a href="mailto:ehatchel@havensteel.com">
                                                            ehatchel@havensteel.com
                                                        </a>
                                                    ) : (
                                                        <a href="mailto:shipping@havensteel.com">
                                                            shipping@havensteel.com
                                                        </a>
                                                    )}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col
                                            span={2}
                                            className="d-flex align-items-end justify-content-center"
                                        >
                                            <h1>1</h1>
                                        </Col>
                                        <Col span={11}>
                                            <div className="flex-column" style={{ height: '100%' }}>
                                                <p>
                                                    <label>Received by:</label>
                                                    {dataHeader.shipToCustomerName}
                                                </p>
                                                <div className="underline" />
                                                <Row>
                                                    <label>Carrier/Driver:</label>
                                                    <Dropdown
                                                        options={carrierOptions}
                                                        value={selectedCarrier}
                                                        style={{ minWidth: '90%' }}
                                                        placeholder="Select carrier/driver"
                                                        onChange={(e) =>
                                                            setSelectedCarrier(e.value)
                                                        }
                                                        className="print-display-none highlight-input"
                                                    />
                                                    <p className="print-display-only">
                                                        {selectedCarrier}
                                                    </p>
                                                    <Button
                                                        onClick={() => setAddCarrierVisible(true)}
                                                        style={{ marginTop: '5px' }}
                                                        className="print-display-none"
                                                    >
                                                        Add
                                                    </Button>
                                                    {selectedCarrier && (
                                                        <>
                                                            <Button
                                                                onClick={() =>
                                                                    setEditCarrierVisible(true)
                                                                }
                                                                style={{
                                                                    marginTop: '5px',
                                                                    marginLeft: '5px',
                                                                }}
                                                                className="print-display-none"
                                                            >
                                                                Edit
                                                            </Button>
                                                        </>
                                                    )}
                                                </Row>
                                                <Row className="mt-auto">
                                                    <Col span={17}>
                                                        <div className="underline" />
                                                        <p className=" text-center">
                                                            Receiving & Carrier Signatures
                                                        </p>
                                                    </Col>
                                                    <Col span={6} className="ms-auto">
                                                        <div className="underline" />
                                                        <p className=" text-center">Date</p>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </Spin>
                )}
            </div>
            <Row className="hidden-print" style={{ justifyContent: 'end', margin: '0.4rem 0' }}>
                <div style={{ marginLeft: 'auto', display: 'flex' }}>
                    <Button
                        className="p-button-lg p-button-outlined"
                        label="Back"
                        onClick={() => handleBack()}
                        style={{
                            borderColor: 'gray',
                            color: 'gray',
                            marginRight: '0.6rem',
                        }}
                    />
                    <Spin spinning={postDataLoading}>
                        <Button
                            className="p-button-lg"
                            label="Print and Save"
                            style={{
                                backgroundColor: 'var(--haven-red)',
                                borderColor: 'var(--haven-red)',
                            }}
                            onClick={() => handlePrint()}
                            disabled={dataLoading || errorMessage !== null}
                        />
                    </Spin>
                </div>
            </Row>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default connect(mapStateToProps)(BillOfLadingReport);
