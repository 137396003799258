import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { SalesShipmentService } from './shipment.services';
import ShipmentLines from './shipment-linetable.component';
import { dateIsAfter } from '../../general/componentSetup';

// --- filters setup ---
FilterService.register('customDateIsAfter', dateIsAfter);

const dateFilterMatchModeOptions = [
    { label: 'Date is', value: FilterMatchMode.DATE_IS },
    { label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: 'customDateIsAfter' },
];
// --- filters setup done ---

const Shipment = (props) => {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [lineTableVisible, setLineTableVisivle] = useState(false);
    // const [lineDialogVisible, setLineDialogVisible] = useState(false)
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [tableLoading, setTableLoading] = useState(true);
    const [filters, setFilters] = useState(null);

    const columns = [
        { field: 'No', header: 'Shipment #' },
        { field: 'Sell_to_Customer_No', header: 'Sell To Customer' },
        { field: 'Posting_Date', header: 'Posting Date' },
        { field: 'Shipment_Date', header: 'Shipment Date' },
    ];

    const headingStyle = {
        color: 'gray',
        fontWeight: '500',
    };

    useEffect(() => {
        console.log(props.division);
        loadData();
        initFilters();
    }, [props.division]);

    const loadData = () => {
        setTableLoading(true);
        SalesShipmentService(props.division).then((result) => {
            // --- Filter-Datatable should be Date format but incoming data from NAV is string type so converting dates ---
            const dateFormattedResult = result.map((obj) => ({
                ...obj,
                Posting_Date: new Date(obj.Posting_Date),
                Shipment_Date: new Date(obj.Shipment_Date),
            }));
            setTableData(dateFormattedResult);
            setTableLoading(false);
        });
    };

    const clearFilter = (e) => {
        initFilters();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const refreshTable = (e) => {
        // clearFilter();
        loadData();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const onClickRow = (data) => {
        // SalesShipmentLinesService(data.data.No).then((result) => {
        //   setLineData(result)
        //   setLineTableVisivle(true)
        // })
        setSelectedRow(data.data.No);
        setLineTableVisivle(true);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            No: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Sell_to_Customer_No: { value: null, matchMode: FilterMatchMode.CONTAINS },
            Posting_Date: { value: null, matchMode: FilterMatchMode.DATE_IS },
            Shipment_Date: { value: null, matchMode: FilterMatchMode.DATE_IS },
        });
        setGlobalFilterValue('');
    };

    const onGlobalFilterChange = (e) => {
        const { value } = e.target;
        const _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleOnFilterClear = (field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: { ...prev[field], value: null },
        }));
    };

    const handleOnFilterMatchModeChange = ({ field, matchMode }) => {
        setFilters((prev) => ({
            ...prev,
            [field]: { ...prev[field], matchMode },
        }));
    };

    const postingDateBodyTemplate = (rowData) => formatDate(rowData.Posting_Date);
    const shipmentDateBodyTemplate = (rowData) => formatDate(rowData.Shipment_Date);

    const formatDate = (value) =>
        new Date(value).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour12: 'false',
        });

    const dateFilterTemplate = (options) => (
        <Calendar
            value={options.value}
            onChange={(e) => {
                options.filterCallback(e.value, options.index);
                //--- Have to force updating value in filters, otherwise it requires user to select one of the constraint options ---
                setFilters((prev) => ({
                    ...prev,
                    [options.field]: { ...prev[options.field], value: e.value },
                }));
            }}
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
            mask="99/99/9999"
        />
    );

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between align-items-center">
                <h2 style={headingStyle}>Shipments</h2>
                <span className="p-input-icon-left ms-auto">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilterValue}
                        onChange={onGlobalFilterChange}
                        placeholder="Global Search"
                    />
                </span>
                <Button
                    style={{ marginLeft: '10px' }}
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear Filter"
                    className="p-button-lg secondary-btn"
                    onClick={clearFilter}
                    onTouchEnd={clearFilter}
                />
                <Button
                    style={{ marginLeft: '5px' }}
                    type="button"
                    icon="pi pi-refresh"
                    label="Refresh"
                    className="p-button-lg secondary-btn"
                    onClick={refreshTable}
                    onTouchEnd={refreshTable}
                />
            </div>
        );
    };

    const renderColumns = columns.map((col, i) => {
        if (filters) {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                    onFilterClear={() => handleOnFilterClear(col.field)}
                    style={{ minWidth: '250px' }}
                    {...(Object.keys(filters).some((filterKey) => filterKey === col.field) && {
                        filter: true,
                    })}
                    {...(col.field === 'Posting_Date' && {
                        filter: true,
                        filterElement: dateFilterTemplate,
                        body: postingDateBodyTemplate,
                        dataType: 'date',
                        filterMatchModeOptions: dateFilterMatchModeOptions,
                        onFilterMatchModeChange: handleOnFilterMatchModeChange,
                    })}
                    {...(col.field === 'Shipment_Date' && {
                        filter: true,
                        filterElement: dateFilterTemplate,
                        body: shipmentDateBodyTemplate,
                        dataType: 'date',
                        filterMatchModeOptions: dateFilterMatchModeOptions,
                        onFilterMatchModeChange: handleOnFilterMatchModeChange,
                    })}
                />
            );
        }
    });

    return (
        <div className="shipment-component" style={{ height: '100%' }}>
            <ShipmentLines
                selectedRow={selectedRow}
                visible={lineTableVisible}
                onHide={() => setLineTableVisivle(false)}
            />
            <DataTable
                size="large"
                value={tableData}
                removableSort
                header={renderHeader}
                dataKey="id"
                responsiveLayout="scroll"
                paginator
                rows={10}
                rowHover
                filters={filters}
                filterDisplay="row"
                loading={tableLoading}
                sortField="No"
                sortOrder={-1}
                emptyMessage={'No records found.'}
                scrollHeight="flex"
                scrollable
                onRowClick={(data) => onClickRow(data)}
            >
                {renderColumns}
            </DataTable>
        </div>
    );
};

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(Shipment));
