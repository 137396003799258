import { useState, useRef } from 'react';
import { Row,Col } from 'antd';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import ReactToPrint from 'react-to-print';

import FinishedGoodsReport from '../report/finishedgoods.report.component';

function ReportDialog(props) {
  const { dataset } = props;
  const printRef = useRef(null);
  return (
    <Dialog
      className="report-dialog"
      {...props}
      onHide={() => props.setVisible(false)}
      dismissableMask
      header="Report - Finished Goods In Warehouse"
      draggable={false}
      style={{ height: '90vh', width: '90vw' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '0.4rem',
          alignItems: 'center',
        }}
      >
        <Row className="hidden-print" style={{ alignSelf: 'end', margin: '0.4rem 0' }}>
          <Col style={{ marginRight: '100px' }}>
            <ReactToPrint
              trigger={() => (
                <Button
                  className="p-button-lg"
                  label="Print"
                  style={{
                    backgroundColor: 'var(--haven-red)',
                    borderColor: 'var(--haven-red)',
                  }}
                />
              )}
              content={() => printRef.current}
            />
          </Col>
          <Col span={6}>
          </Col>
        </Row>
        <FinishedGoodsReport printRef={printRef} dataset={dataset} />

      </div>
    </Dialog>
  );
}

export default ReportDialog;
