import { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FilterMatchMode, FilterService } from 'primereact/api';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import {
    GetPackingListReportService,
    GetPackingListReportLineService,
} from './packinglistreporttabledialog.services';
import PackingList from '../report/packinglist.report.component';
import { dateIsAfter } from '../../general/componentSetup';

// --- filters setup ---
FilterService.register('customDateIsAfter', dateIsAfter);

const dateFilterMatchModeOptions = [
    { label: 'Date is', value: FilterMatchMode.DATE_IS },
    { label: 'Date is not', value: FilterMatchMode.DATE_IS_NOT },
    { label: 'Date is before', value: FilterMatchMode.DATE_BEFORE },
    { label: 'Date is after', value: 'customDateIsAfter' },
];
// --- filters setup done ---

function PackingListTable({ visible, setVisible, division }) {
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [lineTableVisible, setLineTableVisivle] = useState(false);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [tableLoading, setTableLoading] = useState(true);
    const [filters, setFilters] = useState(null);

    const columns = [
        { field: 'created_datetime', header: 'Created Date' },
        { field: 'created_by', header: 'Created By' },
        { field: 'shipment_date', header: 'Shipment Date' },
        { field: 'order_date', header: 'Order Date' },
        { field: 'customer_code', header: 'Customer Code' },
        { field: 'order_number', header: 'Order No' },
    ];

    const headingStyle = {
        color: 'gray',
        fontWeight: '500',
    };

    useEffect(() => {
        loadData();
        initFilters();
    }, [visible, division]);

    useEffect(() => {
        refreshTable();
    }, [lineTableVisible]);

    const loadData = () => {
        GetPackingListReportService(division).then((result) => {
            setTableData(result);
            setTableLoading(false);
        });
    };

    const clearFilter = (e) => {
        initFilters();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const refreshTable = (e) => {
        // clearFilter();
        loadData();
        if (e && e.type === 'touchend') {
            e.preventDefault();
        }
    };

    const onClickRow = (data) => {
        GetPackingListReportLineService(data.data.report_no).then((result) => {
            const tempData = [
                {
                    header: data.data,
                    line: result,
                },
            ];
            setSelectedRow(tempData);
            console.log(tempData);
            setLineTableVisivle(true);
        });
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            created_datetime: { value: null, matchMode: FilterMatchMode.DATE_IS },
            created_by: { value: null, matchMode: FilterMatchMode.CONTAINS },
            shipment_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
            order_date: { value: null, matchMode: FilterMatchMode.DATE_IS },
            customer_code: { value: null, matchMode: FilterMatchMode.CONTAINS },
            order_number: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue('');
    };

    const onGlobalFilterChange = (e) => {
        const { value } = e.target;
        const _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const handleOnFilterClear = (field) => {
        setFilters((prev) => ({
            ...prev,
            [field]: { ...prev[field], value: null },
        }));
    };

    const handleOnFilterMatchModeChange = ({ field, matchMode }) => {
        setFilters((prev) => ({
            ...prev,
            [field]: { ...prev[field], matchMode },
        }));
    };

    const CreatedDateBodyTemplate = (rowData) => formatDate(rowData.created_datetime);
    const shipmentDateBodyTemplate = (rowData) => formatDate(rowData.shipment_date);
    const orderDateBodyTemplate = (rowData) => formatDate(rowData.order_date);

    const formatDate = (value) =>
        new Date(value).toLocaleString('en-US', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour12: 'false',
        });

    const dateFilterTemplate = (options) => (
        <Calendar
            value={options.value}
            onChange={(e) => {
                options.filterCallback(e.value, options.index);
                //--- Have to force updating value in filters, otherwise it requires user to select one of the constraint options ---
                setFilters((prev) => ({
                    ...prev,
                    [options.field]: { ...prev[options.field], value: e.value },
                }));
            }}
            dateFormat="mm/dd/yy"
            placeholder="mm/dd/yyyy"
            mask="99/99/9999"
        />
    );

    const renderHeader = () => (
        <div className="d-flex justify-content-between align-items-center">
            <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Global Search"
            />
            <div>
                <Button
                    style={{ marginLeft: '10px' }}
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear Filter"
                    className="p-button-lg"
                    onClick={clearFilter}
                    onTouchEnd={clearFilter}
                />
                <Button
                    style={{ marginLeft: '5px' }}
                    type="button"
                    icon="pi pi-refresh"
                    label="Refresh"
                    className="p-button-lg"
                    onClick={refreshTable}
                    onTouchEnd={refreshTable}
                />
            </div>
        </div>
    );

    const renderColumns = columns.map((col, i) => {
        if (filters) {
            return (
                <Column
                    key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable
                    onFilterClear={() => handleOnFilterClear(col.field)}
                    style={{ minWidth: '250px' }}
                    {...(Object.keys(filters).some((filterKey) => filterKey === col.field) && {
                        filter: true,
                    })}
                    {...(col.field === 'created_datetime' && {
                        filter: true,
                        filterElement: dateFilterTemplate,
                        body: CreatedDateBodyTemplate,
                        dataType: 'date',
                        filterMatchModeOptions: dateFilterMatchModeOptions,
                        onFilterMatchModeChange: handleOnFilterMatchModeChange,
                    })}
                    {...(col.field === 'shipment_date' && {
                        filter: true,
                        filterElement: dateFilterTemplate,
                        body: shipmentDateBodyTemplate,
                        dataType: 'date',
                        filterMatchModeOptions: dateFilterMatchModeOptions,
                        onFilterMatchModeChange: handleOnFilterMatchModeChange,
                    })}
                    {...(col.field === 'order_date' && {
                        filter: true,
                        filterElement: dateFilterTemplate,
                        body: orderDateBodyTemplate,
                        dataType: 'date',
                        filterMatchModeOptions: dateFilterMatchModeOptions,
                        onFilterMatchModeChange: handleOnFilterMatchModeChange,
                    })}
                />
            );
        }
    });

    return (
        <>
            <Dialog
                className="bol-specific-report-dialog"
                visible={lineTableVisible}
                onHide={() => setLineTableVisivle(false)}
                dismissableMask
                header="Packing List"
                draggable={false}
                style={{ height: '90vh', width: '95vw' }}
            >
                <PackingList
                    dataset={selectedRow}
                    type="edit"
                    setLineTableVisivle={(val) => setLineTableVisivle(val)}
                />
            </Dialog>
            <Dialog
                className="packinglist-dialog"
                visible={visible}
                onHide={() => setVisible(false)}
                dismissableMask
                header="Packing List Table"
                draggable={false}
                style={{ height: '90vh', width: '90vw' }}
            >
                <DataTable
                    size="large"
                    value={tableData}
                    removableSort
                    header={renderHeader}
                    dataKey="report_no"
                    responsiveLayout="scroll"
                    paginator
                    rows={10}
                    rowHover
                    filters={filters}
                    loading={tableLoading}
                    sortField="created_datetime"
                    sortOrder={-1}
                    emptyMessage="No records found."
                    filterDisplay="row"
                    scrollHeight="flex"
                    scrollable
                    onRowClick={(data) => onClickRow(data)}
                >
                    {renderColumns}
                </DataTable>
            </Dialog>
        </>
    );
}

export default PackingListTable;
