import ProgressDiagramFlex from "./progressDiagramFlex.component";
import React from 'react'
import "./progressDiagram.styles.css"


class ProgressDiagramFlexCollection extends React.Component {
    render() { 
        const { numbers, colors, id } = this.props;
        return (
            <div className="ProgressDiagramFlexCollection">
                {   
                    numbers.map((num, i) => {
                        return <ProgressDiagramFlex number={num} key={`progress-flex${id}-${num}-${i}`} colour={ colors[i] } />
                    })
                }
            </div>
        );
    }
}
 
export default ProgressDiagramFlexCollection;
