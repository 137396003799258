import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import LoginPage from './pages/login/login.page';
import ResetPasswordPage from './pages/reset-password/reset-password.page';
import DashboardPage from './pages/inventory/inventory.page';
import ShipCratePage from './pages/shipment/shipment.page';
import CrateTablePage from './pages/create-crate-table/create-crate-table.page';
import { DISCONNECT } from '../src/redux/types';
import { store } from '../src/redux/store';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'primereact/resources/primereact.min.css'; //core css
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css'; //icons
import './assets/styles/bulky-checkbox.css';
import './assets/styles/button-styles.css';
import CrateSalesPage from './pages/create-crate-table/sales/create-crate-sales.page';

async function disconnectWithSSO(history) {
    store.dispatch({
        type: DISCONNECT,
    });
    history.push('/login');
}

function App({ history, user }) {
    var isUserThere = false;
    if (user) {
        const expiryTime = localStorage.getItem('user');
        const today = new Date();

        if (new Date(expiryTime) <= new Date(today) || expiryTime === '' || expiryTime == null) {
            localStorage.setItem('user', '');
            isUserThere = false;
            disconnectWithSSO(history);
        } else {
            isUserThere = true;
        }
    } else {
        isUserThere = false;
    }

    return (
        <div>
            <Switch>
                <Route
                    exact
                    path={['/', '/home']}
                    render={() =>
                        isUserThere ? (
                            <Redirect to={{ pathname: '/create-crate' }} />
                        ) : (
                            <Redirect to={{ pathname: '/login' }} />
                        )
                    }
                />
                <Route
                    exact
                    path={['/login', '/register', '/request-reset']}
                    component={LoginPage}
                />
                <Route
                    exact
                    path={['/inventory']}
                    render={() =>
                        isUserThere ? <DashboardPage /> : <Redirect to={{ pathname: '/login' }} />
                    }
                />
                <Route
                    exact
                    path={'/create-crate'}
                    render={() =>
                        isUserThere ? <CrateTablePage /> : <Redirect to={{ pathname: '/login' }} />
                    }
                />
                <Route
                    exact
                    path={'/create-crate/sales'}
                    render={() =>
                        isUserThere ? <CrateSalesPage /> : <Redirect to={{ pathname: '/login' }} />
                    }
                />
                <Route
                    exact
                    path={'/shipment'}
                    render={() =>
                        isUserThere ? <ShipCratePage /> : <Redirect to={{ pathname: '/login' }} />
                    }
                />
                <Route path="/ResetPassword" render={() => <ResetPasswordPage />} />
            </Switch>
        </div>
    );
}

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(App));
