import { withRouter } from "react-router-dom";
import { Layout } from "antd";

import Header from "../../components/header/header.component";
import FooterComponent from "../../components/footer/footer.component";
import ResetPassword from "./reset-password.component";

import "./reset-password.styles.css";

const { Content } = Layout;

const ResetPasswordPage = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Header />
      <Layout style={{ flex: "1" }}>
        <Layout className="site-layout" style={{ height: "100%" }}>
          <Content style={{ height: "100%", overflow: "auto" }}>
            <ResetPassword />
          </Content>
        </Layout>
      </Layout>
      <FooterComponent />
    </Layout>
  );
};

export default withRouter(ResetPasswordPage);
