import React from "react";
import { getRemainingSeconds, getIntervalDuration, getCurrentDateTime, getTimeElapsed, getCountdownParameters, toClockDisplay } from "./countdown.function";

/* For Performing Activities every interval of time 
    <PROPS>
    - duration <OBJECT> = indicate the countdown length
        Format: { hours, minutes, seconds }
        Default: 1 minute
    - repeat <INTEGER> = indicate the amount of times will countdown
        Default: infinite
    - hidden <BOOLEAN> = indicate to show rendered elements
    - onCountdown <METHOD> = indicate an event handler when one interval is complete
    - render <METHOD> = allows to customize rendering
*/
class Countdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            repeat: undefined,
            intervalStart: undefined,
            intervalDuration: 1000, // in millisecs
            intervalTimer: undefined,

            millisecsElapsed: 0, 
            roundsElapsed: 0,
        }
    }

    async componentDidMount() {
        await this.initializeCountdown();
        this.startCountdown();
    }

    render() {
        const { hidden, render } = this.props;
        const { intervalDuration,  millisecsElapsed } = this.state;

        const secondsRemaining = getRemainingSeconds(intervalDuration,  millisecsElapsed);
        const [ hours, minutes, seconds ] = getCountdownParameters(secondsRemaining);
        const clockDisplay = toClockDisplay(hours, minutes, seconds);

        return (!hidden) ? render({ hours, minutes, seconds, clockDisplay }) : null;
    }

    initializeCountdown = () => {
        const { duration, repeat } = this.props;
        const intervalDuration = getIntervalDuration(duration);

        this.setState({ 
            intervalDuration,
            repeat
        });
    }

    startCountdown = () => {
        const { intervalDuration } = this.state;
        setTimeout(() => this.finishCountdown(), intervalDuration);

        const intervalStart = getCurrentDateTime();
        this.setState({ intervalStart },
            this.startTimer()
        );
    }

    finishCountdown = async () => {
        this.stopTimer();

        // Update roundsElapsed
        let { roundsElapsed, repeat } = this.state;
        roundsElapsed++;
        this.setState({ roundsElapsed });

        await this.props.onCountdown();
        if (repeat === 'infinite' || repeat > roundsElapsed) {
            this.startCountdown();
        }
    }

    startTimer = () => {
        const countdownTimer = setInterval(this.updateTimer, 1000);
        this.setState({ countdownTimer });
    }

    stopTimer = () => {
        const { intervalTimer } = this.state;
        clearInterval(intervalTimer);
    }

    updateTimer = () => {
        const { intervalStart } = this.state;
        const currentDateTime = getCurrentDateTime();
        const millisecsElapsed = getTimeElapsed(intervalStart, currentDateTime);

        this.setState({ millisecsElapsed });
    }
}

Countdown.defaultProps = {
    duration: { minutes: 1 },
    repeat: "infinite", 
    hidden: false,
    onCountdown: () => {},
    render: (...arg) => defaultRender(...arg)
}

function defaultRender({ clockDisplay }) {
    return clockDisplay;
}

export default Countdown;