import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, Form, Spin } from 'antd';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { ValidateTokenService, UpdatePasswordService } from './reset-password.services';

class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: null,
            loading: true,
            invalidToken: true,
            expiredToken: true
        }
        this.form = React.createRef();
    }

    componentDidMount() {
        const token = this.props.history.location.pathname.split('/ResetPassword/')[1];
        if (token) {
            ValidateTokenService(token).then((expiredToken) => {
                this.setState({
                    token,
                    loading: false, 
                    invalidToken: false,
                    expiredToken: expiredToken
                });
            });
        }
        else {
            this.setState({ 
                token,
                loading: false, 
                invalidToken: true 
            });
        }
    }

    renderLoading() {
        return (
            <div style={{ padding: '15px 40px 0 40px' }}>
                <Spin size="large" />
                <h3 style={{ marginTop: '15px' }}>Please wait...</h3>
            </div>
        )
    }

    renderError(type) {
        const error = {
            'invalid' : { text: 'Your password reset is invalid or unauthorized!', color: 'red' },
            'expired' : { text: 'Your password reset is expired!', color: '#E88D0E' }
        }[type];

        return (
            <div className="error-container">
                <div className="error-content">
                    <ExclamationCircleTwoTone twoToneColor={ error.color } className="error-symbol"/>
                    <h3 className="no-margin">{ error.text }</h3>
                </div>
                <Button className="colorless-btn" onClick={ this.handleRedirect }>Click to Request a new Reset</Button>
            </div>
        )
    }

    renderResetForm() {
        const passwordRules = [{ required: true, message: 'Please input your password!'}, { min: 6, message: 'Password must be minimum 6 characters.' }];
        const confirmPasswordRules = [
            { required: true, message: 'Please confirm your password!'},
            { min: 6, message: 'Password must be minimum 6 characters.'},
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error('Your passwords do not match!'));
                },
            }),
        ];

        return (
            <div>
                <h3 className="login-title">Password Reset</h3>
                <p>Your password must have at least 6 characters</p>
                <Form ref={ this.form } 
                    layout='vertical'
                    requiredMark={ false }
                    onFinish={ this.handlePasswordChange }
                >
                    <Form.Item hasFeedback name="password" label="Password" rules={ passwordRules }>
                        <Input.Password visibilityToggle={false} />
                    </Form.Item>
                    <Form.Item hasFeedback name="confirm" label="Confirm Password" dependencies={['password']} rules={ confirmPasswordRules }>
                        <Input.Password visibilityToggle={false} />
                    </Form.Item>
                    <Form.Item className="button-wrapper">
                        <Button htmlType="submit" className="primary-btn">Reset password</Button>
                    </Form.Item>
                </Form>
            </div>
        ) 
    }

    render() {
        const { loading, invalidToken, expiredToken } = this.state;
        return (
            <div id="reset-password" className="login-component">
                <p className="application-name">Warehouse & Shipping Manager</p>
                <div className="login-content-container">
                    <div className="login-container">
                        {   
                            (loading) ? this.renderLoading() :
                            (invalidToken) ? this.renderError('invalid') : 
                            (expiredToken) ? this.renderError('expired') : 
                            this.renderResetForm()
                        }
                    </div>
                </div>
            </div>
        );
    }

    handlePasswordChange = (input) => {
        const { token } = this.state;
        UpdatePasswordService(input.password, token).then(() => {
            this.props.history.push('/login');
        });
    } 

    handleRedirect = () => this.props.history.push('/request-reset');
}

export default withRouter(ResetPassword);
