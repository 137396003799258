import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function GetPackingListReportService(division) {
    return axiosInstance
        .post(`${url}/report/billoflading/getpackinglistreport`,{division:division})
        .then(({ data: result }) =>
            [...(result || [])].map((d) => {
                d.created_datetime = new Date(d.created_datetime);
                d.shipment_date = new Date(d.shipment_date);
                d.order_date = new Date(d.shipment_date);
                return d;
            })
        );
}

export function GetPackingListReportLineService(report_no) {
    return axiosInstance
        .get(`${url}/report/billoflading/getpackinglistreportLine`, { params: { report_no } })
        .then(({ data: result }) => result);
}
