import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Form, Input } from 'antd';
import { updateFreightBillTo } from './edit-address.service';
import { useState } from 'react';

const EditAddressDialog = (props) => {
  const { header, visible, onHide, onFinish, values } = props;
  const [submitLoading, setSubmitLoading] = useState(false);

  const onFinishEditAddress = async (value) => {
    setSubmitLoading(true);
    await updateFreightBillTo({
      name: 'bol_customer_addr',
      label: value.Name,
      value: value,
      oldValue: values
    });

    console.log(values);

    setSubmitLoading(false);
    onFinish(value);
  };
  return (
    <Dialog
      header={header || 'Edit an Address'}
      visible={visible}
      style={{ width: '50vw' }}
      onHide={onHide}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        onFinish={onFinishEditAddress}
        autoComplete="off"
        initialValues={values}
      >
        <Form.Item
          label="Name"
          name="Name"
          rules={[{ required: true, message: 'Please input the name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Address1" name="Address1">
          <Input />
        </Form.Item>
        <Form.Item label="Address2" name="Address2">
          <Input />
        </Form.Item>
        <Form.Item label="Address3" name="Address3">
          <Input />
        </Form.Item>
        <Form.Item label="Address4" name="Address4">
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={submitLoading}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Dialog>
  );
};

export default EditAddressDialog;
