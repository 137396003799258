import React from "react";
import './input-wrapper.styles.css';

class InputWrapper extends React.Component {
    renderTitle() {
        const { showTitle, title, disabled } = this.props;
        const titleClass = this.getClass(showTitle, disabled);
        
        return (
            <div className={ titleClass }>
                <span>{ title }</span>
            </div>
        );
    }

    render() {
        return (
            <div className="input-wrapper">
                { this.renderTitle() }
                { this.props.children }
            </div>
        );
    }

    getClass = (showTitle, disabled) => {
        let titleClass = ["input-wrapper-title"];
        if (showTitle === true) {
            titleClass.push("title-animation");
        }
        if (showTitle === false) {
            titleClass.push("invisible-title");
        }
        if (disabled === true) {
            titleClass.push("disabled-input");
        }
        return titleClass.join(" ");
    }
}

export default InputWrapper;