import Icon from '@ant-design/icons';

export const ShipOutlined = props => <Icon component={ShipSvg} {...props} />;

const ShipSvg = () => (
	<svg width="1em" height="1em" fill="currentColor" viewBox="0 0 100 100">
		<path d="M42.49,51.39c-0.47,0.57-0.65,1.3-0.49,2c0.17,0.75,0.71,1.39,1.46,1.73c0.32,0.15,0.64,0.22,0.96,0.22
			c0.58,0,1.13-0.24,1.63-0.7c1.16-1.08,2.33-2.17,3.45-3.29c0.9-0.9,1.77-1.83,2.58-2.8c0.83-0.99,0.83-2.04,0-2.9
			c-1.87-1.94-3.77-3.87-5.71-5.75c-0.45-0.44-1.05-0.68-1.67-0.68c-0.65,0-1.29,0.27-1.75,0.74c-0.97,0.99-0.99,2.36-0.05,3.41
			c0.36,0.4,0.73,0.79,1.1,1.17c0,0-24.66,0.05-34.64,0.09c-1,0-2,0.11-2.99,0.23c-0.48,0.06-1.11,0.35-1.41,0.86
			c-0.52,0.86-0.56,1.85-0.12,2.65c0.42,0.75,1.2,1.19,2.12,1.2c0.38,0,2.02,0.08,2.46,0.08l34.6,0.04
			C43.51,50.24,42.98,50.8,42.49,51.39z"/>
		<path d="M95.07,44.57c-0.41-3.1-1.57-5.73-3.46-7.83c-2.63-2.91-5.97-4.39-10.03-4.39l-9.1-0.03v-4.74
			c0-1.37-1.22-2.58-2.6-2.58c0,0-23.45,0.01-30.54,0.03c-4.38,0.01-8.77,0.06-13.16,0.13c-2.38,0.04-2.98,0.64-3.02,3.04
			c-0.03,1.65-0.04,3.31-0.04,4.96l-0.01,1.69l-6.52,0.09c-0.78,0.01-1.56,0.05-2.33,0.14c-1.14,0.15-2.1,1.35-2,2.52
			c0.1,1.32,1.06,2.28,2.26,2.28l5.53,0.01l5.83-0.01c1.13-0.01,1.82-0.57,2.09-1.71c0.07-0.29,0.14-0.62,0.15-0.97l0.11-7.05
			c0,0,29.64,0,39.09-0.01c-0.02,5.44-0.01,10.88-0.01,16.32c0,0,0,14.08,0,17.63c-0.64-0.01-1.29-0.01-1.94-0.01l-5.76,0.02
			c-4.15,0.01-8.31,0.03-12.51,0.09c-0.39,0-0.42-0.01-0.59-0.35c-1.34-2.66-3.65-4.13-7.04-4.25c-2.71,0-4.93,1.27-6.43,3.67
			c-0.1,0.17-0.19,0.34-0.28,0.52c-0.08,0.15-0.14,0.3-0.1,0.43c-0.74-0.01-3.66-0.09-4.42-0.1c-0.01-1.87-0.09-7.58-0.09-7.58
			c-0.02-1.13-1.16-2.29-2.26-2.29c-3.77,0.03-7.54,0.05-11.31,0.1c-1.26,0.02-2.26,1.03-2.33,2.36c-0.06,1.24,0.95,2.38,2.21,2.48
			c1.01,0.08,2.04,0.11,3.06,0.12l5.57,0.01v7.53c0,1.26,1.11,2.39,2.39,2.4l1.65,0.03c1.27,0.02,2.53,0.04,3.79,0.04l1.3,0.18
			c0.89,2.76,2.73,4.58,5.35,5.25c0.7,0.18,1.41,0.27,2.11,0.27c3.54,0,6.46-2.32,7.25-5.78l13.2,0.01l13.01-0.16
			c0.88,3.49,3.97,5.93,7.52,5.93c1.34,0,2.69-0.36,3.89-1.03c1.81-1.02,3.05-2.67,3.45-4.75l1.47,0.01l2.56-0.02
			c0.48,0,0.98-0.02,1.45-0.12c1.18-0.25,1.88-1.13,1.89-2.35l0.01-4.34c0.02-3.98,0.03-7.96,0.01-11.94
			C95.42,48.19,95.3,46.32,95.07,44.57z M86.91,62.71c-1.46-1.99-3.71-3.13-6.18-3.13c-2.59,0-5,1.29-6.59,3.65
			c-0.11,0.17-0.22,0.38-0.32,0.59c-0.08,0.16-0.2,0.41-0.14,0.41c0,0,0,0,0,0c-0.17,0.05-0.38,0.08-0.66,0.08
			c-0.17,0-0.34-0.01-0.52-0.02V37.25l2.55,0.05c1.99,0.05,3.98,0.09,5.98,0.09l0.78,0c5.18,0,7.22,4.4,7.72,5.75
			c0.44,1.17,0.65,2.4,0.67,3.77l0.11,12.79l0.01,4.56c0,0-0.86,0-0.91,0c-0.63,0-1.23,0-1.55,0.08c-0.12-0.2-0.24-0.4-0.35-0.6
			C87.33,63.38,87.14,63.03,86.91,62.71z M78.03,67.25c0-1.34,1.25-2.58,2.62-2.59l0.02-0.92l0,0v0.92c0.71,0,1.52,0.38,2.05,0.97
			c0.31,0.34,0.66,0.89,0.59,1.76c0.05,0.73-0.3,1.27-0.6,1.6c-0.53,0.57-1.32,0.94-2.05,0.94C79.2,69.9,78.03,68.72,78.03,67.25z
			M42.29,67.27c0.01,0.69-0.26,1.35-0.76,1.85c-0.51,0.52-1.2,0.81-1.95,0.81c-1.47,0-2.58-1.12-2.58-2.61
			c0-1.46,1.08-2.62,2.45-2.63l0.03-0.92l0,0v0.92C41.07,64.69,42.27,65.79,42.29,67.27z" />
	</svg>
);