import React from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './numpad.styles.css';
import { notDoubleDecimal, notExceededMax, hasDecimal } from './numpad.functions';

const numPadKeys = [1, 2, 3, 4, 5, 6, 7, 8, 9];

/* <PROPS>
	value = numbersEntered,
	numberType,
	maxDecimals
*/
class NumPad extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			numbersEntered: this.props.value,
			numberType: (this.props.allowDecimals) ? "decimal" : "int",
			maxDecimals: this.props.maxDecimals,
		};
	}

	keyboardEventListener = (event) => {
		const { key } = event;
		const { numbersEntered } = this.state;
		if (!isNaN(key)) {
			this.handleAdd(key);
		} 
		else {
			switch (key) {
				case ".": 
					this.handleAdd('.');
					break;
				case "Backspace":
					this.handleDelete(numbersEntered);
					break;
				case "Enter":
					this.handleFinish();
					break;
				default:
			}
		}
	};

	componentDidMount() {
		window.addEventListener('keydown', this.keyboardEventListener);
	}

	componentDidUpdate() {
		window.addEventListener('keydown', this.keyboardEventListener);
	}

	componentWillUnmount() {
		window.removeEventListener('keydown', this.keyboardEventListener);
	}

	renderMessage = (maxExceeded, maxDecimals) => {
		return (maxExceeded) ? <span>Maximum { maxDecimals } decimals allowed!</span> : "";
	}

	renderButtons = (maxExceeded) => {
		return numPadKeys.map((key) => (
			<Button onClick={() => this.handleAdd(key)} 
				disabled={ maxExceeded }
				key={`numpad-${key}`}>
				{key}
			</Button>
		));
	};

	render() {
		const { numbersEntered, numberType, maxDecimals } = this.state;
		const maxExceeded = !notExceededMax(numbersEntered, maxDecimals);

		return (
			<div className="numpad-component">
				<div className="numpad-display">{ numbersEntered }</div>
				<div className="numpad-message">{ this.renderMessage(maxExceeded, maxDecimals) }</div>
				<div className="numpad-grid-container">
					<div className="numpad-grid">
						{ this.renderButtons(maxExceeded) }
						<Button onClick={() => this.handleAdd('.')} key="numpad-decimal" disabled={ (numberType === "int" || hasDecimal(numbersEntered)) }>
							.
						</Button>
						<Button onClick={() => this.handleAdd('0')} disabled={ maxExceeded } key="numpad-0">
							0
						</Button>
						<Button onClick={() => this.handleDelete(numbersEntered)} key="numpad-delete">
							<ArrowLeftOutlined className="arrow-left-icon" />
						</Button>
					</div>
				</div>
				<Button className="light-btn" onClick={this.handleFinish}>
					done
				</Button>
			</div>
		);
	}

	addNumber(existingNumber, newNumber) {
		const updatedNumber = (!existingNumber) ? ((newNumber === ".") ? "0" + newNumber : newNumber) : `${existingNumber}` + `${newNumber}`;
		this.setState({ numbersEntered: updatedNumber });
	}

	handleAdd = (input) => {
		const { numbersEntered, numberType, maxDecimals } = this.state;
		
		if (numberType === "int" && input !== ".") {
			this.addNumber(numbersEntered, input);
		}
		else if (numberType === "decimal") {
			if (notDoubleDecimal(numbersEntered, input) && 
				notExceededMax(numbersEntered, maxDecimals)) {
				this.addNumber(numbersEntered, input);
			}
		}
	};

	handleDelete = (numbersEntered) => {
		if (numbersEntered) {
			const digits = numbersEntered.length;
			const removed = numbersEntered[digits - 1];
			const newNumbers = `${numbersEntered}`.slice(0, digits - 1);

			let updatedStates = {};
			updatedStates.numbersEntered = newNumbers;
			if (removed === '.') {
				updatedStates.decimalEntered = false;
			}

			this.setState(updatedStates);
		}
	};

	handleFinish = () => {
		const { numbersEntered } = this.state;

		// remove keydown event
		window.removeEventListener('keydown', this.keyboardEventListener);

		this.props.onFinish(numbersEntered);
	};
}

NumPad.defaultProps = {
	allowDecimals: false,
	value: undefined,
	maxDecimals: 10
};

export default NumPad;
