import axiosInstance from '../../general/axiosInstance';
import { notification } from 'antd';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function ValidateTokenService(token) {
    return axiosInstance
        .post(`${url}/validateToken`, { token })
        .then(({ data }) => {
            return !data;
        })
        .catch((error) => `ValidateTokenService: ${error}`);
}

export function UpdatePasswordService(password, token) {
    return axiosInstance
        .post(`${url}/updateUserPass`, { password, token })
        .then(({ data: result }) => {
            if (result === false) {
                notification.error({
                    message: 'Password Reset Failed!',
                });
            } else {
                notification.success({
                    message: 'Password Reset Success!',
                });
            }
            return;
        })
        .catch((error) => `UpdatePasswordService: ${error}`);
}
