import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function getLabelService(data) {
    return axiosInstance
        .post(`${url}/webservice/getLabel`, { data })
        .then(({ data: zpl }) => {
            return zpl;
        })
        .catch((error) => console.log(`GetLabelService Client: ${error}`));
}
