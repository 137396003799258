import React, { useEffect } from 'react';
import { Modal, Form, Button, Descriptions } from 'antd';

function ErrorInfoDialog({ visible, data, onClose, onComplete }) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (form) {
            // console.log('test', data);
            form.setFieldsValue(data);
        }
    }, [data, form]);

    const footer = (
        <>
            <Button onClick={onClose}>Close</Button>
        </>
    );

    return (
        <Modal
            title="Error Info"
            className="error-info-component"
            visible={visible}
            onCancel={onClose}
            footer={footer}
            getContainer={false}
        >
            {/* <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
                <Form.Item label="Storage Location" name="location" rules={required}>
                    <Input size="large" />
                </Form.Item>
                <Form.Item label="Quantity (pc)" name="total_quantity" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item>
                <Form.Item label="Gross Weight (lb)" name="total_gross_weight" rules={required}>
                    <InputNumPad size="large" />
                </Form.Item>
            </Form> */}
            <p>
                <b>* This error detail will be sent to Alpacasys Helpdesk *</b>
            </p>
            {data?.map((item) => {
                console.log(item);
                return (
                    <>
                        <Descriptions>
                            <Descriptions.Item label="Crate ID">
                                {item?.data.crate_id}
                            </Descriptions.Item>
                            <Descriptions.Item label="Part #">
                                {item?.data.part_no}
                            </Descriptions.Item>
                            <Descriptions.Item label="MO #">
                                {item?.data.order_no}
                            </Descriptions.Item>
                            <Descriptions.Item label="SO #">{item?.data.so_no}</Descriptions.Item>
                            <Descriptions.Item label="SO Line #">
                                {item?.data.so_line_no}
                            </Descriptions.Item>
                            {/* <Descriptions.Item label="Error Reason">{item?.reason}</Descriptions.Item> */}
                        </Descriptions>
                        <Descriptions>
                            <Descriptions.Item label="Error Message">
                                {item?.reason}
                            </Descriptions.Item>
                        </Descriptions>
                    </>
                );
            })}
        </Modal>
    );
}

export default ErrorInfoDialog;
