import { Col, Row } from 'antd';
import './reports.styles.css';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import HavenReportLogo from '../../assets/icons/HSP_Icon.svg';

function FinishedGoodsReport(props) {
  const { printRef, dataset } = props;
  const [data, setData] = useState([]);
console.log("1",dataset);
  useEffect(() => {
    const rawData = dataset;
    const sortedData = [];

    // --- Restructure data group by customer ---
    rawData.forEach((dataObj) => {
      const targetCustomer = dataObj.customer_code;
      const targetPartNo = dataObj.part_no;
      const custIndex = sortedData.findIndex((data) => data.customer_code === targetCustomer);
      if (custIndex === -1) {
        sortedData.push({
          customer_code: targetCustomer,
          partGroup: [{ part_no: dataObj.part_no, crates: [dataObj] }],
        });
      } else {
        const targetPartGroup = sortedData[custIndex].partGroup;
        const partIndex = targetPartGroup.findIndex((data) => data.part_no === targetPartNo);
        if (partIndex === -1) {
          targetPartGroup.push({ part_no: dataObj.part_no, crates: [dataObj] });
        } else {
          targetPartGroup[partIndex].crates.push(dataObj);
        }
      }
    });
    // --- Calculate total group by customer ---
    sortedData.forEach((dataObj) => {
      let total_qty = 0;
      let total_gross_wt = 0;
      let total_cont_wt = 0;

      dataObj.partGroup.forEach((partgrp) => {
        let subtotal_qty = 0;
        let subtotal_gross_wt = 0;
        let subtotal_cont_wt = 0;
        partgrp.crates.forEach((crate) => {
          subtotal_qty += crate.total_quantity;
          subtotal_gross_wt += crate.total_gross_weight;
          subtotal_cont_wt += crate.total_contained_weight;
        });
        partgrp.subtotal_qty = subtotal_qty;
        partgrp.subtotal_gross_wt = subtotal_gross_wt;
        partgrp.subtotal_cont_wt = subtotal_cont_wt;

        total_qty += subtotal_qty;
        total_gross_wt += subtotal_gross_wt;
        total_cont_wt += subtotal_cont_wt;
      });

      dataObj.cust_total_quantity = total_qty;
      dataObj.cust_total_gross_weight = total_gross_wt;
      dataObj.cust_total_contained_weight = total_cont_wt;
      dataObj.company_total_weight =        total_gross_wt > total_cont_wt ? total_gross_wt : total_cont_wt;
    });
console.log("data from finished",sortedData);
    setData(sortedData);
  }, [dataset]);

  return (
    <div className="printme report-fingoods" ref={printRef} style={{ width: '8.5in' }}>
      <style type="text/css" media="print">
        {`
      .printme { border: none;}
      .pagebreak {
        page-break-before: auto;
      }
    `}
      </style>
      <div className="fingoods-wrapper">
        {/* HEADER */}
        <div className="fingoods-body">
          {/* <Row style={{ alignItems: "center" }}>
            <Col span={6}>
              <h6>{moment().format("MM/DD/YY HH:mm:ss")}</h6>
            </Col>
            <Col span={12} className="text-center">
              <h4>Finished Goods In Warehouse</h4>
              <p>(FGINV1.FRX)</p>
            </Col>
            <Col span={6} className="text-end">
            </Col>
          </Row> */}
          <table>
            <thead>
              <tr className="fingoods-header">
                {/* <th colSpan={2} className="text-start">
                  {moment().format("MM/DD/YY HH:mm:ss")}
                </th> */}
                <th colSpan="100%">
                  <h4>Finished Goods In Warehouse</h4>
                  {/* <p>(FGINV1.FRX)</p> */}
                </th>
                {/* <th colSpan={2} className="text-end">
                  <span id="PageNumber" />
                </th> */}
              </tr>
              <tr>
                <th colSpan="100%" className="customer-subheader">
                  Customer
                </th>
              </tr>
              <tr>
                <th>Ticket</th>
                <th>DueDate</th>
                <th>Date</th>
                <th>PO#</th>
                <th>Part Numb</th>
                <th>Job_num</th>
                <th>Quant</th>
                <th>Gross_wt</th>
                {/* <th>Cont_wt</th> */}
              </tr>
            </thead>
            <tbody>
              {data.map((obj) => (
                <React.Fragment key={obj.customer_code}>
                  <tr>
                    <td colSpan="100%" className="customer-subheader">
                      {obj.customer_code}
                    </td>
                  </tr>
                  {obj.partGroup.map((partgrp) => (
                    <React.Fragment key={`${obj.customer_code}-${partgrp.part_no}`}>
                      {partgrp.crates.map((crate) => (
                        <>
                        {crate.items.map((item,index) => (
                        <tr key={`${obj.customer_code}-${crate.id}-${item.crate_item_id}`}>
                          <td>{index>0?"":crate.id}</td>
                          <td>
                            <b>{index>0?"":crate.due_date}</b>
                          </td>
                          <td>{index>0?"":crate.created_date}</td>
                          <td>{item.external_document_no}</td>
                          <td>
                            <b>{item.part_no}</b>
                          </td>
                          <td>{item.order_no}</td>
                          <td className="text-end">{item.quantity}</td>
                          <td className="text-end">{index>0?"":crate.total_gross_weight}</td>
                        </tr>
                      )
                      )}
                      </>
                      ))}
                      <tr className="subtotal">
                        <td colSpan={5} />
                        <td className="text-end">Totals:</td>
                        <td className="text-end">{partgrp.subtotal_qty}</td>
                        <td className="text-end">{partgrp.subtotal_gross_wt}</td>
                        {/* <td>{partgrp.subtotal_cont_wt}</td> */}
                      </tr>
                    </React.Fragment>
                  ))}
                  <tr className="total">
                    <td colSpan={2} />
                    <td colSpan={2} className="text-end">
                      Company Total:
                    </td>
                    <td colSpan={3}>{obj.company_total_weight} lbs.</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default FinishedGoodsReport;
