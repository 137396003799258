import axiosInstance from '../../../../../general/axiosInstance';
import ZebraBrowserPrintWrapper from 'zebra-browser-print-wrapper';
import { CONST_API_URL } from '../../../../../appconfig';

const url = CONST_API_URL;

export async function printLabelService(zpl) {
    try {
        const browserPrint = new ZebraBrowserPrintWrapper();
        const defaultPrinter = await browserPrint.getDefaultPrinter();
        browserPrint.setPrinter(defaultPrinter);

        const printerStatus = await browserPrint.checkPrinterStatus();
        if (printerStatus.isReadyToPrint) {
            browserPrint.print(zpl);
        } else {
            console.log('Error/s', printerStatus.errors);
        }
    } catch (error) {
        throw new Error(error);
    }
}

export function getLabelService(data) {
    return axiosInstance
        .post(`${url}/webservice/getLabel`, { data })
        .then(({ data: zpl }) => {
            return zpl;
        })
        .catch((error) => console.log(`GetLabelService Client: ${error}`));
}
