import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getManufacturingOrderService } from './create-crate-table.services';
import { TABLE_PROPERTIES, TABLE_FILTERS } from './create-crate-table.config';
import { BoxOfGoodsFilled } from '../../assets/icons/custom-antd/BoxOfGoodsFilled';
import CreateCrateModal from './subcomponents/create-crate-modal/create-crate-modal.component';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { notification } from 'antd';
import ProgressDiagramFlexCollection from './subcomponents/progress-diagram/progressDiagramFlexCollection.component';
import QuantityProgress from './subcomponents/quantity-progress/quantity-progress.component';
import './create-crate-table.scss';
import { isEqualAny } from '../../functions/isEqualAny';
import Countdown from '../../components/countdown/countdown.component';
import _ from 'lodash';

const defaultState = {
    products: undefined,
    selectedProduct: [],

    // Table Related
    tableLoading: true,
    tableRows: 6,
    filters: _.cloneDeep(TABLE_FILTERS),
    customerLock: undefined, // Autofilters all to the same customer once one is selected

    // Modals
    showCreateModal: false,
};

class CreateCrateTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = _.cloneDeep(defaultState);
    }

    componentDidMount() {
        if (this.props.division !== '') {
            this.loadData();
            window.addEventListener('resize', this.adjustTableRows);
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.division !== prevProps.division) {
            this.handleClearFilter();
            this.handleRefreshTable();
            window.addEventListener('resize', this.adjustTableRows);
        }
    }

    adjustTableRows = () => {
        const rowHeight = 94;
        const { height: tableHeight } = document
            .querySelector('.p-datatable-table')
            .getBoundingClientRect();
        const { height: theadHeight } = document
            .querySelector('.p-datatable-thead')
            .getBoundingClientRect();

        const tableRows = Math.floor((tableHeight - theadHeight - 10) / rowHeight);
        this.setState({ tableRows });
    };

    loadData = async (update) => {
        try {
            this.setState({ tableLoading: true });
            const products = await getManufacturingOrderService(this.props.division);
            this.setState({ ...update, products });
        } catch (error) {
            console.log('ERROR - loadData', error);
        } finally {
            this.setState({ tableLoading: false });
        }
    };

    render() {
        const { products, tableRows, filters, tableLoading, selectedProduct, showCreateModal } =
            this.state;
        return (
            <div className="create-crate-table-component">
                <DataTable
                    size="large"
                    value={this.renderProducts(products)}
                    removableSort
                    header={this.renderHeader(filters['global']?.value || '')}
                    responsiveLayout="scroll"
                    paginator
                    columnKey="table_id"
                    dataKey="table_id"
                    rows={tableRows}
                    rowHover
                    filters={filters}
                    filterDisplay="menu"
                    globalFilterFields={[
                        'mo',
                        'customer_code',
                        'so',
                        'quantity_available',
                        'part_no',
                    ]}
                    loading={tableLoading}
                    emptyMessage={
                        this.props.division ? (
                            'No records found.'
                        ) : (
                            <b>Please Select a Location First!</b>
                        )
                    }
                    scrollHeight="flex"
                    scrollable
                    reorderableColumns
                    selection={selectedProduct}
                    onFilter={({ filters }) => this.setState({ filters })}
                    onSelectionChange={this.handleSelectionChange}
                    isDataSelectable={this.isRowSelectable}
                    scrollDirection="both"
                >
                    <Column selectionMode="multiple" />
                    {this.renderColumns()}
                </DataTable>
                <CreateCrateModal
                    visible={showCreateModal}
                    selection={selectedProduct}
                    onCancel={this.handleLeaveCrate}
                    onPrint={this.handleLeaveCrate}
                    mode={'PRODUCTION'}
                />
            </div>
        );
    }

    renderPrintNotifications = (status) => {
        console.log(status);

        if (status) {
            const message = status === 'success' ? 'Print Sucessful!' : 'Print Failed!';
            notification[status]({ message });
        }
    };

    renderProducts = (products) => {
        return !products ? null : products;
    };

    renderColumns = () => {
        const COLUMNS = TABLE_PROPERTIES.map((column) => (
            <Column
                key={column.dataIndex}
                field={column.dataIndex}
                style={{ minWidth: column.minWidth }}
                header={column.name}
                filter
                showFilterOperator={false}
                showAddButton={false}
                sortable
            />
        ));
        COLUMNS.push(
            <Column
                key="progress"
                header="Progress"
                style={{ minWidth: '15rem' }}
                headerClassName="progressHeader"
                body={(rowData) => <QuantityProgress data={rowData} />}
            />
        );
        COLUMNS.push(
            <Column
                key="box-status"
                header="Box Status"
                style={{ minWidth: '30%' }}
                headerClassName="boxStatusHeader"
                body={(rowData) => (
                    <ProgressDiagramFlexCollection
                        numbers={rowData.crates}
                        colors={['red', 'green', 'yellow']}
                        id={rowData.table_id}
                    />
                )}
            />
        );
        return COLUMNS;
    };

    renderHeader = (globalFilter) => {
        return (
            <div className="production-header d-flex justify-content-between align-items-center">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h2 className="table-name">Production Table</h2>
                    <Countdown
                        duration={{ minutes: 5 }}
                        onCountdown={async () => await this.loadData()}
                        render={({ clockDisplay, seconds, minutes, hours }) =>
                            seconds === 0 && minutes === 0 && hours === 0
                                ? 'Refreshing...'
                                : `Auto-refresh in ${clockDisplay}`
                        }
                    />
                </div>
                <span className="p-input-icon-left ms-auto">
                    <i className="pi pi-search" />
                    <InputText
                        value={globalFilter}
                        onChange={this.handleGlobalFilterChange}
                        placeholder="Global Search"
                    />
                </span>
                <Button
                    onClick={this.handleClearFilter}
                    onTouchEnd={this.handleClearFilter}
                    icon="pi pi-filter-slash"
                    label="clear"
                    className="p-button-lg secondary-btn"
                    type="button"
                />
                <Button
                    onClick={this.handleRefreshTable}
                    onTouchEnd={this.handleRefreshTable}
                    icon="pi pi-refresh"
                    label="refresh"
                    className="p-button-lg secondary-btn"
                    type="button"
                />
                <Button
                    onClick={() => this.setState({ showCreateModal: true })}
                    onTouchEnd={() => this.setState({ showCreateModal: true })}
                    disabled={this.state.selectedProduct.length === 0}
                    className="p-button-lg create-button"
                >
                    <BoxOfGoodsFilled className="button-icon" />
                    <span className="button-text">Create</span>
                </Button>
            </div>
        );
    };

    isRowSelectable = (row) => {
        const { customerLock } = this.state;
        const unacceptedValues = ['', undefined, null];
        if (
            isEqualAny(row.data.customer_code, unacceptedValues) ||
            row.data.is_sales_order_valid === false
        ) {
            return false;
        }
        if (customerLock !== undefined) {
            return row.data.customer_code === customerLock;
        }
        return true;
    };

    handleGlobalFilterChange = (e) => {
        const { filters } = this.state;
        const value = e.target.value;
        filters['global'] = { value, matchMode: FilterMatchMode.CONTAINS };
        this.setState({ filters });
    };

    handleRefreshTable = (e) => {
        this.loadData();
        this.setState(_.cloneDeep(defaultState));
        e?.preventDefault();
    };

    handleClearFilter = (e) => {
        this.setState({ filters: _.cloneDeep(TABLE_FILTERS) });
        e?.preventDefault();
    };

    handleSelectionChange = ({ value: selectedProducts }) => {
        const productCount = selectedProducts.length;
        if (productCount !== 0) {
            const { filters } = this.state;
            const customer = selectedProducts[0]['customer_code'];

            this.setState({
                customerLock: customer,
                filters: {
                    ...filters,
                    customer_code: { value: customer, matchMode: FilterMatchMode.EQUALS },
                },
                selectedProduct: selectedProducts,
            });
        } else {
            this.setState({
                customerLock: undefined,
                selectedProduct: selectedProducts,
            });
        }
    };

    handleLeaveCrate = (boxedProducts, status = undefined) => {
        if (boxedProducts !== undefined) {
            this.setState({ showCreateModal: false });
            this.loadData({
                selectedProduct: [],
                customerLock: undefined,
            });
            this.renderPrintNotifications(status);
        } else {
            this.setState({ showCreateModal: false });
        }
    };
}

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(CreateCrateTable));
