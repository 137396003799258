import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export async function getNextPackingListReportNo() {
    try {
        const result = await axiosInstance.get(
            `${url}/report/billoflading/packinglistnextreportno`
        );

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export async function insertPackingListReportData(data) {
    try {
        const result = await axiosInstance.post(`${url}/report/packinglistreport`, data);

        if (result.data) {
            return result.data;
        }
        return null;
    } catch (error) {
        console.log(error);
        return null;
    }
}
