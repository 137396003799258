import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Input, Form, Space } from 'antd';

const validateMessages = {
	required: 'Required!',	
	types: { email: 'Not a valid email format!' }
};

/* IMPORTANT PROPS
	onFinish = onFinish method of the form, 
	btnLoading = handles the button loading status
*/
class LoginForm extends React.Component {
	form = React.createRef();

	componentDidUpdate(prevProps) {
		this.resetInputFields(prevProps);
	}

	render() {
		const { btnLoading, history } = this.props;
		return (
			<Form ref={ this.form }
				layout="vertical"
				validateMessages={ validateMessages }
				requiredMark={ false }
				onFinish={ this.props.onFinish }
			>
				<Form.Item hasFeedback label="Email" name="email" rules={[{ required: true, type: 'email' }]}>
					<Input placeholder="Please enter your email address" autoComplete="username" />
				</Form.Item>
				<Form.Item label="Password" name="password" rules={[{ required: true }]}>
					<Input.Password visibilityToggle={false} placeholder="Please enter your password" autoComplete="current-password" />
				</Form.Item>
				<Form.Item className="space-up">
					<Space className="button-group">
						<Button htmlType="submit" className="primary-btn" loading={ btnLoading }>
							Log in
						</Button>
						<Button htmlType="button" className="borderless-btn" onClick={() => history.push('/request-reset')}>
							Forgot Password?
						</Button>
					</Space>
				</Form.Item>
			</Form>
		);
	}

	resetInputFields = (prev, curr = this.props) => {
		if (prev.btnLoading && !curr.btnLoading) {
			this.form.current.resetFields();
		}
	}
}

export default withRouter(LoginForm);
