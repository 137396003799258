import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function insertFreightBillTo(data) {
    return axiosInstance
        .post(`${url}/report/insertFreightBillTo`, { data })
        .catch((error) => console.log(`insertFreightBillTo Client: ${error}`));
}

export function updateFreightBillTo(data) {
    return axiosInstance
        .put(`${url}/report/updateFreightBillTo`, { data })
        .catch((error) => console.log(`updateFreightBillTo Client: ${error}`));
}

export function updateCrateStatusService(data) {
    return axiosInstance
        .put(`${url}/item/updateCrateStatus`, { data })
        .catch((error) => console.log(`updateCrateStatusService Client: ${error}`));
}

export async function updateSalesOrderLineService(data) {
    return await axiosInstance.post(`${url}/nav/updateSaleOrderLine`, { data }).catch((error) => {
        console.log(`updateSalesOrderLineService Client: ${error}`);
        return error.response.data;
    });
}

export async function updateSalesOrderLineConfirmed(data) {
    return await axiosInstance
        .post(`${url}/nav/updateSaleOrderLineConfirmed`, { data })
        .catch((error) => {
            console.log(`updateSalesOrderLineConfirmed Client: ${error}`);
            return error.response.data;
        });
}

export async function checkCrateValidity(data) {
    return await axiosInstance
        .post(`${url}/webservice/checkCrateValidity`, { data })
        .catch((error) => {
            console.log(`checkCrateValidity Client: ${error}`);
            return error.response.data;
        });
}
