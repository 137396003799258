import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Form, Input } from 'antd';
import { useState, useRef } from 'react';
import { Toast } from 'primereact/toast';

import { insertCarrierTo } from './edit-carrier.service';

const AddCarrierDialog = (props) => {
  const { header, visible, onHide, onFinish } = props;
  const [submitLoading, setSubmitLoading] = useState(false);

  const toast = useRef(null);

  const onFinishAddCarrier = async (value) => {
    setSubmitLoading(true);

    await insertCarrierTo({
      name: 'bol_carrier',
      label: value.Name,
      value: value.Name
    }).then((res) => {
      if (res === null) {
        toast.current.show({
          severity: 'error',
          summary: 'Cannot Add',
          detail: 'A record with that name already exists.'
        });
      }
    });
    setSubmitLoading(false);
    onFinish(value);
  };
  return (
    <>
      <Toast ref={toast} />{' '}
      <Dialog
        header={header || 'Add a Carrier/Driver'}
        visible={visible}
        style={{ width: '50vw' }}
        onHide={onHide}
      >
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={onFinishAddCarrier}
          autoComplete="off"
        >
          <Form.Item
            label="Name"
            name="Name"
            rules={[{ required: true, message: 'Please input the name!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit" loading={submitLoading}>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Dialog>
    </>
  );
};

export default AddCarrierDialog;
