import Icon from '@ant-design/icons';

export const BoxOfGoodsFilled = props => <Icon component={BoxOfGoodsSvg} {...props} />;

const BoxOfGoodsSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 200 200">
        <path d="M188.77,44.73c-29.54-10.64-59.09-21.24-88.59-31.99c-3.22-1.17-5.96-1.1-9.12,0.16
            C64.24,23.59,37.36,34.13,10.5,44.72c-0.04,0.26-0.02,0.51,0.05,0.77c-0.05,1.73-0.14,3.46-0.15,5.19
            c-0.05,16.66-0.08,33.33-0.12,49.99c0,6.13-0.01,12.27-0.01,18.4c0.14,6.13,0.28,12.25,0.42,18.38c-0.13,0.08-0.27,0.08-0.4,0
            c0.9,0.55,1.76,1.17,2.7,1.63c32.14,15.77,64.3,31.48,96.4,47.33c2.99,1.48,5.16,1.4,7.96-0.49c23.75-16,47.6-31.84,71.42-47.73
            C188.77,107.04,188.77,75.88,188.77,44.73z M113.83,175.01c-27.19-13.27-60.88-31.65-88.05-44.96c-0.83-0.41-1.59-0.95-2.38-1.43
            c0,0,0,0,0,0c0,0,0,0,0,0c0.02-3.06,0.06-6.13,0.06-9.19c0.05-21.71,0.1-43.41,0.15-65.12c12.92,4.91,25.87,9.76,38.76,14.74
            c13.28,5.13,46.92,18.23,46.92,18.23c0.1,0.04,0.2,0.08,0.3,0.12c0.13,0.05,0.24,0.1,0.36,0.15l3.88,1.53v82.97 M118.56,82.53
            c-1.16,0.61-2.05,0.09-3.01-0.29C88.63,71.83,61.73,61.41,34.82,50.98c-0.03-0.35-0.06-0.71-0.09-1.06
            c7.41-2.84,14.83-5.67,22.23-8.54c1.25-0.48,2.38-0.07,3.47,0.36c25.45,9.8,50.89,19.63,76.32,29.46c0.33,0.13,0.58,0.46,1.41,1.15
            C131.41,75.85,124.97,79.16,118.56,82.53z M155.85,62.96c-1.06,0.56-2.07,0.08-3.06-0.31c-24.3-9.48-48.6-18.95-72.9-28.43
            c-0.47-0.18-0.9-0.5-2.09-1.17c5.83-2.35,11.01-4.41,16.18-6.52c1.32-0.54,2.55-0.42,3.87,0.06c23.74,8.66,47.49,17.3,71.24,25.95
            c0.95,0.35,1.84,0.85,3.4,1.57C166.58,57.26,161.22,60.12,155.85,62.96z"/>
        <g>
            <path d="M93.31,88.96c-1.51,1.45-2.92,2.75-4.28,4.13c-8.28,8.4-14.65,18.81-19.2,31.13
                c-0.34,0.93-0.79,1.61-1.77,1.41c-1.02-0.21-1.57-1.11-2-2.25c-3.38-9.01-8.4-16.47-15.15-22.28c-0.16-0.14-0.32-0.29-0.48-0.43
                c-0.02-0.02-0.03-0.06-0.11-0.22c1.55-0.18,3.06-0.38,4.59-0.52c0.22-0.02,0.5,0.19,0.75,0.34c4.35,2.59,8.28,5.98,11.56,10.87
                c0.49-0.7,0.97-1.39,1.45-2.07c4.04-5.75,8.27-11.27,13.33-15.71c2.07-1.81,4.32-3.35,6.51-4.99c0.22-0.17,0.53-0.28,0.81-0.23
                C90.59,88.37,91.87,88.66,93.31,88.96z"/>
            <path d="M84.64,103.36c1.27,3.37,2.07,6.97,2.25,10.61c0.71,14.16-8.11,23.72-19.66,21.3s-21.52-15.91-22.22-30.07
                s8.11-23.72,19.66-21.3c4.96,1.04,9.62,4.12,13.39,8.41c0.64-0.62,1.3-1.23,1.97-1.82c-4.33-5.09-9.75-8.75-15.52-9.95
                c-13.06-2.73-23.03,8.07-22.23,24.09c0.8,16.02,12.08,31.27,25.13,34c13.06,2.73,23.03-8.07,22.23-24.09
                c-0.23-4.67-1.36-9.28-3.16-13.52C85.85,101.78,85.24,102.56,84.64,103.36z"/>
        </g>
    </svg>
);

