import { Input } from "antd";
import React from "react";
import InputWrapper from "../input-wrapper/input-wrapper.component";

class WrappedInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTitle: false
        }
    }

    render() {
        const { title, placeholder, value, disabled } = this.props;
        return (
            <InputWrapper className="wrapped-element" title={ title } showTitle={ this.setTitleCondition(value) } disabled={ disabled } >
                <Input placeholder={ placeholder } onChange={ this.handleChange } value={ value } disabled={ disabled }/>
            </InputWrapper>
        );
    }

    handleChange = ({target}) => {
        this.props.onChange?.(target.value);
    }

    setTitleCondition = (value) => {
        return (value !== undefined && value !== '' && value !== null);
    }
}

WrappedInput.defaultProps = {
    disabled: false
}

export default WrappedInput;