import axiosInstance from '../../general/axiosInstance';
import { CONST_API_URL } from '../../appconfig';
const url = CONST_API_URL;

export function SalesShipmentService(division) {
    return axiosInstance.get(`${url}/nav/postedsalesshipments`, { params: { division: division } }).then(({ data: result }) => {
        return [...(result || [])].map((d) => {
            d.Shipment_Date = convertDateSting(d.Shipment_Date);
            d.Posting_Date = convertDateSting(d.Posting_Date);
            return d;
        });
    });
}

function convertDateSting(date) {
    let tempDate = date.split('-');
    return tempDate[1] + '/' + tempDate[2] + '/' + tempDate[0];
}

export function SalesShipmentLinesService(No) {
    return axiosInstance
        .get(`${url}/nav/postedsalesshipmentLines`, { params: { No: No } })
        .then(({ data: result }) => {
            return result;
        });
}
