import React from 'react';
import { Modal } from 'antd';
import CreateCrate from './create-crate-input/create-crate-input.component';
import PrintCrateLabel from './print-crate-label/print-crate-label.component';
import './create-crate-modal.styles.css';

const initialState = {
    step: 0,
    crateData: undefined,
    cancelForm: false,
};

class CreateCrateModal extends React.Component {
    constructor(props) {
        console.log('in create crate');
        super(props);
        this.state = initialState;
    }

    renderContent = () => {
        const { selection } = this.props;
        const { step, cancelForm, crateData } = this.state;
        if (step === 0) {
            return (
                <CreateCrate
                    data={!crateData ? selection : crateData}
                    cancelForm={cancelForm}
                    cancelFinish={this.handleCancelFinish}
                    onFinish={this.handleFinish}
                    mode={this.props.mode}
                />
            );
        } else if (step === 1) {
            return (
                <PrintCrateLabel
                    data={crateData}
                    onBack={this.handleBack}
                    onPrint={this.handlePrint}
                />
            );
        }
    };

    render() {
        const { visible } = this.props;
        return (
            <Modal
                className="create-crate-modal"
                visible={visible}
                footer={false}
                width={1400}
                onCancel={this.handleCancel}
                getContainer={false}
            >
                {this.renderContent()}
            </Modal>
        );
    }

    handleBack = () => {
        this.setState({ step: 0 });
    };

    handleCancelFinish = () => {
        this.setState({ cancelForm: false });
    };

    handlePrint = (status) => {
        const { crateData } = this.state;

        this.props.onPrint(crateData, status);
        this.setState({ cancelForm: true, crateData: undefined, step: 0 });
    };

    handleCancel = () => {
        const { crateData } = this.state;

        this.props.onCancel(crateData);
        this.setState({ cancelForm: true, crateData: undefined, step: 0 });
    };

    handleFinish = (crateData) => {
        this.setState({ step: 1, crateData });
    };
}

export default CreateCrateModal;
