import { DateTime } from "luxon";

export function getRemainingSeconds(totalDuration, elapsedDuration) {
    const remaining = Math.round((totalDuration - elapsedDuration)/1000);
    return (remaining > 0) ? remaining : 0;
}

export function getCountdownParameters(totalSeconds) {
    const hours = Math.floor(totalSeconds % 86400 / 3600);
    const minutes = Math.floor(totalSeconds % 3600 / 60);
    const seconds = Math.floor(totalSeconds % 60);
    return [ hours, minutes, seconds ];
}

export function getCurrentDateTime() {
    return DateTime.now();
}

export function getTimeElapsed(startDateTime, currentDateTime) {
    if (!startDateTime) {
        return null;
    }
    const difference = currentDateTime.diff(startDateTime, ['milliseconds']);
    return difference.values.milliseconds;
}

export function getIntervalDuration(interval) {
    let intervalLength = 0;
    if (interval.hasOwnProperty("hours")) {
        intervalLength += interval.hours * 3600;
    }
    if (interval.hasOwnProperty("minutes")) {
        intervalLength += interval.minutes * 60;
    }
    if (interval.hasOwnProperty("seconds")) {
        intervalLength += interval.seconds;
    }
    return (intervalLength === 0) ? 60000 : intervalLength * 1000;
}

export function toClockDisplay(hours, minutes, seconds) {
    return (hours === 0) ? (
        `${minutes}:${toClockDigits(seconds)}`
    ) : (
        `${hours}:${toClockDigits(minutes)}:${toClockDigits(seconds)}`
    );
}

export function toClockDigits(number) {
    return (number.toString().length === 1) ? `0` + number : number;
}

