import axiosInstance from '../../../general/axiosInstance';
import { CONST_API_URL } from '../../../appconfig';

const url = CONST_API_URL;

export function insertFreightBillTo(data) {
    return axiosInstance
        .post(`${url}/report/insertFreightBillTo`, { data })
        .catch((error) => console.log(`insertFreightBillTo Client: ${error}`));
}

export function updateFreightBillTo(data) {
    return axiosInstance
        .put(`${url}/report/updateFreightBillTo`, { data })
        .catch((error) => console.log(`updateFreightBillTo Client: ${error}`));
}

export function updateCrateStatusService(data) {
    return axiosInstance
        .put(`${url}/item/updateCrateStatus`, { data })
        .catch((error) => console.log(`updateCrateStatusService Client: ${error}`));
}
