import React, { useEffect, useState, useRef } from 'react';
import { Steps, Form, Row, Col, Input } from 'antd';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from 'moment';
import DelayedExceededIcon from '../../assets/icons/Delayed-Exceeded.svg';

import './shipcratedialog.styles.css';
import BillOfLadingReport from '../report/billoflading.report.component';
import PackingList from '../report/packinglist.report.component';
import BarcodeScanner from '../barcode-scanner/barcodescanner.component';
import { getCratesAndCrateItems } from '../../pages/inventory/inventory.services';

const { Step } = Steps;

const mock_data = [];
for (let i = 0; i < 20; i++) {
    const newMockObj = {
        id: i,
        customer_code: `Company ${String.fromCharCode('A'.charCodeAt(0) + i)}`,
        order_no: `00000-${i}`,
        gross_weight: 7100 + i,
        contained_weight: 7000 + i,
        quantity: 1000 + i,
        location: `F${i}`,
        due_date: moment(new Date().setDate(new Date().getDate() + i)).format('YYYY-MM-DD'),
    };
    mock_data.push(newMockObj);
}

function ShipCrateDialog(props) {
    const [currentStep, setCurrentStep] = useState(0);
    const [crateData, setCrateData] = useState([]);
    const [tableData, setTableData] = useState(mock_data);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [totalGrossWeight, setTotalGrossWeight] = useState(0);
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [overWeightWarningVisiable, setOverWeightWarningVisible] = useState(false);
    const [form] = Form.useForm();

    const [customerList, setCustomerList] = useState([]);
    const columns = [
        { field: 'id', header: 'Crate ID' },
        { field: 'part_no', header: 'Part No' },
        { field: 'customer_code', header: 'Customer' },
        { field: 'mo_no', header: 'MO#' },
        { field: 'customer_po_no', header: 'Customer PO#' },
        { field: 'location', header: 'Storage Location' },
        { field: 'total_quantity', header: 'Quantity' },
        { field: 'total_gross_weight', header: 'Gross Weight (lbs)' },
        // { field: "total_contained_weight", header: "Contained Weight (lbs)" },
    ];

    const resetStates = () => {
        setCurrentStep(0);
        setCrateData([]);
        setTableData([]);
        setTableLoading(false);
        setSelectedCustomer(null);
        setSelectedRecords([]);
        setTotalGrossWeight(0);
        setTotalQuantity(0);
        setOverWeightWarningVisible(false);
        form.resetFields();
    };

    useEffect(() => {
        async function fetchData() {
            console.log('in ship crate', props.division);
            console.log('going in getCratesAndCrateItems');
            const structuredCrates = await getCratesAndCrateItems(props.division);
            if (structuredCrates) {
                // --- Fetch Crates ---
                setCrateData(structuredCrates);
                // console.log('structuredCrates', structuredCrates);

                // --- Fetch Customers ---
                const customers = [];
                structuredCrates.forEach((crate) => {
                    crate.mo_no = crate.items
                        .map((u) => u.order_no)
                        .filter(Boolean)
                        .join(', ');
                    crate.customer_po_no = crate.items
                        .map((u) => u.external_document_no)
                        .filter(Boolean)
                        .join(', ');
                    if (!customers.includes(crate.customer_code)) {
                        customers.push(crate.customer_code);
                    }
                });
                setCustomerList(customers);
            }
        }
        fetchData();
    }, [props.visible, props.division]);

    useEffect(() => {
        const filteredData = crateData.filter((crate) => crate.customer_code === selectedCustomer);
        // const partNo =  new Set();
        // for(let i = 0; i < filteredData.length; i++){
        //   for(let j = 0; j < filteredData[i].items.length; j++){
        //     partNo.add(filteredData[i].items[j].part)
        //   }
        // }
        setTableData(filteredData);
        setSelectedRecords([]);
    }, [selectedCustomer]);

    useEffect(() => {
        let total_weight = 0;
        let total_qty = 0;
        selectedRecords.forEach((obj) => {
            total_weight += obj.total_gross_weight;
            total_qty += obj.total_quantity;
        });
        setTotalGrossWeight(total_weight);
        setTotalQuantity(total_qty);

        const truckCapacityWeight = Number(form.getFieldValue('truck_capacity'));
        setOverWeightWarningVisible(truckCapacityWeight < total_weight);
    }, [selectedRecords]);

    const onFinish = (values) => {
        //console.log('onFinish values:', values);
        setCurrentStep(currentStep + 1);
    };

    const handleOnSelectionChange = (e) => {
        setSelectedRecords(e.value);
    };

    const handleFinish = () => {
        props.setVisible(false);
        resetStates();
    };

    const checkNextButtonDisabled = () => {
        if (currentStep === 1 && selectedRecords.length === 0) {
            return true;
        } else {
            return false;
        }
    };

    const renderHeader = (
        <div>
            <h1>Ship Crate</h1>
        </div>
    );

    const breadcrumb = (
        <div style={{ padding: '2rem' }}>
            <Steps current={currentStep}>
                <Step title="INPUT WEIGHT" />
                <Step title="CRATES TO SHIP" />
                <Step title="BILL OF LADING" />
                <Step title="PACKING LIST REPORT" />
            </Steps>
        </div>
    );

    const renderColumns = columns.map((col, i) => (
        <Column key={col.field} field={col.field} header={col.header} sortable />
    ));

    const renderContents = (step) => {
        switch (step) {
            case 0: // INPUT WEIGHT
                return (
                    <div style={{ padding: '0 2rem' }}>
                        <Form form={form} id="shipcrateform" layout="vertical" onFinish={onFinish}>
                            <Form.Item
                                label="Customer Code"
                                name="customer_code"
                                rules={[{ required: true }]}
                            >
                                <Dropdown
                                    options={customerList}
                                    placeholder="Select Company Name"
                                    style={{ minWidth: '300px' }}
                                    onChange={(e) => setSelectedCustomer(e.value)}
                                />
                            </Form.Item>
                            <Form.Item
                                label="Truck Capacity (lbs)"
                                name="truck_capacity"
                                rules={[
                                    { required: true },
                                    {
                                        validator: (_, value) =>
                                            Number.isNaN(Number(value))
                                                ? Promise.reject(new Error('Enter number only'))
                                                : Promise.resolve(),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Enter Truck Capacity (lbs)"
                                    autoComplete="off"
                                    style={{ minWidth: '300px', width: 'fit-content' }}
                                    size="large"
                                />
                            </Form.Item>
                            <Row style={{ justifyContent: 'end', margin: '0.4rem 0' }}>
                                <Button
                                    className="p-button-lg"
                                    label="Next"
                                    type="submit"
                                    style={{
                                        backgroundColor: 'var(--haven-red)',
                                        borderColor: 'var(--haven-red)',
                                    }}
                                />
                            </Row>
                        </Form>
                    </div>
                );
            case 1: // CRATES TO SHIP
                const handleScan = (barcode) => {
                    //console.log('TEST:', barcode);
                    /*
					// 1. Search
					// 2. set DataTable Selection value (to have checkbox)
					// 3. setSelectedRecords([...selectedRecords, newRecord])
					*/
                };
                return (
                    <div style={{ padding: '0 0.4rem' }}>
                        <BarcodeScanner onScan={handleScan} />

                        <DataTable
                            size="small"
                            value={tableData}
                            dataKey="id"
                            responsiveLayout="scroll"
                            rowHover
                            loading={tableLoading}
                            emptyMessage="No records found."
                            selection={selectedRecords}
                            onSelectionChange={handleOnSelectionChange}
                        >
                            <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                            {renderColumns}
                        </DataTable>
                        <Row style={{ justifyContent: 'end', margin: '0.4rem 0' }}>
                            <h3 style={{ fontWeight: '700', marginRight: '2em' }}>
                                Total Gross Weight: {totalGrossWeight}
                            </h3>
                            {/* <h3 style={{ fontWeight: "700" }}>Total Quantity: {totalQuantity}</h3> */}
                            <div style={{ marginLeft: 'auto' }}>
                                <Button
                                    className="p-button-lg p-button-outlined"
                                    label="Back"
                                    onClick={() => setCurrentStep(currentStep - 1)}
                                    style={{
                                        borderColor: 'gray',
                                        color: 'gray',
                                        marginRight: '0.6rem',
                                    }}
                                />
                                <Button
                                    className="p-button-lg"
                                    label="Next"
                                    disabled={checkNextButtonDisabled()}
                                    onClick={() => setCurrentStep(currentStep + 1)}
                                    style={{
                                        backgroundColor: 'var(--haven-red)',
                                        borderColor: 'var(--haven-red)',
                                    }}
                                />
                            </div>
                        </Row>
                        {overWeightWarningVisiable && (
                            <Row>
                                <h3 style={{ fontWeight: '700' }}>
                                    <img
                                        src={DelayedExceededIcon}
                                        alt="exceeded"
                                        className="me-1"
                                        style={{ width: '1.4em' }}
                                    />
                                    You have exceeded this truck's capacity by{' '}
                                    {totalGrossWeight -
                                        Number(form.getFieldValue('truck_capacity'))}{' '}
                                    pounds
                                </h3>
                            </Row>
                        )}
                    </div>
                );
            case 2: //PACKING LIST REPORT
                return (
                    // padding: "0 2rem"
                    <div style={{}}>
                        <BillOfLadingReport
                            dataset={selectedRecords}
                            currentStep={currentStep}
                            setCurrentStep={(val) => setCurrentStep(val)}
                            type="add"
                        />
                    </div>
                );
            case 3: // PACKING LIST REPORT
                return (
                    // padding: "0 2rem"
                    <div style={{}}>
                        <PackingList
                            dataset={selectedRecords}
                            currentStep={currentStep}
                            setCurrentStep={(val) => setCurrentStep(val)}
                            onFinishPrint={handleFinish}
                            type="add"
                        />
                    </div>
                );
            default:
                return <h3 className="text-danger">Something went wrong (Unfound step:{step}) </h3>;
        }
    };

    return (
        <Dialog
            className="ship-crate-dialog"
            {...props}
            onHide={() => props.setVisible(false)}
            closable={currentStep <= 1}
            header={renderHeader}
            draggable={false}
            // style={{ minHeight: "80vh", minWidth: "60vw" }}
            style={{ height: '90vh', width: '100vw' }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0.4rem',
                }}
            >
                {breadcrumb}
                {renderContents(currentStep)}
            </div>
        </Dialog>
    );
}

export default ShipCrateDialog;
