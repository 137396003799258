import packageJson from '../../package.json';

const axios = require('axios');

const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
    (config) => {
        config.headers['Frontend-Version'] = packageJson.version;
        return config;
    },
    (error) => Promise.reject(error)
);

export default axiosInstance;
