import { Select } from 'antd';
import React from 'react';
import InputWrapper from '../input-wrapper/input-wrapper.component';

class WrappedSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTitle: false,
        };
    }

    render() {
        return (
            <InputWrapper
                className="wrapped-element"
                title={this.props.title}
                showTitle={this.state.showTitle}
            >
                <Select
                    showSearch
                    placeholder={this.props.placeholder}
                    onChange={this.handleChange}
                    loading={this.props.loading}
                >
                    {this.props.options}
                </Select>
            </InputWrapper>
        );
    }

    handleChange = (value) => {
        this.props.onChange?.(value);
        this.setState({ showTitle: value !== '' });
    };
}

export default WrappedSelect;
