import React from 'react';
import { Button, Navbar, Nav } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { store } from '../../redux/store';
import axios from 'axios';
import { CONST_API_URL } from '../../appconfig';
import { Avatar, Menu, Dropdown, Select } from 'antd';
import { DISCONNECT, UPDATE_DIVISION } from '../../redux/types';
import packageJson from '../../../package.json';

import logo from '../../assets/images/haven-white-logo.png';
import './header.styles.css';
import { getInitials } from '../../functions/getInitials';
import { DEV_MODE } from '../../appconfig';
const { Option } = Select;

const url = CONST_API_URL;

async function disconnectWithSSO(history) {
    store.dispatch({
        type: DISCONNECT,
    });
    history.push('/login');
}

function setAvatar(history, user) {
    if (!user) {
        return null;
    } else {
        const avatarMenuOverlay = (
            <Menu>
                <Menu.Item key="1">
                    <Button
                        style={{ fontSize: '3rem' }}
                        size="lg"
                        variant="danger"
                        onClick={() => disconnectWithSSO(history)}
                    >
                        Sign out
                    </Button>
                </Menu.Item>
            </Menu>
        );

        return (
            <Dropdown overlay={avatarMenuOverlay}>
                <Avatar size={50} className="header-avatar">
                    {user.hasOwnProperty('fullName') ? getInitials(user.fullName) : '--'}
                </Avatar>
            </Dropdown>
        );
    }
}

class Header extends React.Component {
    state = {
        selectedDivision: this.props.division ? this.props.division : 'Select a Division',
        divisionClicked: false,
        divisions: [],
    };

    componentDidMount() {
        const fetchDivisions = async () => {
            const result = await this.getDivisions();
            if (result) {
                this.setState({ divisions: result }, () => {
                    console.log(this.state.divisions);
                });
            }
        };
        fetchDivisions();
    }

    getDivisions = async () => {
        const response = await axios.get(url + '/nav/getDivisions');
        return response.data;
    };

    onSelectDivision = (selectedDivision) => {
        let user = this.props.user;
        this.setState(
            {
                selectedDivision,
                divisionClicked: false,
            },
            () => {
                console.log(this.state.selectedDivision);

                store.dispatch({
                    type: UPDATE_DIVISION,
                    division: this.state.selectedDivision,
                });
                // store.dispatch({
                //   type: DISCONNECT,
                // });
                // store.dispatch({
                //   user,
                //   type: CONNECT_WITH_SSO,
                // });
            }
        );
    };

    onClickStopProg = (e) => {
        e.stopPropagation();
    };

    onDivisionClick = () => {
        this.setState({ divisionClicked: true });
    };

    render() {
        const avatarSection = setAvatar(this.props.history, this.props.user);

        return (
            <Navbar expand="sm" style={{ backgroundColor: 'rgba(0,0,0,1)' }}>
                <Navbar.Brand href="/">
                    <img alt="" src={logo} width="300" className="d-inline-block align-top" />
                </Navbar.Brand>
                <Navbar.Collapse
                    id="responsive-navbar-nav"
                    style={{ textAlign: 'center', marginRight: 0 }}
                >
                    <Nav style={{ width: '100%', height: '100%', color: 'white', margin: 'auto' }}>
                        <div
                            onClick={this.onDivisionClick}
                            className="d-flex align-items-center DivisionSelect"
                            style={{
                                width: '100%',
                                alignContent: 'center',
                                alignSelf: 'center',
                                margin: 'auto',
                            }}
                        >
                            {this.props.user ? (
                                this.state.divisionClicked ? (
                                    <Select
                                        defaultOpen="true"
                                        autoFocus={true}
                                        listHeight={window.innerHeight * 0.7}
                                        placeholder="Select a Division"
                                        optionFilterProp="children"
                                        onClick={this.onClickStopProg}
                                        onSelect={this.onSelectDivision}
                                        value={this.props.division}
                                        size={'large'}
                                        style={{
                                            width: '100%',
                                            fontSize: '3rem',

                                            // height:"100%"
                                        }}
                                        // onSearch={this.onSearch}
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onDropdownVisibleChange={() =>
                                            this.setState({ divisionClicked: false })
                                        }
                                    >
                                        {this.state.divisions.map((item) => {
                                            return (
                                                <Option
                                                    style={{ fontSize: '2.6rem' }}
                                                    className="DivisionSelect"
                                                    key={item.division}
                                                    value={item.division}
                                                >
                                                    {item.division}
                                                </Option>
                                            );
                                        })}
                                    </Select>
                                ) : (
                                    <div
                                        style={{
                                            color: 'white',
                                            fontSize: '2.8rem',
                                            fontFamily: `Roboto, sans-serif`,
                                            background: '#d32f2f',
                                            padding: '0.25rem',
                                            borderRadius: '5px',
                                            width: '100%',
                                        }}
                                    >
                                        <p style={{ marginBottom: 0 }}>
                                            {this.state.selectedDivision}
                                        </p>
                                    </div>
                                )
                            ) : (
                                ''
                            )}{' '}
                        </div>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-content-end">
                    {DEV_MODE ? (
                        <div
                            style={{
                                border: '1px solid white',
                                padding: '0 1rem',
                                marginRight: '1rem',
                            }}
                        >
                            <p style={{ color: 'white', margin: 'auto' }}>DEV MODE</p>
                        </div>
                    ) : (
                        <div
                            style={{
                                padding: '0 1rem',
                                marginRight: '1rem',
                            }}
                        >
                            <p style={{ color: 'white', margin: 'auto' }}>V{packageJson.version}</p>
                        </div>
                    )}
                    {avatarSection}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
    division: state.division,
});

export default withRouter(connect(mapStateToProps)(Header));
