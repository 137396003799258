import React from "react";
import InputWrapper from "../input-wrapper/input-wrapper.component";
import InputNumPad from "../input-numpad/input-numpad.component";

class WrappedInputNumber extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showTitle: false
        }
    }

    render() {
        const { title, placeholder, value } = this.props;
        return (
            <InputWrapper className="wrapped-element" title={ title } showTitle={ this.setTitleCondition(value) }>
                {/* <InputNumber placeholder={ placeholder } onChange={ this.handleChange } controls={ false } value={ value }/> */}
                <InputNumPad placeholder={ placeholder } onChange={ this.handleChange } controls={ false } value={ value }/>
            </InputWrapper>
        );
    }
    
    handleChange = (value) => {
        this.props.onChange?.(value);
    }

    setTitleCondition = (value) => {
        return (value !== undefined && value !== '' && value !== null);
    }
}

export default WrappedInputNumber;