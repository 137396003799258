import axiosInstance from '../../general/axiosInstance';
import { notification } from 'antd';
import { store } from '../../redux/store';
import { CONNECT_WITH_SSO } from '../../redux/types';
import { CONST_API_URL } from '../../appconfig';

const url = CONST_API_URL;

export function LoginService(input) {
    return axiosInstance.post(`${url}/auth/login`, input).then(({ data: result }) => {
        if (result === 'Account not valid' || result === 'Wrong Email address or Password') {
            notification.error({
                message: 'Invalid Login Info',
                description: 'Your email or password is incorrect. \r\n Please try again',
            });
        } else if (result === 'Account just Disabled') {
            notification.error({
                message: 'Too Many Login Attempts',
                description:
                    'Exceeded the number of login attempts! \r\n This Account will be disabled for next 24 hours',
            });
        } else if (result === 'Account Disabled') {
            notification.error({
                message: 'Account Disabled',
                description: 'This account has been disabled and is currently not accessible',
            });
        } else {
            const today = new Date();
            const expirydate = new Date(new Date(today).setHours(today.getHours() + 12));
            localStorage.setItem('user', expirydate);
            store.dispatch({
                type: CONNECT_WITH_SSO,
                user: result,
            });
            return true;
        }
        return false;
    });
}

export function RegisterService(input) {
    return axiosInstance.post(`${url}/auth/registernewuser`, input).then(({ data: result }) => {
        if (result === 'Invaild Invitation Code') {
            notification.error({
                message: 'Invaild Code',
                description: 'Your invitation code is invalid. Please try again',
            });
        } else if (result === 'Email is already registered') {
            notification.error({
                message: 'Email is already registered',
                description:
                    'This e-mail address has already been registered. Login or use Forgot Password to reset your password',
            });
        } else {
            notification.success({
                message: 'Registation success',
                description: 'Your registration has been successfully completed',
            });
            return true;
        }
        return false;
    });
}

export function RequestResetService(email) {
    return axiosInstance
        .post(`${url}/auth/validateEmail`, email)
        .then(({ data: result }) => {
            if (result === 'Account not valid') {
                notification.error({
                    message: 'Invalid Account',
                    description: 'Your email is not recognized. Please try again',
                });
            } else if (result === 'Account Disabled') {
                notification.error({
                    message: 'Account Disabled',
                    description: 'Your account is disabled and cannot request password reset',
                });
            } else {
                notification.success({
                    message: 'Email Sent',
                    description:
                        'An email has been sent to you with the instructions to reset your password',
                });
                return true;
            }
            return false;
        })
        .catch((error) => `RequestResetService: ${error}`);
}
