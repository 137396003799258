import React from 'react';
import { withRouter } from 'react-router-dom';
import LoginForm from '../../components/login/login-form/login-form.component';
import RegisterForm from '../../components/login/register-form/register-form.component';
import RequestResetForm from '../../components/login/request-reset-form/request-reset-form.component';
import { LoginService, RegisterService, RequestResetService } from './login.services';

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loginAttempt: false,
			registerAttempt: false,
			requestResetAttempt: false
		}
	}

	renderForm(url) {
		return {
			'/login' : <LoginForm onFinish={ this.handleLogin } btnLoading={ this.state.loginAttempt }/>,
			'/register': <RegisterForm onFinish={ this.handleRegister } btnLoading={ this.state.registerAttempt } />,
			'/request-reset': <RequestResetForm onFinish={ this.handleRequestReset } btnLoading={ this.state.requestResetAttempt } />
		}[url];
	}

	renderTabs(url, history) {
		return (url === '/login' || url === '/register') ? (
			<div className="login-title-container">
				<div onClick={() => history.push('/login')}>
					<h3 className={ this.getClass(url, 'login') }>Login</h3>
				</div>
				<div onClick={() => history.push('/register')}>
					<h3 className={ this.getClass(url, 'register') }>Register</h3>
				</div>
			</div>
		) : null;
	}

	getClass = (url, type) => {
		return (url === `/${type}`) ? 'login-title active' : 'login-title';
	}

	render() {
		const { history } = this.props;
		const url = history.location.pathname;
		return (
			<div className="login-component">
				<p className="application-name">Warehouse & Shipping Manager</p>
				<div className="login-content-container">
					<div className="login-container">
						{ this.renderTabs(url, history) }
						{ this.renderForm(url) }
					</div>
				</div>
			</div>
		);
	}

	handleLogin = (input) => {
		this.setState({ loginAttempt: true }, () => {
			LoginService(input).then((success) => {
				this.setState({ loginAttempt: false }, () => {
					if (success) this.props.history.push('/');
				});
			});
		});
	}

	handleRegister = (input) => {
		this.setState({ registerAttempt: true }, () => {
			RegisterService(input).then((success) => {
				this.setState({ registerAttempt: false }, () => {
					if (success) this.props.history.push('/login');
				});
			});
		});
	}

	handleRequestReset = (input) => {
		this.setState({ requestResetAttempt: true }, () => {
			RequestResetService(input).then((success) => {
				this.setState({ requestResetAttempt: false }, () => {
					if (success) this.props.history.push('/login');
				});
			});
		});
	}

};

export default withRouter(Login);
