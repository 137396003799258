import { Layout } from 'antd';
import Header from '../../components/header/header.component';
import SideBar from '../../components/sidebar/sidebar.component';
import CreateCrateTable from './create-crate-table.component';

const { Content } = Layout;

const CrateTablePage = () => (
    <Layout style={{ height: '100vh' }}>
        <Header />
        <Layout style={{ flex: '1' }}>
            <SideBar />
            <Layout className="site-layout" style={{ height: '100%' }}>
                <Content style={{ background: 'white' }}>
                    <div
                        className="site-layout-background"
                        style={{
                            height: '100%',
                            overflow: 'auto',
                        }}
                    >
                        <CreateCrateTable />
                    </div>
                </Content>
            </Layout>
        </Layout>
    </Layout>
);

export default CrateTablePage;
