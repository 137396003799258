import Icon from '@ant-design/icons';

export const SalesOutlined = (props) => <Icon component={SalesSvg} {...props} />;

const SalesSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0, 0, 400, 400">
        <path d="M134.28,352.25l-63-1.1v-27.57l53-1.12,53-1.12v-10c0-9.39-1.53-10.12-25.08-12l-25.08-2,85.86-85.76,85.87-85.76,1.21,25.76c1.15,24.48,1.7,25.76,11.21,25.76h10l1.09-77,1.09-77h27.67l1.08,87c1.04,83.97,1.36,87,9.08,87s8-2.67,8-98V1.35L188.81,.31C39.61-.55,7.54,.22,3.76,4.77,.46,8.75-.51,59.81,.24,189.81l1.04,179.54H99.28c95.33,0,98-.22,98-8s-3.03-8.05-63-9.1ZM71.28,37.35v-14h228v28H71.28v-14Zm176,35.08c0,.59-18,19.01-40,40.92l-40,39.85V71.35h40c22,0,40,.48,40,1.08ZM71.28,160.27V71.35h72v106.17l-36,35.83-36,35.83v-88.92Zm-20,27.08v164H23.28V23.35h28V187.35Zm124.97-10.97c106.06-106.12,123.03-119.36,123.03-96.06,0,12.76-206.22,219.03-218.97,219.03-23.26,0-10.08-16.89,95.94-122.97Z" />
        <path d="M257.84,193.88c-65.79,20.21-91.78,111.59-47.1,165.61,40.58,49.06,126.57,50.1,162.55,1.97,65.67-87.85-11.29-199.57-115.45-167.57Zm71.56,139.42c-2.07,4.51-4.96,8.27-8.69,11.28-3.73,3.01-8.25,5.24-13.54,6.69-5.29,1.44-11.11,2.17-17.46,2.17-8.36,0-15.71-.78-22.06-2.34-6.35-1.56-11.03-3.11-14.04-4.68l7.35-19.56c1.22,.67,2.81,1.4,4.76,2.18,1.95,.78,4.15,1.5,6.6,2.17,2.45,.67,5.04,1.23,7.77,1.67,2.73,.45,5.54,.67,8.44,.67,6.79,0,12-1.14,15.63-3.43,3.62-2.29,5.43-5.77,5.43-10.45,0-3.56-1.31-6.46-3.93-8.69s-5.91-4.26-9.86-6.1c-3.96-1.84-8.25-3.73-12.87-5.69-4.62-1.95-8.92-4.35-12.87-7.19-3.95-2.84-7.24-6.37-9.86-10.61-2.62-4.24-3.92-9.64-3.92-16.21,0-5.69,.95-10.62,2.84-14.79,1.89-4.18,4.59-7.66,8.1-10.45,3.52-2.78,7.66-4.85,12.45-6.18,4.79-1.34,10.14-2.01,16.05-2.01,6.79,0,13.2,.58,19.22,1.75s10.98,2.87,14.87,5.09l-7.19,19.23c-2.45-1.56-6.13-2.99-11.03-4.26-4.9-1.28-10.19-1.92-15.87-1.92-5.35,0-9.45,1.06-12.29,3.18-2.84,2.12-4.26,4.96-4.26,8.53,0,3.34,1.31,6.13,3.92,8.35,2.62,2.23,5.9,4.29,9.86,6.19,3.96,1.89,8.24,3.84,12.87,5.85,4.62,2.01,8.91,4.43,12.87,7.27,3.95,2.84,7.24,6.35,9.86,10.53,2.62,4.18,3.92,9.39,3.92,15.63s-1.03,11.62-3.09,16.13Z" />
    </svg>
);
