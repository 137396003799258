import React from "react";
import { Modal, InputNumber } from "antd";
import NumPad from "./numpad/numpad.component";
import "./input-numpad.styles.css";

/* <PROPS>
    allowDecimals = enables decimals to be used; default is integers only
    maxDecimals = decimal count limit

    <Native Antd InputNumber Props>
    size, placeholder, min, max
*/
class InputNumPad extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openNumPad: false
        }
    }

    render() {
        const { placeholder, allowDecimals, size, min, max, maxDecimals, value } = this.props;
        const { openNumPad } = this.state;
        return (
            <>
                <Modal className="numpad-modal-container" visible={ openNumPad } footer={ false } closable={ false }>
                    <div className="numpad-modal">
                        <div>
                            <NumPad allowDecimals={ allowDecimals } 
                                maxDecimals={ maxDecimals }
                                onFinish={ this.handleInputChange } 
                                value={ value }/>
                        </div>
                    </div>
                </Modal>
                <InputNumber 
                    value={ value } 
                    controls={ false } 
                    keyboard={ false } 
                    placeholder={ placeholder }
                    size={ size}
                    min={ min }
                    max={ max }

                    onClick={ this.handleInput }
                    onChange={ this.handleChange }
                    onPressEnter={ this.handleInput }
                />
            </>
        );
    }

    handleChange = () => {
        this.setState({ openNumPad: true });
    }

    handleInput = (e) => {
        e.preventDefault();
        e.currentTarget.blur();
        this.setState({ openNumPad: true });
    }

    handleInputChange = (numbers) => {
        numbers = (numbers === "") ? undefined : numbers;
        
        this.setState({ openNumPad: false, numbers });
        this.props.onChange(numbers);
    }
}

InputNumPad.defaultProps = {
    allowDecimals: false,
    placeholder: undefined,
    size: "middle",
    value: undefined,
    max: Number.MAX_SAFE_INTEGER,
    min: Number.MIN_SAFE_INTEGER,
    maxDecimals: 10
}

export default InputNumPad;